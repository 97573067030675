import React from "react";
import { Route, Switch } from "react-router-dom";

import Reset from "./reset";
import Forgot from "./forgot";
import Signin from "./signin";
import SignupPartner from "./signup_partner";
import SignupUser from "./signup_user";
import LoginAs from "./loginas";

export default () => {
  return (
    <Switch>
      <Route path="/auth/loginas" component={LoginAs} />
      <Route path="/auth/reset" component={Reset} />
      <Route path="/auth/forgot" component={Forgot} />
      <Route path="/auth/signup_partner" component={SignupPartner} />
      <Route path="/auth/signup_user" component={SignupUser} />
      <Route path="/auth" component={Signin} exact />
    </Switch>
  );
};
