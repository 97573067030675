import { Fragment, useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { Search, Plus, Delete, Copy, Trash } from "lucide-react";
import { AiOutlineCheck } from "react-icons/ai";
import toast from "react-hot-toast";

import { Dialog, DialogContent, DialogFooter, DialogHeader, DialogTitle, DialogTrigger } from "@/components/ui/dialog";
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from "@/components/ui/table";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "@/components/ui/select";
import { Label } from "@/components/ui/label";
import { Input } from "@/components/ui/input";
import { Button } from "@/components/ui/button";
import View from "./view";

import api from "@/services/api";
import useStore from "@/store";
import Loader from "@/components/loader";

import { Questionnaire } from "./questionnaire";
import { Template } from "./template";

const QUESTIONNAIRE = "Questionnaire";
const TEMPLATE = "Template";

export const Index = ({ match }) => {
  const datasetId = match.params.id;

  const [activeTab, setActiveTab] = useState(QUESTIONNAIRE);
  const [dataset, setDataset] = useState();
  const [filters, setFilters] = useState({ dataset_id: datasetId });

  useEffect(() => {
    (async () => {
      const { data: d } = await api.get(`/dataset/${datasetId}`);
      setDataset(d);
    })();
  }, [datasetId]);

  return (
    <section>
      <header className="bg-[#F7F9F8] pt-5 relative">

        <div className="container mb-5">
          <div>
            <h1 className="font-extrabold text-3xl mb-5">{dataset?.name} <span className="inline-block text-sm rounded-md bg-blue-400 text-white px-2 py-1 uppercase">{dataset?.type}</span></h1>
            <div className="flex items-center lg:gap-x-10 gap-x-5 overflow-x-auto -mx-8 px-8">
              <TabButton name={QUESTIONNAIRE} active={activeTab === QUESTIONNAIRE} onClick={() => setActiveTab(QUESTIONNAIRE)} />
              <TabButton name={`${TEMPLATE} - ${dataset?.master_dataset_name}`} active={activeTab === TEMPLATE} onClick={() => setActiveTab(TEMPLATE)} />
            </div>
          </div>
        </div>
      </header>

      <div className="container">
        {activeTab === QUESTIONNAIRE && <Questionnaire dataset={dataset} filters={filters} setFilters={setFilters} />}
        {activeTab === TEMPLATE && <Template dataset={dataset} filters={filters} setFilters={setFilters} />}
      </div>
    </section>
  );
};


const TabButton = ({ name, active, onClick }) => (
  <button
    className={`block text-sm font-medium pb-3 border-b-2 transition-colors hover:border-purple-2 hover:text-purple-2 capitalize ${active ? "border-purple-2 text-purple-2" : "border-transparent text-muted-foreground"}`}
    onClick={onClick}
  >
    {name}
  </button>
);

