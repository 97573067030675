import { Route, Switch, Redirect } from "react-router-dom";

import List from "./list";
import { Edit } from "./edit";
import { Index } from "./indicators/index";

export const Dataset = () => {
  return (
    <div className="my-4">
      <Switch>
        <Route exact path="/dataset/:id/indicators" component={Index} />
        <Route exact path="/dataset/:id" component={Edit} />
        <Route path="/dataset" component={List} />
      </Switch>
    </div>
  );
};
