import { Chart as ChartJS, ArcElement, Tooltip, Legend, CategoryScale, LinearScale, Title } from "chart.js";
import ChartDataLabels from "chartjs-plugin-datalabels";
import { Doughnut, Pie } from "react-chartjs-2";

import Boolean from "./components/Boolean";
import MultipleChoice from "./components/MultipleChoice";
import Number from "./components/Number";
import Percentage from "./components/Percentage";

ChartJS.register(ArcElement, Tooltip, Legend, CategoryScale, LinearScale, Title, ChartDataLabels);

export default ({ company, indicators, dimension }) => {
  const filteredIndicators = indicators.filter((indicator) => indicator.dimension === dimension);

  const items = {};
  for (let i = 0; i < filteredIndicators.length; i++) {
    const { sub_dimension, ...indicatorWithoutSubDimension } = filteredIndicators[i];
    if (!items[sub_dimension]) {
      items[sub_dimension] = [];
    }
    items[sub_dimension].push(indicatorWithoutSubDimension);
  }

  return (
    <section className="pt-10" id={`dimension-${dimension.replace(/\s+/g, "-").toLowerCase()}`}>
      <h1 className="text-2xl font-bold mb-8 font-montserrat">{dimension}</h1>

      {Object.keys(items).map((subDimensionKey, index) => (
        <>
          <h2 className="text-lg font-bold mb-3 font-montserrat">{subDimensionKey}</h2>
          <div key={index} className="border border-gray-200 shadow rounded-md p-6 text-base font-semibold grid grid-cols-2 gap-10 mb-10 font-montserrat">
            {items[subDimensionKey].map((indicator, index) => (
              <div key={index} id={`dimension-indicator-${index}`} className="flex text-base justify-between font-montserrat">
                {(indicator.question_type === "Yes / No" || indicator.question_type === "boolean") && <Boolean data={indicator} />}
                {(indicator.question_type === "mcq" || indicator.question_type === "MCQ" || indicator.question_type === "multiple_choice") && <MultipleChoice data={indicator} />}
                {(indicator.question_type === "number" || indicator.question_type === "text") && <Number data={indicator} />}
                {(indicator.question_type === "Percentage" || indicator.question_type === "percentage") && <Percentage data={indicator} />}
              </div>
            ))}
          </div>
        </>
      ))}
    </section>
  );
};
