import { Route, Switch } from 'react-router-dom'

import List from './list'
import Edit from './edit'

export const Campaign = () => {
  return (
    <div className='my-4'>
      <Switch>
        <Route path='/campaign/:id' component={Edit} />
        <Route path='/campaign' component={List} />
      </Switch>
    </div>
  )
}
