import { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import toast from "react-hot-toast";
import { Inbox } from "lucide-react";

import { Dialog, DialogContent, DialogFooter, DialogHeader, DialogTitle, DialogTrigger } from "@/components/ui/dialog";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "@/components/ui/select";
import { Avatar, AvatarFallback, AvatarImage } from "@/components/ui/avatar";
import { Label } from "@/components/ui/label";
import { Input } from "@/components/ui/input";
import { Button } from "@/components/ui/button";
import Loader from "@/components/loader";

import api from "@/services/api";
import useStore from "@/store";
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from "@/components/ui/table";

export default function List() {
  const [campaigns, setCampaigns] = useState();
  const { fund } = useStore();
  const { user } = useStore();

  useEffect(() => {
    fetch();
  }, [fund]);

  async function fetch() {
    if (!fund) return;
    const { data } = await api.post("/campaign/search", { fund_id: fund?._id });
    setCampaigns(data);
  }

  function renderContent() {
    if (!campaigns) return <Loader />;
    if (!campaigns.length)
      return (
        <p className="flex items-center gap-x-2 text-sm bg-table-header rounded-lg h-20 p-5 justify-center">
          <Inbox size={24} />
          There are no campaigns.
        </p>
      );
    return (
      <div className="border rounded-xl overflow-hidden">
        <Table>
          <TableHeader>
            <TableRow>
              <TableHead>Name</TableHead>
              <TableHead>Dates</TableHead>
              <TableHead>Questionnaires</TableHead>
              <TableHead>Indicators</TableHead>
            </TableRow>
          </TableHeader>
          <TableBody>
            {campaigns.map((campaign) => (
              <Row key={campaign._id} campaign={campaign} />
            ))}
          </TableBody>
        </Table>
      </div>
    );
  }

  return (
    <section className="container">
      <div className="flex flex-col md:flex-row gap-5 items-center justify-between mb-10">
        <h1 className="text-4xl font-bold shrink-0">Campaigns</h1>
        <div className="flex items-center justify-end">
          <Addcampaign />
        </div>
      </div>
      <div className="">{renderContent()}</div>
    </section>
  );
}

const Row = ({ campaign }) => {
  const history = useHistory();

  return (
    <TableRow key={campaign._id} onClick={() => history.push(`/campaign/${campaign._id}`)} className="cursor-pointer">
      <TableCell>{campaign.name}</TableCell>
      <TableCell>
        <div className="flex items-center gap-x-2">
          <p>{new Date(campaign.start_at).toLocaleDateString([], { year: "numeric", month: "long", day: "numeric" })}</p>
          {/* <ProgressBar
            percentage={Math.floor(((Date.now() - new Date(campaign.start_at)) / (new Date(campaign.end_at) - new Date(campaign.start_at))) * 100)}
            color={"bg-secondary"}
          /> */}
          <p>{new Date(campaign.end_at).toLocaleDateString([], { year: "numeric", month: "long", day: "numeric" })}</p>
        </div>
      </TableCell>
      <TableCell>{0}</TableCell>
      <TableCell>{0}</TableCell>
    </TableRow>
  );
};

const Addcampaign = () => {
  const [values, setValues] = useState({});
  const [loading, setLoading] = useState(false);

  const { fund } = useStore();
  const { user } = useStore();

  const history = useHistory();

  useEffect(() => {
    const start_at = new Date();
    const end_at = new Date();
    end_at.setMonth(end_at.getMonth() + 1);
    setValues({ start_at, end_at });
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      setLoading(true);
      if (!fund._id) return toast.error("Please select a fund");
      values.fund_id = fund._id;
      values.fund_name = fund.name;
      const { data } = await api.post("/campaign", values);
      toast.success("campaign created successfully");
      history.push(`/campaign/${data._id}`);
    } catch (e) {
      console.log(e);
    }
    setLoading(false);
  };

  if (user.role === "user") return null;

  return (
    <Dialog>
      <DialogTrigger asChild>
        <Button variant="blue">Create a campaign</Button>
      </DialogTrigger>
      <DialogContent className="max-w-lg">
        <DialogHeader>
          <DialogTitle className="mb-5">Create new campaign</DialogTitle>
        </DialogHeader>
        <form onSubmit={handleSubmit} className="space-y-4">
          <fieldset className="space-y-2">
            <Label>Campaign name</Label>
            <Input placeholder="Name" required value={values.name} onChange={(e) => setValues({ ...values, name: e.target.value })} />
          </fieldset>
          <div className="grid grid-cols-2 gap-4">
            <fieldset className="space-y-2">
              <Label>Start date</Label>
              <Input
                value={values.start_at && new Date(values.start_at).toISOString().split("T")[0]}
                onChange={(e) => setValues({ ...values, start_at: e.target.value })}
                placeholder="Start date"
                type="date"
              />
            </fieldset>
            <fieldset className="space-y-2">
              <Label>End date</Label>
              <Input
                value={values.end_at && new Date(values.end_at).toISOString().split("T")[0]}
                onChange={(e) => setValues({ ...values, end_at: e.target.value })}
                placeholder="End date"
                type="date"
              />
            </fieldset>
          </div>
          <DialogFooter className="mt-3">
            <Button disabled={loading} type="submit" className="bg-accent hover:bg-[#3760fa]">
              {loading ? "Loading..." : "Create"}
            </Button>
          </DialogFooter>
        </form>
      </DialogContent>
    </Dialog>
  );
};

const CompanySelector = ({ value, onChange }) => {
  const [companies, setCompanies] = useState([]);
  const { fund } = useStore();

  useEffect(() => {
    getCompanies();
  }, []);

  const getCompanies = async () => {
    const { data, ok } = await api.post("/company/search", { search: "", fund_id: fund._id });
    if (!ok) return toast.error("Something went wrong");
    setCompanies(data);
  };

  return (
    <fieldset className="space-y-2">
      <Label>Company</Label>
      <Select
        defaultValue={value}
        onValueChange={(companyId) => {
          const company = companies.find((p) => companyId === p._id);

          onChange(company);
        }}
      >
        <SelectTrigger className="text-left">
          <SelectValue placeholder="Select a company" />
        </SelectTrigger>
        <SelectContent>
          {companies.map((company) => (
            <SelectItem value={company._id} key={company._id}>
              {company.name}
            </SelectItem>
          ))}
        </SelectContent>
      </Select>
    </fieldset>
  );
};

const DatasetSelect = ({ value, onChange }) => {
  const [data, setData] = useState([]);

  const { fund } = useStore();

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    const { data, ok } = await api.post("/dataset/search", { fund_id: fund._id });
    if (!ok) return toast.error("Something went wrong");
    setData(data);
  };

  return (
    <Select
      defaultValue={value}
      onValueChange={(id) => {
        const item = data.find((d) => id === d._id);
        onChange({ dataset_id: item._id, dataset_name: item.name });
      }}
    >
      <SelectTrigger className="text-left">
        <SelectValue placeholder="Select dataset" />
      </SelectTrigger>
      <SelectContent>
        {data.map((item) => (
          <SelectItem value={item._id} key={item._id}>
            {item.name}
          </SelectItem>
        ))}
      </SelectContent>
    </Select>
  );
};
