import { Route, Switch } from "react-router-dom";

import List from "./list";
import Edit from "./edit";

export default () => {
  return (
    <div className="my-4">
      <Switch>
        <Route path="/report/:id" component={Edit} />
        <Route path="/report" component={List} />
      </Switch>
    </div>
  );
};
