import React, { useState } from "react";

import Information from "./information";
import RawData from "./raw";
import Notes from "./notes";
import { Dialog, DialogContent, DialogHeader } from "@/components/ui/dialog";

const INFORMATION = "INFORMATION";
const RAW_DATA = "RAW_DATA";
const NOTES = "NOTES";

export default ({ value, onChange, readOnly }) => {
  const [tab, setTab] = useState(INFORMATION);

  if (!value) return <div />;

  return (
    <Dialog defaultOpen onOpenChange={onChange}>
      <DialogContent className="max-w-7xl">
        <DialogHeader>
          <nav className="flex items-center gap-2 pl-2">
            <button className={`${tab === INFORMATION && "bg-gray-500 text-white"} rounded-sm px-3 py-2 text-sm`} onClick={() => setTab(INFORMATION)}>
              Information
            </button>
            {!readOnly && (
              <button className={`${tab === NOTES && "bg-gray-500 text-white"} rounded-sm px-3 py-2 text-sm`} onClick={() => setTab(NOTES)}>
                Notes
              </button>
            )}
            <button className={`${tab === RAW_DATA && "bg-gray-500 text-white"} rounded-sm px-3 py-2 text-sm`} onClick={() => setTab(RAW_DATA)}>
              Raw Data
            </button>
          </nav>
        </DialogHeader>
        <div className="bg-white rounded-md p-6 border border-primary-black-50">
          {tab === INFORMATION && <Information indicator={value} readOnly={readOnly} />}
          {tab === RAW_DATA && <RawData indicator={value} />}
          {tab === NOTES && <Notes indicator={value} />}
        </div>
      </DialogContent>
    </Dialog>
  );
};
