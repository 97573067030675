import { useEffect, useState } from "react";

import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "@/components/ui/select";

import api from "@/services/api";

export default ({ value, onChange, type, filters }) => {
  const [data, setData] = useState();

  useEffect(() => {
    (async () => {
      const { aggs } = await api.post("/indicator/search", { per_page: 0, aggregation: type, ...filters });
      setData(aggs);
    })();
  }, [filters]);

  const handleChange = (e) => {
    onChange(e);
  };

  if (!data) return <div>Loading...</div>;

  return (
    <Select defaultValue={value} onValueChange={handleChange}>
      <SelectTrigger className="text-left">
        <SelectValue placeholder={`Select ${type}`} />
      </SelectTrigger>
      <SelectContent>
        {data.map((item) => (
          <SelectItem value={item[type]} key={item[type]}>
            {item[type]}
          </SelectItem>
        ))}
      </SelectContent>
    </Select>
  );
};
