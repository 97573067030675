import { useEffect, useRef, useState } from "react";
import { XIcon, Plus, ChevronsUpDown, Check } from "lucide-react";
import toast from "react-hot-toast";
import { useHistory } from "react-router-dom";

import { Command, CommandEmpty, CommandGroup, CommandInput, CommandItem } from "@/components/ui/command";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "@/components/ui/select";
import { Popover, PopoverContent, PopoverTrigger } from "@/components/ui/popover";
import { Label } from "@/components/ui/label";
import { Input } from "@/components/ui/input";
import { Button } from "@/components/ui/button";
import Loader from "@/components/loader";

import { BUSINESS_SECTOR, CURRENCY, DOMICILE, NACE_CODE } from "@/services/constants";

import { cn } from "@/utils";

import api from "@/services/api";
import FileInput from "@/components/file-input";

export const Edit = ({ match }) => {
  const [user, setUser] = useState();
  const [values, setValues] = useState({});

  const [file, setFile] = useState(null);
  const inputRef = useRef(null);
  const history = useHistory();

  useEffect(() => {
    (async () => {
      const { data } = await api.get(`/user/${match.params.id}`);
      setUser(data);
      setValues(data);
    })();
  }, [match.params.id]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const { data } = await api.put(`/user/${match.params.id}`, values);
    toast.success("user updated successfully");
  };

  const handleDelete = async () => {
    if (!confirm("Are you sure?")) return;
    const { data } = await api.remove(`/user/${match.params.id}`);
    toast.success("Delete!");
    history.push("/team");
  };

  if (!user) return <Loader />;

  return (
    <div className="max-w-4xl mx-auto">
      <form onSubmit={handleSubmit} className="grid grid-cols-2 lg:grid-cols-3 gap-4">
        <fieldset className="space-y-2">
          <Label>Logo</Label>
          <div className="aspect-square rounded-lg overflow-hidden border-muted-foreground">
            <FileInput value={values?.logo} folder="/user" onChange={(e) => setValues({ ...values, logo: e.target.value[0] })} />
          </div>
        </fieldset>

        <fieldset className="lg:grid grid-cols-3 gap-4 col-span-9">
          <fieldset className="space-y-2">
            <Label>user Name</Label>
            <Input value={values.name} onChange={(e) => setValues({ ...values, name: e.target.value })} placeholder="Full name" />
          </fieldset>
          <fieldset className="space-y-2">
            <Label>Job title</Label>
            <Input value={values.job_title} onChange={(e) => setValues({ ...values, job_title: e.target.value })} placeholder="Job title" />
          </fieldset>
          <fieldset className="space-y-2">
            <Label>Phone number</Label>
            <Input value={values.phone} onChange={(e) => setValues({ ...values, phone: e.target.value })} placeholder="Phone number" type="tel" />
          </fieldset>
          <fieldset className="space-y-2">
            <Label>Email</Label>
            <Input value={values.email} onChange={(e) => setValues({ ...values, email: e.target.value })} placeholder="Email" type="email" />
          </fieldset>
        </fieldset>
        <fieldset className="lg:grid grid-cols-2 gap-4 col-span-9">
          <fieldset className="space-y-2">
            <Label>Language</Label>
            <Select defaultValue={values.language} onValueChange={(language) => setValues({ ...values, language })}>
              <SelectTrigger className="text-left">
                <SelectValue placeholder="Language" />
              </SelectTrigger>
              <SelectContent>
                <SelectItem value={"en"}>English</SelectItem>
                <SelectItem value={"fr"}>French</SelectItem>
              </SelectContent>
            </Select>
          </fieldset>

          <fieldset className="space-y-2">
            <Label>User type</Label>

            <Select defaultValue={values.role} onValueChange={(role) => setValues({ ...values, role })}>
              <SelectTrigger className="text-left">
                <SelectValue placeholder="User type" />
              </SelectTrigger>
              <SelectContent>
                <SelectItem value={"user"}>User</SelectItem>
                <SelectItem value={"admin"}>Admin</SelectItem>
              </SelectContent>
            </Select>
          </fieldset>
        </fieldset>
        <div className="space-x-2">
          <Button type="submit" className="bg-accent hover:bg-[#3760fa]">
            Update
          </Button>
          <Button type="button" onClick={handleDelete} className="bg-red-500 hover:bg-red-400">
            Delete
          </Button>
        </div>
      </form>
    </div>
  );
};

const NaceCode = ({ value, onChange }) => {
  const [open, setOpen] = useState(false);

  return (
    <Popover open={open} onOpenChange={setOpen}>
      <PopoverTrigger asChild>
        <Button variant="outline" role="combobox" aria-expanded={open} className={`justify-between w-full font-normal ${!value && "text-[#BDBFC0]"}`}>
          {value ? NACE_CODE.find((nace) => nace.name.toLowerCase() === value)?.name : "Choose an option..."}
          <ChevronsUpDown className="ml-2 h-4 w-4 shrink-0 opacity-50" />
        </Button>
      </PopoverTrigger>
      <PopoverContent className="p-0 lg:w-[720px] w-max max-h-56 overflow-y-auto">
        <Command>
          <CommandInput placeholder="Search framework..." />
          <CommandEmpty>No data found</CommandEmpty>
          <CommandGroup>
            {/* i limit it to 20 because it will cause lag */}
            {NACE_CODE.slice(0, 20).map((code, i) => (
              <CommandItem
                key={i}
                value={code.name}
                onSelect={(currentValue) => {
                  onChange(currentValue === value ? "" : currentValue);
                  setOpen(false);
                }}
              >
                <Check className={cn("mr-2 h-4 w-4", value === code.name.toLowerCase() ? "opacity-100" : "opacity-0")} />
                {code.code} - {code.name}
              </CommandItem>
            ))}
          </CommandGroup>
        </Command>
      </PopoverContent>
    </Popover>
  );
};
