import { Route, Switch, Redirect } from 'react-router-dom'

import List from './list'
import Detail from './detail'
//import Edit from './edit'

export const Market = () => {
  return (
      <Switch>
        <Route path='/market/:id' component={Detail} />
        <Route path='/market' component={List} />
      </Switch>
  )
}