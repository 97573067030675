import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useHistory, useParams } from "react-router-dom";

import { Label } from "@/components/ui/label";
import { Input } from "@/components/ui/input";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "@/components/ui/select";
import { Button } from "@/components/ui/button";

import api from '@/services/api'

export default ({ indicator, readOnly }) => {
  const [values, setValues] = useState(indicator);
  const [language, setLanguage] = useState("en");

  const onUpdate = async () => {
    const { data, ok } = await api.put(`/indicator/${indicator._id}`, values);
    if (!ok) return toast.error(data);
    toast.success("Updated!");
  };


  console.log("values", values);
  return (
    <div>
      <div className="grid grid-cols-3 gap-4">
        <SelectDimension type="dimension" value={values.dimension} onChange={(dimension) => setValues({ ...values, dimension })} />
        <SelectDimension type="sub_dimension" value={values.sub_dimension} onChange={(sub_dimension) => setValues({ ...values, sub_dimension })} />
        <div className="w-full">
          <Label className="mb-2 block">Regulation</Label>
          <Input
            value={values.regulation}
            onChange={(e) =>
              setValues({ ...values, regulation: e.target.value })
            }
          />
        </div>
        <div className="w-full">
          <Label className="mb-2 block">Dataset</Label>
          <Input
            value={values.dataset_name}
            onChange={(e) =>
              setValues({ ...values, dataset_name: e.target.value })
            }
          />
        </div>
      </div>
      <hr className="mt-4 mb-4" />
      <div className="mb-4">
        <Label className="mb-2 block">Language</Label>
        <select
          className="p-2 border border-gray-200 w-full rounded-lg bg-white"
          value={language}
          onChange={(e) => setLanguage(e.target.value)}
        >
          <option value={"en"}>English</option>
          <option value={"fr"}>French</option>
        </select>
      </div>
      <div className="grid grid-cols-2 gap-4">
        <div className="w-full">
          <Label className="mb-2 block">Question</Label>
          <textarea
            className="p-3 border border-gray-200 w-full rounded-lg min-h-[100px]"
            value={values.question && values.question[language]}
            onChange={(e) =>
              setValues({
                ...values,
                question: { ...values.question, [language]: e.target.value },
              })
            }
          />
        </div>
        <div className="w-full">
          <Label className="mb-2 block">Help</Label>
          <textarea
            className="p-3 border border-gray-200 w-full rounded-lg min-h-[100px]"
            value={values.help && values.help[language]}
            onChange={(e) =>
              setValues({
                ...values,
                help: { ...values.help, [language]: e.target.value },
              })
            }
          />
        </div>
        <div className="w-full">
          <Label className="mb-2 block">Indicator</Label>
          <textarea
            className="p-3 border border-gray-200 w-full rounded-lg min-h-[100px]"
            value={values.indicator && values.indicator[language]}
            onChange={(e) =>
              setValues({
                ...values,
                indicator: { ...values.indicator, [language]: e.target.value },
              })
            }
          />
        </div>
        <div className="w-full">
          <Label className="mb-2 block">indicator Final Report</Label>
          <textarea
            className="p-3 border border-gray-200 w-full rounded-lg min-h-[100px]"
            value={
              values.indicator_final_report &&
              values.indicator_final_report[language]
            }
            onChange={(e) =>
              setValues({
                ...values,
                indicator_final_report: {
                  ...values.indicator_final_report,
                  [language]: e.target.value,
                },
              })
            }
          />
        </div>

        <div className="w-full">
          <Label className="mb-2 block">Question unit</Label>
          <Input
            value={values.question_unit}
            onChange={(e) =>
              setValues({ ...values, question_unit: e.target.value })
            }
          />
        </div>
        <div className="w-full">
          <Label className="mb-2 block">Question type</Label>
          <select
            className="p-2 border border-gray-200 w-full rounded-lg bg-white"
            value={values.question_type}
            onChange={(e) =>
              setValues({ ...values, question_type: e.target.value })
            }
          >
            <option value="text">Text</option>
            <option value="number">Number</option>
            <option value="date">Date</option>
            <option value="boolean">Boolean</option>
            <option value="single_choice">Single Choice</option>
            <option value="multiple_choice">Multiple Choice</option>
          </select>
        </div>
        {["single_choice", "multiple_choice"].includes(
          values.question_type
        ) && (
            <QuestionChoices
              value={values.question_choices}
              onChange={(question_choices) => {
                console.log("question_choices", question_choices);
                setValues({ ...values, question_choices });
              }}
            />
          )}
        {/* <div className="w-full">
          <Label className="mb-2 block">Rapport </Label>
          <Input
            type="checkbox"
            checked={values.rapport}
            onChange={(e) =>
              setValues({ ...values, rapport: e.target.checked })
            }
          />
        </div> */}
      </div>
      {!readOnly && (
        <div className="flex items-center space-x-2 mt-4">
          <Button variant="blue" onClick={onUpdate}>
            Update
          </Button>
        </div>
      )}
    </div>
  );
};

const SelectDimension = ({ value, onChange, type, }) => {
  const [data, setData] = useState();
  const [custom, setCustom] = useState(false);

  const { id: datasetId } = useParams()

  useEffect(() => {
    (async () => {
      const { aggs } = await api.post("/indicator/search", { per_page: 0, aggregation: type, dataset_id: datasetId });
      setData(aggs);
    })();
  }, [])

  const handleChange = (e) => {
    if (e === "CREATE_NEW") {
      onChange("")
      return setCustom(true)
    }
    onChange(e)
  }

  const renderContent = () => {
    if (!data) return <div>Loading...</div>

    if (custom) {
      return (
        <div className="flex items-center space-x-2">
          <Input autoFocus value={value} onChange={(e) => onChange(e.target.value)} />
          <HiX onClick={() => setCustom(false)} className="cursor-pointer" />
        </div>
      )
    }

    return (
      <Select
        defaultValue={value}
        onValueChange={handleChange}
      >
        <SelectTrigger className='text-left'>
          <SelectValue placeholder='Select' />
        </SelectTrigger>
        <SelectContent>
          <SelectItem value="CREATE_NEW" className="font-medium underline cursor-pointer">Create new</SelectItem>
          {data.map(item => (
            <SelectItem value={item[type]} key={item[type]}>
              {item[type]}
            </SelectItem>
          ))}
        </SelectContent>
      </Select>

    )
  }

  return (
    <fieldset className="space-y-2">
      <Label className="mb-2 block capitalize">{type}</Label>
      {renderContent()}
    </fieldset>
  )
}


const QuestionChoices = ({ value, onChange }) => {
  const onAdd = () => {
    onChange([...value, { key: "", value: "" }]);
  };

  const onLocalChange = (index, key, v) => {
    console.log("index", index, "key", key, "value", v);
    const newChoices = [...value];
    newChoices[index][key] = v;
    onChange(newChoices);
  };

  const onDelete = (index) => {
    const newChoices = [...value];
    newChoices.splice(index, 1);
    onChange({ question_choices: newChoices });
  };

  return (
    <div className="mt-4">
      <div className="flex items-center justify-between">
        <Label className="mb-2 block">Choices</Label>
        <button className="btn btn-primary" onClick={onAdd}>
          Add
        </button>
      </div>
      <div className="flex flex-col">
        {(value || []).map((choice, index) => (
          <div className="w-full" key={index}>
            <div className="flex items-center justify-between">
              <div>
                <div>Key</div>
                <Input
                  value={choice.key}
                  onChange={(e) => onLocalChange(index, "key", e.target.value)}
                />
              </div>
              <div>
                <div>Value</div>
                <Input
                  value={choice.value}
                  onChange={(e) =>
                    onLocalChange(index, "value", e.target.value)
                  }
                />
              </div>
              <button className="btn btn-red" onClick={() => onDelete(index)}>
                Delete
              </button>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};
