import { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { Plus } from "lucide-react";
import toast from "react-hot-toast";

import { Dialog, DialogContent, DialogFooter, DialogHeader, DialogTitle, DialogTrigger } from "@/components/ui/dialog";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "@/components/ui/select";
import { Label } from "@/components/ui/label";
import { Input } from "@/components/ui/input";
import { Button } from "@/components/ui/button";

import api from "@/services/api";
import useStore from "@/store";
import Loader from "@/components/loader";

export default () => {
  const [datasets, setDatasets] = useState();
  const [filters, setFilters] = useState({ search: "" });

  const { fund } = useStore();

  const history = useHistory();

  useEffect(() => {
    getData();
  }, [filters, fund]);

  const getData = async () => {
    filters.fund_id = fund._id;
    const { data: m } = await api.post("/dataset/search", { type: "master" });
    const { data } = await api.post("/dataset/search", filters);
    const arr = [...m, ...data];
    setDatasets(arr);
  };

  if (!datasets) return <Loader />;

  return (
    <section className="container">
      <div className="flex flex-col md:flex-row gap-5 items-center justify-between mb-10">
        <h1 className="text-4xl font-bold shrink-0">Datasets</h1>
        <AddDataset />
      </div>

      <div className="grid grid-cols-3 gap-5">
        {datasets
          .filter((d) => !d.isDefault)
          .map((dataset) => (
            <div className="p-4 border border-gray-200 rounded-lg">
              <div className="flex items-start space-x-6 w-full">
                <div className="flex flex-col gap-y-4">
                  <img src={dataset.logo} alt={dataset.name} className="w-16 h-16 min-w-[4rem] object-contain rounded-full" />
                  {<div className="text-sm rounded-md bg-blue-400 text-white px-2 py-1 uppercase">{dataset.type}</div>}
                </div>
                <div className="">
                  <h2 className="text-xl font-bold mb-2">{dataset.name}</h2>
                  <div className="text-sm">{dataset.description}</div>
                </div>
              </div>
            </div>
          ))}
      </div>
    </section>
  );
};

const AddDataset = () => {
  const [values, setValues] = useState({});
  const { fund } = useStore();

  const history = useHistory();

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      values.fund_id = fund._id;
      values.fund_name = fund.name;

      const { data } = await api.post("/dataset", values);
      toast.success("dataset created successfully");
      history.push(`/dataset/${data._id}/indicators`);
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <Dialog>
      <DialogTrigger asChild>{/* <Button variant="blue">Create a dataset</Button> */}</DialogTrigger>
      <DialogContent className="max-w-lg">
        <DialogHeader>
          <DialogTitle className="mb-5">Create new dataset</DialogTitle>
        </DialogHeader>
        <form onSubmit={handleSubmit}>
          <div className="space-y-4">
            <fieldset className="space-y-2">
              <Label>Name</Label>
              <Input placeholder="Jhon dee" required value={values.name} onChange={(e) => setValues({ ...values, name: e.target.value })} />
            </fieldset>
            <fieldset className="space-y-2">
              <Label>Dataset</Label>
              <DatasetSelect value={values.master_dataset_id} onChange={(e) => setValues({ ...values, ...e })} />
            </fieldset>
          </div>
          <DialogFooter className="mt-3">
            <Button disabled={!values.master_dataset_id} type="submit" className="bg-accent hover:bg-[#3760fa]">
              Create a dataset <Plus className="ml-2" />
            </Button>
          </DialogFooter>
        </form>
      </DialogContent>
    </Dialog>
  );
};

const DatasetSelect = ({ value, onChange }) => {
  const [data, setData] = useState([]);

  const { fund } = useStore();

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    const datasetsByFundId = await api.post("/dataset/search", { fund_id: fund._id });
    const datasetsByType = await api.post("/dataset/search", { type: "master" });
    if (!datasetsByFundId.ok || !datasetsByType.ok) return toast.error("Something went wrong");
    const combinedDatasets = [...datasetsByFundId.data, ...datasetsByType.data];
    setData(combinedDatasets);
  };

  return (
    <Select
      defaultValue={value}
      onValueChange={(id) => {
        const item = data.find((d) => id === d._id);
        onChange({ master_dataset_id: item._id, master_dataset_name: item.name });
      }}
    >
      <SelectTrigger className="text-left">
        <SelectValue placeholder="Select dataset" />
      </SelectTrigger>
      <SelectContent>
        {data.map((item) => (
          <SelectItem value={item._id} key={item._id}>
            {item.name}
          </SelectItem>
        ))}
      </SelectContent>
    </Select>
  );
};
