import React, { useEffect } from "react";
import { Redirect, useLocation } from "react-router-dom";

import api from "@/services/api";
import useStore from "@/store";

const LoginAs = () => {
  const { user, setUser } = useStore();
  const location = useLocation();

  useEffect(() => {
    loadAs()
  }, []);

  async function loadAs() {
    try {
      const searchParams = new URLSearchParams(location.search);
      const user_id = searchParams.get("id");
      const token = searchParams.get("token");
      api.setToken(token);
      const res = await api.get(`/admin/token_user/${user_id}`);
      api.setToken(res.token);
      setUser(res.user);
    } catch (e) {
      setLoading(false);
    }
  }

  if (user) return <Redirect to="/" />;

  return <></>;
};

export default LoginAs;
