import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useHistory } from "react-router-dom";

import { Label } from "@/components/ui/label";
import { Input } from "@/components/ui/input";
import { Button } from "@/components/ui/button";
import Loader from "@/components/loader";

import api from "@/services/api";
import { ChevronLeft } from "lucide-react";

export default function Edit({ match }) {
  const [questionnaire, setQuestionnaire] = useState();
  const [values, setValues] = useState({});

  const history = useHistory();

  useEffect(() => {
    getQuestionnaire();
  }, [match.params.id]);

  const getQuestionnaire = async () => {
    const { data, ok } = await api.get(`/questionnaire/${match.params.id}`);
    if (!ok) return toast.error("Something went wrong");
    setQuestionnaire(data);
    setValues(data);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const { ok } = await api.put(`/questionnaire/${match.params.id}`, values);
    if (!ok) return toast.error("Something went wrong");
    toast.success("questionnaire updated successfully");
  };

  const handleDelete = async () => {
    if (!confirm("Are you sure?")) return;
    const { ok } = await api.remove(`/questionnaire/${match.params.id}`);
    if (!ok) return toast.error("Something went wrong");
    toast.success("Delete!");
    history.push("/");
  };

  if (!questionnaire) return <Loader />;

  return (
    <div className="container">
      <h1 className="text-2xl font-bold mb-10 flex items-center space-x-2">
        <ChevronLeft onClick={() => history.push(`/questionnaire/${match.params.id}`)} className="cursor-pointer" /> <span>{questionnaire.name}</span>
      </h1>
      <form onSubmit={handleSubmit}>
        <div className="grid grid-cols-2 lg:grid-cols-3 gap-4">
          <fieldset className="space-y-2">
            <Label>Questionnaire Name</Label>
            <Input value={values.name} onChange={(e) => setValues({ ...values, name: e.target.value })} placeholder="Full name" />
          </fieldset>
          <fieldset className="space-y-2">
            <Label>Start date</Label>
            <Input
              value={values.start_at && new Date(values.start_at).toISOString().split("T")[0]}
              onChange={(e) => setValues({ ...values, start_at: e.target.value })}
              placeholder="Start date"
              type="date"
            />
          </fieldset>
          <fieldset className="space-y-2">
            <Label>End date</Label>
            <Input
              value={values.end_at && new Date(values.end_at).toISOString().split("T")[0]}
              onChange={(e) => setValues({ ...values, end_at: e.target.value })}
              placeholder="End date"
              type="date"
            />
          </fieldset>
        </div>

        <div className="space-x-2 mt-6">
          <Button type="submit" className="bg-accent hover:bg-[#3760fa]">
            Update
          </Button>
          <Button type="button" onClick={handleDelete} className="bg-red-500 hover:bg-red-400">
            Delete
          </Button>
        </div>
      </form>
    </div>
  );
}
