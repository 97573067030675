import { useEffect, useState } from "react";
import Gleap from "gleap";
import { BrowserRouter, Switch, Route, Redirect } from "react-router-dom";
import { Toaster } from "react-hot-toast";
import * as Sentry from "@sentry/browser";

import Auth from "@/pages/auth";
import { Campaign } from "@/pages/campaigns";
import { CompanyEdit } from "@/pages/home/companyEdit";
import { Home } from "@/pages/home";
import { Team } from "@/pages/team";
import { Account } from "@/pages/account";
import Report from "@/pages/report";
import { Dataset } from "@/pages/dataset";
import { Market } from "@/pages/market";
import { Questionnaire } from "@/pages/questionnaire";

import useStore from "@/store";

import { ResponsiveIndicator } from "@/components/responsive-indicator";
import { Header } from "@/components/header";
import Loader from "@/components/loader";

import api from "@/services/api";
import Hotjar from "./services/hotjar";

import { GLEAP_API_KEY, ENVIRONMENT, SENTRY_DSN } from "./config";

if (ENVIRONMENT === "production") {
  Gleap.initialize(GLEAP_API_KEY);
  Sentry.init({ dsn: SENTRY_DSN, environment: "app" });
}

export default function App() {
  const [loading, setLoading] = useState(true);

  const { user, setUser } = useStore();

  useEffect(() => {
    fetchData();
  }, []);

  async function fetchData() {
    try {
      const res = await api.get("/user/signin_token");
      if (res.token) api.setToken(res.token);
      setUser(res.user);
    } catch (e) {
      console.log(e);
    }
    setLoading(false);
  }

  if (loading) return <Loader />;

  return (
    <BrowserRouter>
      <Hotjar />
      {user && <Header />}
      <Switch>
        <Route path="/auth" component={Auth} />
        <RestrictedRoute path="/team" component={Team} />
        <RestrictedRoute path="/dataset" component={Dataset} />
        <RestrictedRoute path="/campaign" component={Campaign} />
        <RestrictedRoute path="/account" component={Account} />
        <RestrictedRoute path="/report" component={Report} />
        <RestrictedRoute path="/market" component={Market} />
        <RestrictedRoute path="/questionnaire" component={Questionnaire} />
        <RestrictedRoute path="/company/:id" component={CompanyEdit} />
        <RestrictedRoute path="/" component={Home} exact />
      </Switch>
      <Toaster position="top-center" />
      <ResponsiveIndicator />
    </BrowserRouter>
  );
}

const RestrictedRoute = ({ component: Component, role, ...rest }) => {
  const { user } = useStore();
  return <Route {...rest} render={(props) => (user ? <Component {...props} /> : <Redirect to={{ pathname: "/auth" }} />)} />;
};
