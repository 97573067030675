export default ({ data }) => {
  return (
    <>
      <div>
        <p className="font-bold w-full max-w-xs  font-montserrat">{data.indicator_final_report?.en}</p>
        <p className="text-gray-400 italic">{data.indicator_final_report_description?.en}</p>
      </div>

      <span className="font-normal  font-montserrat">{data.value === "No" ? "❌" : data.value === "Yes" ? "✅" : "N/A"}</span>
    </>
  );
};
