import { Route, Switch, Redirect } from 'react-router-dom'

import { List } from './list'
import { Edit } from './edit'

export const Team = () => {
  return (
    <div className='container my-10'>
      <Switch>
        <Route path='/team/:id' component={Edit} />
        <Route path='/team' component={List} />
      </Switch>
    </div>
  )
}
