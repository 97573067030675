export default ({ data }) => {
  return (
    <div className="flex justify-between gap-4 w-full">
      <div className="w-1/2">
        <p className="font-bold w-full max-w-xs  font-montserrat">{data.indicator_final_report?.en}</p>
        <p className="text-gray-400 italic">{data.indicator_final_report_description?.en}</p>
      </div>
      <div className="w-1/2 flex justify-end">
        <span className="font-normal font-montserrat">{data.value === "None of the above" ? "None" : data.value?.replace(/,/g, "\n")}</span>
      </div>
    </div>
  );
};
