export const dimensions = [
  { name: "General information", color: "bg-[#6E7581]" },
  { name: "Stewardship", color: "bg-[#9F3C60]" },
  { name: "Environment", color: "bg-[#81B5A6]" },
  { name: "Social", color: "bg-[#8177CC]" },
  { name: "Governance", color: "bg-[#D28A2B]" },
  { name: "External Stakeholders", color: "bg-[#C73F34]" },
];

export const question_types = [
  { name: "text", color: "bg-[#E2E2E2]" },
  { name: "number", color: "bg-[#D6F4F0]" },
  { name: "boolean", color: "bg-[#EFF2FE]" },
  { name: "mcq", color: "bg-[#C5CEF8]" },
  { name: "ocq", color: "bg-[#E1B2C4]" },
  { name: "date", color: "bg-[#FFE9CC]" },
];
