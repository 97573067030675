import React, { useEffect, useState } from "react"
import Loader from '@/components/loader'

export default function Detail ({ match }) {
    const {id} = match.params
    const [data, setData] = useState()

    const fetch = async () => {
        try {
            //fetch data here
            ///////////////////////
            const tmp = [
                {_id: "0", name: "Vasco", logo: "/logotmp/vasco.png", image: "/imgtmp/vasco.png", aboutInfos: "Vasco digitises the creation, distribution and monitoring of your vehicles: club-deals and UCIs, whatever your asset class. Manage your dealflow, organise the raising of your vehicles, track the funds deployed and measure the performance generated.", matterInfos: "Connect your account to Vasco to get a full free access to their platform as well as an automatic connection between their reports and your data.", linkedin:"#", tags: ["Data import", "Mobility"]},
                {_id: "1", name: "Meteory", logo: "/logotmp/meteory.png", image: "/imgtmp/meteory.png", aboutInfos: "Meteory enhances environmental sustainability with their monitoring platform. With this tool you have unlimited possibilities to explore, compare, and analyse places (such as buildings, factories, etc.). Using different lenses you can monitor your vegetation, atmosphere, landcover and many more.", matterInfos: "Connect your account to Meteory to get a full free access to their platform as well as an automatic connection between their reports and your data.", linkedin:"#", tags: ["Data connection", "Environment", "Carbon"]},
                {_id: "2", name: "Carbometrix", logo: "/logotmp/carbometrix.png",  image: "/imgtmp/carbometrix.png", aboutInfos: "Carbometrix produces fast and meaningful carbon performance data for financial institutions and the companies they finance in order to make the right investment decisions. Customised and impactful decarbonisation levers to build your decarbonisation roadmap.", matterInfos: "Connect your account to Carbometrix to get a full free access to their platform as well as an automatic connection between their reports and your data.", tags: ["Data import", "Finance", "Carbone"],  linkedin:"#"},
            ]
            const res = tmp.find(e => e._id === id)
            ///////////////////////

            if (!res) return
            setData(res)
        } 
        catch (e) {
            console.log(e)
        }
    }

    useEffect(() => {
        fetch()
    }, [])

    if (!data) return <Loader/>

    return (
        <div className="h-[calc(100vh-80px)]"> 
            <div className="bg-white grid grid-cols-12 p-10">
                <div className="p-5 col-span-12 md:col-span-3 flex flex-col items-center justify-center">
                    <h1 className="text-4xl lg:text-5xl font-bold text-black-100 py-5">{data.name}</h1>
                    <button type="button" className="mt-10 px-3 py-2 rounded-lg bg-blue-700 hover:bg-blue-600 text-white text-center w-full">Open with Matteriality</button>
                </div>
                <div className="col-span-1"></div>
                <div className="hidden md:flex md:col-span-8 items-center justify-center">
                    <img alt='logo' src={data.image} className="aspect-video"/>
                </div>
            </div>
            <div className="bg-gray-50 w-full min-h-2/5 flex p-10 flex-col md:flex-row">
                <div className="w-2/3 flex flex-col gap-10 p-5">
                    <div>
                        <h3 className="font-bold text-xl">About</h3>
                        <p className="text-sm">{data.aboutInfos}</p>
                    </div>
                    <div>
                        <h3 className="font-bold text-xl">How does it work with Matter</h3>
                        <p className="text-sm">{data.matterInfos}</p>
                    </div>
                </div>
                <div className="w-1/4 flex flex-col gap-10 p-5">
                    <div>
                        <h3 className="font-bold text-xl">Tags</h3>
                        <div className="flex gap-3 flex-wrap">
                            {data.tags?.map(e => (
                                <div className="text-blue-700 bg-[#EFF2FA] rounded-lg px-2 py-1 text-center">{e}</div>
                            ))}
                        </div>
                    </div>
                    <div>
                        <h3 className="font-bold text-xl">Network</h3>
                        {data.linkedin && <a href={data.linkedin} target="_blank"><img alt='logo' src="/linkedin.png" className="w-5 h-5"/></a>}
                    </div>
                </div>
            </div>
        </div>
    )
}