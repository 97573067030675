import { useEffect, useState, useRef } from "react";
import toast from "react-hot-toast";
import { Paperclip } from "lucide-react";
import { MdOutlineModeEdit, MdDeleteOutline, MdKeyboardArrowDown, MdKeyboardArrowUp } from "react-icons/md";

import { Label } from "@/components/ui/label";
import { Input } from "@/components/ui/input";
import { Button } from "@/components/ui/button";

import api from "@/services/api";

import useStore from "@/store";

const TabValues = {
  ALL: "ALL",
  TO_ANSWER: "TO_ANSWER",
  ANSWERED: "ANSWERED",
};

export default ({ questionnaire, onUpdate }) => {
  const [campaignIndicators, setCampaignIndicators] = useState([]);
  const [activeSubdimension, setActiveSubDimension] = useState("");
  const [activeDimension, setActiveDimension] = useState("");
  const [tabValue, setTabValue] = useState(TabValues.ALL);
  const [questions, setQuestions] = useState([]);

  useEffect(() => {
    fetchIndicators();
  }, []);

  const fetchIndicators = async () => {
    const { data } = await api.post("/campaign_indicator/search", { questionnaire_id: questionnaire._id });
    setCampaignIndicators(data);
    setActiveSubDimension(data[0].sub_dimension);
  };

  useEffect(() => {
    activeSubdimension
      ? setQuestions(campaignIndicators.filter((e) => e.sub_dimension == activeSubdimension))
      : setQuestions(campaignIndicators.filter((e) => e.dimension == activeDimension));
  }, [activeDimension, activeSubdimension]);

  // const questions = campaignIndicators.filter((e) => e.sub_dimension == activeSubdimension || e.dimension == activeDimension);

  return (
    <section className="flex md:flex-row flex-col md:items-start items-stretch">
      <Menu
        campaignIndicators={campaignIndicators}
        activeSubdimension={activeSubdimension}
        setActiveSubDimension={setActiveSubDimension}
        activeDimension={activeDimension}
        setActiveDimension={setActiveDimension}
      />
      <div className="flex-grow md:ml-8 ml-0 md:mt-0 mt-4 border-l-2 pl-8">
        <div className="flex items-center space-x-2 mb-4">
          <Tab
            onClick={() => setTabValue(TabValues.ALL)}
            text={`All questions (${questions.length})`}
            className={`bg-blue-100 text-blue-600 ${tabValue === TabValues.ALL && "border border-blue-600"}`}
          />
          <Tab
            onClick={() => setTabValue(TabValues.TO_ANSWER)}
            text={`To answer (${questions.filter((e) => !e.value).length})`}
            className={`bg-orange-100 text-orange-600 ${tabValue === TabValues.TO_ANSWER && "border border-orange-600"}`}
          />
          <Tab
            onClick={() => setTabValue(TabValues.ANSWERED)}
            text={`Answered (${questions.filter((e) => e.value).length})`}
            className={`bg-black/5 text-black/60 ${tabValue === TabValues.ANSWERED && "border border-gray-950"}`}
          />
        </div>
        <div className="space-y-6">
          {tabValue === TabValues.ALL &&
            questions.map((campaignIndicator) => (
              <Question
                allData={questions}
                data={campaignIndicator}
                onData={async (value) => {
                  const res = await api.put(`/campaign_indicator/${campaignIndicator._id}`, value);
                  toast.success("Answer saved");

                  // refresh
                  const newIndicators = [...campaignIndicators];

                  const index = newIndicators.findIndex((e) => e._id == campaignIndicator._id);
                  newIndicators[index] = res.data;

                  onUpdate();

                  setCampaignIndicators(newIndicators);
                }}
                key={campaignIndicator._id}
              />
            ))}
          {tabValue === TabValues.TO_ANSWER &&
            questions
              .filter((e) => !e.value)
              .map((campaignIndicator) => (
                <Question
                  allData={questions}
                  data={campaignIndicator}
                  onData={async (value) => {
                    const res = await api.put(`/campaign_indicator/${campaignIndicator._id}`, value);
                    toast.success("Answer saved");

                    // refresh
                    const newIndicators = [...campaignIndicators];

                    const index = newIndicators.findIndex((e) => e._id == campaignIndicator._id);
                    newIndicators[index] = res.data;

                    onUpdate();

                    setCampaignIndicators(newIndicators);
                  }}
                  key={campaignIndicator._id}
                />
              ))}
          {tabValue === TabValues.ANSWERED &&
            questions
              .filter((e) => e.value)
              .map((campaignIndicator) => (
                <Question
                  allData={questions}
                  data={campaignIndicator}
                  onData={async (value) => {
                    const res = await api.put(`/campaign_indicator/${campaignIndicator._id}`, value);
                    toast.success("Answer saved");

                    // refresh
                    const newIndicators = [...campaignIndicators];

                    const index = newIndicators.findIndex((e) => e._id == campaignIndicator._id);
                    newIndicators[index] = res.data;

                    onUpdate();

                    setCampaignIndicators(newIndicators);
                  }}
                  key={campaignIndicator._id}
                />
              ))}
        </div>
      </div>
    </section>
  );
};

const Menu = ({ campaignIndicators, activeSubdimension, setActiveSubDimension, activeDimension, setActiveDimension }) => {
  const items = {};
  const [openDimensions, setOpenDimensions] = useState([]);

  for (let i = 0; i < campaignIndicators.length; i++) {
    const { dimension, sub_dimension } = campaignIndicators[i];
    if (!items[dimension]) items[dimension] = [];

    if (!items[dimension].includes(sub_dimension)) {
      items[dimension].push(sub_dimension);
    }
  }

  useEffect(() => {
    setOpenDimensions([campaignIndicators[0]?.dimension]);
  }, [campaignIndicators]);

  return (
    <div className="w-80 flex-none">
      {Object.keys(items).map((key) => {
        const isActiveDimension = key === activeDimension; //items[key].includes(activeSubdimension)
        const bgColorClass = isActiveDimension ? "bg-purple-4" : "";
        const textColorClass = isActiveDimension ? "text-purple" : "";

        const num = campaignIndicators.filter((e) => e.dimension == key).length;

        return (
          <div key={key} value={key} className={`text-sm cursor-pointer py-1.5 px-3 rounded font-medium ${bgColorClass} ${textColorClass}`}>
            <div
              className="flex justify-between"
              onClick={() => {
                if (!openDimensions.includes(key)) {
                  setOpenDimensions((prev) => prev.concat(key));
                  setActiveDimension(key);
                  setActiveSubDimension("");
                } else if (!isActiveDimension) {
                  setActiveDimension(key);
                  setActiveSubDimension("");
                } else {
                  setOpenDimensions((prev) => prev.filter((e) => e !== key));
                  setActiveDimension("");
                }
              }}
            >
              <p>{`${key} (${num})`}</p>
              {openDimensions.includes(key) ? <MdKeyboardArrowUp size={20} /> : <MdKeyboardArrowDown size={20} />}
            </div>

            {items[key][0] &&
              openDimensions.includes(key) &&
              items[key].map((sub) => {
                const isActiveSubDimension = sub === activeSubdimension;
                const bgColorClass = isActiveSubDimension ? "bg-purple-4" : "";
                const textColorClass = isActiveSubDimension ? "text-purple" : "";

                return (
                  <div
                    key={sub}
                    className={`text-sm cursor-pointer py-1.5 px-3 font-medium ml-4 border-l-2 ${bgColorClass} ${textColorClass}`}
                    onClick={() => {
                      setActiveDimension("");
                      setActiveSubDimension(sub);
                    }}
                  >
                    {sub}
                  </div>
                );
              })}
          </div>
        );
      })}
    </div>
  );
};

const Tab = ({ text, onClick, className }) => (
  <span className={`${className} px-3 py-3 font-semibold text-sm rounded cursor-pointer`} onClick={onClick}>
    {text}
  </span>
);

const splitTextAndLink = (text) => {
  const urlPattern = /(https?:\/\/[^\s]+)/;
  const parts = text.split(urlPattern);
  return parts;
};

const Question = ({ data, onData, index, allData }) => {
  const helpText = data?.help?.en || data?.help?.fr || "";
  const [beforeLink, link, afterLink] = splitTextAndLink(helpText);

  return (
    <div className="border rounded-lg p-4">
      <div className="relative rounded-lg">
        {data.value && <span className="bg-gray-100 text-black px-1 py-0.5 text-xs rounded mr-2">Answered</span>}
        <div className="my-3 space-y-4">
          <Label className="font-semibold md:text-xl text-xl text-black">{`${data.key}. ${data?.question?.en || data?.question?.fr}`}</Label>
          {(data?.help?.en || data?.help?.fr) && (
            <div className="p-4 bg-gray-100 rounded-sm">
              <div className="text-xs font-semibold mb-2">💡 Help section</div>
              <div className="text-xs leading-5 text-muted-foreground break-words">
                {beforeLink}
                {link && (
                  <a href={link} target="_blank" rel="noopener noreferrer" className="break-all text-blue-600 underline">
                    {link}
                  </a>
                )}
                {afterLink}
              </div>
            </div>
          )}
        </div>
        <div className="relative mt-3">
          <Label className="text-sm font-medium mb-2 block">Your answer</Label>
          <AnswerInput data={data} onChange={onData} />
          {/* <textarea
            className="border border-gray-300 w-full p-4 text-sm outline-none ring-0 resize-y rounded-md min-h-[80px]"
            placeholder="Type your answer here"
            value={inputValue}
            onBlur={() => onData({ value: inputValue })}
            onChange={e => setInputValue(e.target.value)}
          /> */}
        </div>
        <hr className="mt-4" />
        <Comments data={data} onData={(v) => onData(v, index)} />
      </div>
    </div>
  );
};

const AnswerInput = ({ data, onChange }) => {
  const [inputValue, setInputValue] = useState(data.value);
  const [checkBoxValue, setCheckBoxValue] = useState(data.value ? data.value.split(",") : []);
  const handleCheckboxChange = async (choice_en) => {
    const updatedValues = checkBoxValue.includes(choice_en) ? checkBoxValue.filter((e) => e !== choice_en) : [...checkBoxValue, choice_en];

    setCheckBoxValue(updatedValues);
    await onChange({ value: updatedValues.join(",") });
  };
  function onUpdate() {
    if (inputValue == data.value) return;
    onChange({ value: inputValue });
  }

  if (["MCQ", "mcq", "multiple_choice"].includes(data.question_type)) {
    const choices = data.question_choices || [];

    return [...choices, { en: "N/A" }].map((choice, index) => {
      const choice_en = choice?.en || "";
      return (
        <div key={index} className="flex items-center space-x-2 mb-2 cursor-pointer">
          <input
            type="checkbox"
            name={`${data._id}-${choice_en}`}
            id={`${data._id}-${choice_en}`}
            checked={checkBoxValue.includes(choice_en)}
            onChange={() => handleCheckboxChange(choice_en)}
          />
          <label htmlFor={`${data._id}-${choice_en}`}>{choice_en}</label>
        </div>
      );
    });
  }

  if (["Yes / No + Comments", "boolean", "Yes / No"].includes(data.question_type) || data.question_type.includes("Yes / No")) {
    return ["Yes", "No", "N/A"].map((choice, index) => (
      <div key={index} className="flex items-center space-x-2 mb-2 cursor-pointer">
        <input
          type="radio"
          name={`${data._id}-${choice}`}
          id={`${data._id}-${choice}`}
          checked={inputValue === choice}
          onChange={async () => {
            setInputValue(choice);
            await onChange({ value: choice });
          }}
        />
        <label htmlFor={`${data._id}-${choice}`}>{choice}</label>
      </div>
    ));
  }

  if (["percentage", "Percentage"].includes(data.question_type)) {
    return (
      <div className="flex gap-2 items-center">
        <Input
          type="number"
          min="0"
          max="100"
          placeholder="Type your answer here"
          value={inputValue}
          onChange={(e) => {
            const value = Math.max(0, Math.min(100, Number(e.target.value)));
            setInputValue(value);
          }}
          onBlur={onUpdate}
        />
        <span>%</span>
      </div>
    );
  }

  if (["number", "Number"].includes(data.question_type)) {
    return (
      <div className="flex gap-2 items-center">
        <Input type="number" placeholder="Type your answer here" value={inputValue} onChange={(e) => setInputValue(e.target.value)} onBlur={onUpdate} />
        <span>{data.question_unit}</span>
      </div>
    );
  }

  return (
    <textarea
      className="border border-gray-300 w-full p-4 text-sm outline-none ring-0 resize-y rounded-md min-h-[80px]"
      placeholder="Type your answer here"
      value={inputValue}
      onChange={(e) => setInputValue(e.target.value)}
      onBlur={onUpdate}
    />
  );
};

const Comments = ({ data, onData }) => {
  const [comment, setComment] = useState("");
  const [comments, setComments] = useState(data.comments || []);
  const [file, setFile] = useState();

  const { questionnaire_id } = data;
  const handleSubmit = async (e) => {
    e.preventDefault();
    // onAdd(comment);
    setComment("");
    setFile();
  };

  const { user } = useStore();

  const saveDocument = async (documentData) => {
    try {
      const response = await api.post("/document", documentData);
      console.log("Document saved successfully:", response.data);
    } catch (error) {
      console.error("Error saving document:", error);
    }
  };

  function onAdd() {
    const newComment = {
      user_avatar: user.avatar,
      user_name: user.name,
      user_id: user._id,
      text: comment,
      created_at: new Date(),
    };
    if (file) {
      newComment.file_url = file.file_url;
      newComment.file_name = file.file_name;
      const commentNew = newComment;
      commentNew.size = file.size;
      commentNew.updated_at = new Date();
      commentNew.questionnaire_id = questionnaire_id;
      saveDocument(commentNew);
    }

    const tempComments = [...comments, newComment];
    setComments(tempComments);
    onData({ comments: [...comments, newComment] });
  }

  function onDelete(index) {
    const newComments = comments.filter((_, i) => i !== index);
    setComments(newComments);
    onData({ comments: newComments });
  }

  return (
    <div className="flex-grow-0 py-3">
      <div className="text-sm text-secondary font-medium">Comments</div>
      <div className="flex flex-col gap-y-1 mb-4">
        {(comments || []).map((comment, index) => {
          const time = new Date(comment.created_at).toLocaleDateString([], { year: "numeric", month: "long", day: "numeric" });

          return (
            <div key={index} className="flex items-center bg-white p-2 rounded-md">
              <img src={comment.user_avatar} className="rounded-full w-10 h-10 mr-4 self-start" />
              <div>
                <div className="flex items-center gap-x-4 font-semibold mb-2 pt-0.5">
                  <div className="text-base">{comment.user_name} </div>
                  <div className="font-normal text-gray-500 text-xs" data-tip data-for={`comment-${comment._id}`}>
                    {time}
                  </div>
                </div>
                <div className="text-sm text-gray-500 font-medium">{comment.text}</div>
                <div className="text-sm">
                  <a href={comment?.file_url} target="_blank" className="text-sm text-blue-600 underline overflow-hidden relative text-ellipsis">
                    {comment?.file_name || ""}
                  </a>
                </div>
              </div>
              <div className="flex-grow" />
              <div>
                <div
                  className="text-sm text-gray-500 ml-4 cursor-pointer underline"
                  onClick={() => {
                    if (!window.confirm("Are you sure?")) return;
                    onDelete(index);
                  }}
                >
                  delete
                </div>
              </div>
            </div>
          );
        })}
      </div>
      <form onSubmit={handleSubmit}>
        <textarea
          className="border border-gray-200 w-full p-4 text-sm outline-none ring-0 resize-y rounded-md min-h-[80px]"
          placeholder="Add a comment"
          value={comment}
          onChange={(e) => setComment(e.target.value)}
        />
        <div className="flex items-center mt-2 justify-end space-x-4">
          <FileInput value={file} name="comment-file" folder="/comments" onChange={setFile} />
          <Button variant="outline" className="border-blue-600 text-blue-700 px-6 py-1.5 h-auto font-semibold hover:text-blue-600" onClick={onAdd}>
            Add
          </Button>
        </div>
      </form>
    </div>
  );
};

const FileInput = ({ value, onChange, name, folder }) => {
  const [loading, setLoading] = useState(false);

  const inputRef = useRef(null);

  const handleFileChange = async (e) => {
    setLoading(true);

    const files = [];

    const file = e.target.files[0];
    const rawBody = await readFileAsync(file);
    files.push({ rawBody, name: file.name });

    const { data } = await api.post(`/file`, { files, folder });

    onChange({ file_url: data[0], file_name: file.name, size: file.size });
    setLoading(false);
  };

  function renderPreview() {
    if (loading) return <div className="text-sm">Loading...</div>;

    if (value)
      return (
        <div className="flex items-center space-x-2">
          <div className="text-sm underline overflow-hidden relative text-ellipsis">{value.file_name}</div>
          <div className="flex items-center">
            <div className="hover:bg-gray-50 cursor-pointer" onClick={() => inputRef.current.click()}>
              <MdOutlineModeEdit />
            </div>
            <div className="px-3 py-2 w-full hover:bg-gray-50 cursor-pointer" onClick={() => onChange()}>
              <MdDeleteOutline />
            </div>
          </div>
        </div>
      );

    return <Paperclip className="cursor-pointer text-blue-600" onClick={() => inputRef.current.click()} />;
  }

  return (
    <div>
      <input ref={inputRef} type="file" onChange={handleFileChange} className="hidden" />
      {renderPreview()}
    </div>
  );
};

function readFileAsync(file) {
  return new Promise((resolve, reject) => {
    let reader = new FileReader();
    reader.onload = () => resolve(reader.result);
    reader.onerror = reject;
    reader.readAsDataURL(file);
  });
}
