import React, { useState, useEffect } from 'react'
import { toast } from 'react-hot-toast'

import useStore from '@/store'

import Loader from '@/components/loader'
import api from '../services/api'
import { Button } from '@/components/ui/button'
import { Input } from '@/components/ui/input'
import { Label } from '@radix-ui/react-label'

export const Account = () => {
  const [values, setValues] = useState()

  const user = useStore(state => state.user)
  const setUser = useStore(state => state.setUser)

  useEffect(() => {
    ; (async () => {
      const { data } = await api.get(`/user/${user._id}`)
      const { name, email, phone } = data
      setValues({ name, email, phone })
    })()
  }, [])

  const handleSubmit = async e => {
    e.preventDefault()
    try {
      const res = await api.put(`/user`, values)
      if (!res.ok) throw new Error(res)
      setUser(res.data)
      toast.success('Updated successfully')
    } catch (error) {
      console.log('error', error)
    }
  }

  if (!values) return <Loader />

  return (
    <section className='container my-10'>
      <form onSubmit={handleSubmit}>
        <h2 className='text-2xl font-bold mb-10'>My account</h2>
        <div className='grid grid-cols-2 gap-4'>
          <div className='space-y-2.5'>
            <Label htmlFor='name'>Name</Label>
            <Input
              autoFocus
              type='text'
              name='name'
              value={values.name}
              onChange={e => setValues({ ...values, name: e.target.value })}
              placeholder='Entrez le nom de votre association'
            />
          </div>
          <div className='space-y-2.5'>
            <Label htmlFor='organization-email'>Email</Label>
            <Input
              type='email'
              name='organization-email'
              value={values.email}
              onChange={e => setValues({ ...values, email: e.target.value })}
              placeholder='Entrez votre adresse e-mail'
            />
          </div>
        </div>

        <div className='!mt-10'>
          <Button type='submit'>Update</Button>
        </div>
      </form>
    </section>
  )
}
