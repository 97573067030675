export default ({ data }) => {
  return (
    <div className="flex justify-between gap-4 w-full">
      <div className="w-1/3">
        <p className="font-bold w-full max-w-xs  font-montserrat">{data.indicator_final_report?.en}</p>
        <p className="text-gray-400 italic">{data.indicator_final_report_description?.en}</p>
      </div>
      <div className="w-2/3 flex justify-end">
        <span className="font-normal font-montserrat">{data.value || "N/A"}</span>
        {data.unit && <span className="font-normal font-montserrat">{data.unit}</span>}
      </div>
    </div>
  );
};
