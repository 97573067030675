import { useEffect, useMemo, useState } from "react";

import { Button } from "@/components/ui/button";

import api from "@/services/api";

export default ({ questionnaire }) => {
  const exportToCSV = async () => {
    const { data: campaignIndicators } = await api.post("/campaign_indicator/search", { questionnaire_id: questionnaire._id });

    const csvRows = [];
    const questionHeaders = ["Question ID", "Dimension", "Sub Dimension", "Question Text", "Answers", "Comments"];

    csvRows.push(questionHeaders.map((header) => `"${header}"`).join(";"));

    for (let i = 0; i < campaignIndicators.length; i++) {
      const indicator = campaignIndicators[i];
      const comments = indicator.comments.map((comment) => (comment.text || comment.file_name || "N/A").replace(/"/g, '""').replace(/[\r\n]+/g, " ")).join(" ; ");
      const questionValues = [
        indicator.key,
        indicator.dimension || "N/A",
        indicator.sub_dimension || "N/A",
        indicator.question.en.replace(/[\r\n]+/g, " "),
        indicator.value,
        `"${comments}"`,
      ];
      csvRows.push(questionValues.join(";"));
    }

    const csvContent = "data:text/csv;charset=utf-8," + encodeURIComponent(csvRows.join("\n"));

    const link = document.createElement("a");
    link.href = csvContent;
    link.download = `${questionnaire.name}.csv`;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  if (!questionnaire) return <div />;

  return (
    <Button onClick={exportToCSV} className="bg-accent hover:bg-[#3760fa] p-2">
      Export CSV
    </Button>
  );
};
