const ProgressBar = ({ percentage, color }) => {
  return (
    // Progress Bar
    <div className='flex justify-center'>
      <div className={'w-full bg-gray-200 h-3 rounded-[10px] overflow-hidden'}>
        <div className={`${color} rounded-[10px]`} style={{ width: `${percentage}%` }}>
          <div
            className={`flex justify-center items-center h-3 text-[14px] font-medium text-green-dark bg-green-light
              rounded-[10px]`}
          />
        </div>
      </div>
    </div>
  )
}

export default ProgressBar
