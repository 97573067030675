import React, { useEffect, useState } from "react";
import { Table, TableHeader, TableBody, TableRow, TableHead, TableCell, TableCaption } from "@/components/ui/table";
import api from "@/services/api";
import { IoMdDownload } from "react-icons/io";
import { RiFileExcel2Fill, RiFileTextFill, RiFileWord2Fill, RiFilePdf2Fill, RiImage2Fill } from "react-icons/ri";

export default ({ questionnaire }) => {
  const [documents, setDocuments] = useState([]);
  // const [selectedDocument, setSelectedDocument] = useState(null);

  useEffect(() => {
    const fetchDocuments = async () => {
      try {
        const { data } = await api.post("/document/search", { questionnaire_id: questionnaire._id });
        setDocuments(data);
      } catch (error) {
        console.error("Error fetching documents:", error);
      }
    };

    fetchDocuments();
  }, [questionnaire]);

  const columns = ["Name", "Owner", "Last modified", "File size", ""];

  const handleDownloadClick = (file) => {
    const link = document.createElement("a");
    link.href = file.file_url;
    link.download = file.file_name;
    link.click();
    console.log("Downloading file:", file.file_url);
  };

  return (
    <section>
      <Table>
        <TableHeader style={{ fontWeight: "bold", borderBottom: "1px solid #ddd", backgroundColor: "transparent" }}>
          <TableRow>
            {columns.map((column, index) => (
              <TableHead key={index}>{column}</TableHead>
            ))}
          </TableRow>
        </TableHeader>
        <TableBody>
          {documents.map((document) => (
            <TableRow key={document.id} onClick={() => window.open(document.file_url, "_blank")}>
              <TableCell>
                <div className="flex items-center space-x-2">
                  <span className="text-lg">{getFileIcon(document.file_name)}</span>
                  <span>{document.file_name.split(".")[0]}</span>
                </div>
              </TableCell>
              <TableCell>
                <div className="flex items-center">
                  <img src={document.user_avatar} className="rounded-full w-5 h-5 mr-4" />
                  <div className="flex-grow">{document.user_name}</div>
                </div>
              </TableCell>
              <TableCell>{document.updated_at.slice(0, 10)}</TableCell>
              <TableCell>{fileSize(document.size)}</TableCell>
              <TableCell>
                <button onClick={() => handleDownloadClick(document)}>
                  <IoMdDownload />
                </button>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </section>
  );
};

function getFileIcon(file) {
  const extension = file.split(".").pop();
  switch (extension) {
    case "doc":
    case "docx":
      return <RiFileWord2Fill />;
    case "xls":
    case "xlsx":
      return <RiFileExcel2Fill />;
    case "pdf":
      return <RiFilePdf2Fill />;
    case "png":
    case "jpg":
    case "jpeg":
      return <RiImage2Fill />;
    default:
      return <RiFileTextFill />;
  }
}

function fileSize(size) {
  const i = Math.floor(Math.log(size) / Math.log(1024));
  return (size / Math.pow(1024, i)).toFixed(2) * 1 + " " + ["B", "kB", "MB", "GB", "TB"][i];
}
