import { Chart as ChartJS, ArcElement, Tooltip, Legend, CategoryScale, LinearScale, BarElement, Title } from "chart.js";
import ChartDataLabels from "chartjs-plugin-datalabels";
import { Doughnut } from "react-chartjs-2";
import FlagOfFrance from "../../../assets/FlagOfFrance.jpeg";

ChartJS.register(ArcElement, Tooltip, Legend, CategoryScale, LinearScale, BarElement, Title, ChartDataLabels);

export default ({ company, indicators }) => {
  return (
    <>
      <section id="General-Information">
        <h1 className="text-4xl font-bold mb-4 font-montserrat">ESG Impact report</h1>
        <div className="text-lg font-semibold mb-5  font-montserrat">Generated on {new Date().toLocaleDateString()}</div>
        <p className="font-medium mb-10  font-montserrat">
          This report has been created thanks to the data provided through the Matter form, combined to the official European Commission ESG regulatory initiatives, which are
          publically available.
        </p>
        <div className="text-lg font-bold mb-3 font-montserrat">For the following fund</div>
        <div className="shadow-full rounded-md p-6 flex items-center space-x-4 font-montserrat">
          <img src={company.logo} alt={company.fund_name} className="h-14 w-14 rounded-full font-montserrat" />
          <div>
            <div className="text-base font-bold font-montserrat">{company.fund_name}</div>
            <div className="flex items-center space-x-5 font-montserrat">
              <div className="flex items-center space-x-2 h-full font-montserrat">
                <img src={FlagOfFrance} className="h-6 w-6 rounded-full border border-gray-600" alt="French Flag"></img>
                {/* TO FILL WITH COUNTRY'S FLAG */}
                <div className="text-sm uppercase font-medium font-montserrat">{company.country}</div>
              </div>

              <div className="text-sm uppercase font-medium rounded text-white bg-black py-2 px-2.5">{company.currency}</div>
            </div>
          </div>
        </div>
      </section>

      <section className="pt-10" id="page-2">
        <h1 className="text-2xl font-bold mb-8 font-montserrat">General information</h1>
        <div className="text-lg font-bold mb-3  font-montserrat">{company.name} - Company info</div>
        <div className="border border-gray-200 rounded-lg p-6 space-y-3 mb-10 font-montserrat">
          {company.incorporation_at && (
            <div className="flex items-center space-x-5 font-montserrat">
              <div className="font-bold w-full max-w-xs font-montserrat">Incorporated at</div>
              <div className="font-montserrat">: {new Date(company.incorporation_at).toLocaleDateString()}</div>
            </div>
          )}
          {company.website && (
            <div className="flex items-center space-x-5 font-montserrat">
              <div className="font-bold w-full max-w-xs font-montserrat">Website</div>
              <div className="font-montserrat">: {company.website}</div>
            </div>
          )}
          {company.sector && (
            <div className="flex items-center space-x-5 font-montserrat">
              <div className="font-bold w-full max-w-xs font-montserrat">Business sector and activity</div>
              <div className=" font-montserrat">: {company.sector}</div>
            </div>
          )}

          {company.nace_codes && (
            <div className="flex items-center space-x-5 font-montserrat">
              <div className="font-bold w-full max-w-xs font-montserrat">Nace code</div>
              <div className="font-montserrat">: {company.nace_codes}</div>
            </div>
          )}
        </div>
      </section>
    </>
  );
};
