import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useHistory } from "react-router-dom";

import { Label } from "@/components/ui/label";
import { Input } from "@/components/ui/input";
import { Button } from "@/components/ui/button";
import Loader from "@/components/loader";

import api from "@/services/api";
import FileInput from "@/components/file-input";
import { ChevronLeft } from "lucide-react";

export const Edit = ({ match }) => {
  const [dataset, setDataset] = useState();
  const [values, setValues] = useState({});

  const history = useHistory();

  const datasetId = match.params.id;

  useEffect(() => {
    (async () => {
      const { data } = await api.get(`/dataset/${datasetId}`);
      setDataset(data);
      setValues(data);
    })();
  }, [datasetId]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const { data } = await api.put(`/dataset/${datasetId}`, values);
    toast.success("dataset updated successfully");
  };

  const handleDelete = async () => {
    if (!confirm("Are you sure?")) return;
    const { data } = await api.remove(`/dataset/${datasetId}`);
    toast.success("Delete!");
    history.push("/dataset");
  };

  if (!dataset) return <Loader />;

  return (
    <div className="container mx-auto">
      <h1 className="text-2xl font-bold mb-6 flex items-center space-x-2"><ChevronLeft onClick={() => history.goBack()} className='cursor-pointer' /> <span>{dataset.name}</span></h1>
      <form onSubmit={handleSubmit} className="">
        <div className="flex items-center justify-between">
          <fieldset className="space-y-2 mb-4">
            <Label>Logo</Label>
            <div className="w-[200px]  rounded-lg overflow-hidden border-muted-foreground">
              <FileInput value={values?.logo} folder="/dataset" onChange={(e) => setValues({ ...values, logo: e.target.value[0] })} />
            </div>
          </fieldset>
        </div>
        <div className="grid grid-cols-2 lg:grid-cols-3 gap-4 mb-5">
          <fieldset className="space-y-2">
            <Label>Name</Label>
            <Input value={values.name} onChange={(e) => setValues({ ...values, name: e.target.value })} placeholder="Full name" />
          </fieldset>
          <fieldset className="space-x-2 flex items-center">
            <input id="isdefault" className="w-5 h-5" type="checkbox" checked={values.isDefault} onChange={(e) => setValues({ ...values, isDefault: e.target.checked })} />
            <Label htmlFor="isdefault">Is default ?</Label>
          </fieldset>
          <fieldset className="space-y-2 col-span-3">
            <Label>Description</Label>
            <textarea className="p-3 border border-gray-200 w-full rounded-lg min-h-[100px]" value={values.description} onChange={(e) => setValues({ ...values, description: e.target.value })} />
          </fieldset>
        </div>
        <div className="space-x-2">
          <Button type="submit" className="bg-accent hover:bg-[#3760fa]">
            Update
          </Button>
          <Button type="button" onClick={handleDelete} className="bg-red-500 hover:bg-red-400">
            Delete
          </Button>
        </div>
      </form>
    </div>
  );
};
