import React, { useEffect, useState } from "react"
import { Link } from "react-router-dom";
import Loader from '@/components/loader'

export default function List() {
    const [data, setData] = useState([])

    const getData = async () => {
        try {
            //fetchData here
            ////////////////////
            const tmp = [
                { _id: "0", name: "Vasco", logo: "/logotmp/vasco.png" },
                { _id: "1", name: "Meteory", logo: "/logotmp/meteory.png" },
                { _id: "2", name: "Carbometrix", logo: "/logotmp/carbometrix.png" },
            ]
            setData(tmp)
            ////////////////////
        }
        catch (e) {
            console.log(e)
        }
    }

    useEffect(() => {
        getData()
    }, [])

    if (!data) return <Loader />

    return (
        <div className="container my-4">
            <h1 className="text-4xl font-bold text-black-100">Marketplace</h1>
            <p className="text-gray-600 py-5">Explore available connections to facilitate your reporting operations. Access and manage it all from Matter</p>
            <div className="grid md:grid-cols-3 md:gap-4 gap-2">
                {data.map(e => (
                    <Link to={`/market/${e._id}`} className="shadow-sm hover:shadow-md border rounded-lg bg-white flex justify-center items-center w-full h-[130px] ">
                        <img alt='logo' src={e.logo} className="p-5 w-[200px]" />
                    </Link>
                ))}
            </div>
        </div>

    )
}