import { useEffect, useMemo, useState } from "react";
import { Link, useHistory, useLocation } from "react-router-dom";

import { Avatar, AvatarFallback, AvatarImage } from "@/components/ui/avatar";
import { Button } from "@/components/ui/button";
import Loader from "@/components/loader";

import Questionnaire from "./questionnaire";
import Documents from "./documents";
import ExportCSV from "./export";
import Participants from "./participants";

import useStore from "@/store";
import api from "@/services/api";
import { Loader2 } from "lucide-react";

export default ({ match }) => {
  const [questionnaire, setQuestionnaire] = useState();
  const { search } = useLocation();

  const { user } = useStore();

  const searchParams = useMemo(() => new URLSearchParams(search), [search]);
  const activeTab = searchParams.get("tab") || "questionnaire";
  const history = useHistory();

  useEffect(() => {
    fetch();
  }, [match.params.id]);

  async function fetch() {
    const { data } = await api.get(`/questionnaire/${match.params.id}`);
    setQuestionnaire(data);
  }

  if (!questionnaire) return <Loader />;

  return (
    <section>
      <header className="bg-[#F7F9F8] pt-5 relative">
        <div className="container">
          <div className="mb-10 flex md:flex-row flex-col justify-between gap-y-5">
            <div className="flex items-center md:gap-x-10 gap-x-5 w-full">
              <Link to={`/company/${questionnaire.company_id}`}>
                <Avatar className="lg:w-[150px] w-[75px] lg:h-[150px] h-[75px] border">
                  <AvatarImage src={questionnaire.logo} alt={questionnaire.name} />
                  {/* <AvatarFallback>{questionnaire?.name?.charAt(0).toUpperCase()}</AvatarFallback> */}
                </Avatar>
              </Link>

              <div className="space-y-2.5">
                <h1 className="md:text-2xl text-xl font-bold">{questionnaire.name}</h1>
                <p className="text-purple-2 text-sm font-medium">
                  {new Date(questionnaire.start_at).toLocaleDateString([], { year: "numeric", month: "long", day: "numeric" })} -{" "}
                  {new Date(questionnaire.end_at).toLocaleDateString([], { year: "numeric", month: "long", day: "numeric" })}
                </p>
                <ProgressBar value={questionnaire.current_index} total={questionnaire.total_indicators} />
              </div>
            </div>

            <div className="flex gap-y-2.5 ml-4 pt-2">
              {user.role === "partner" && (
                <div className="w-20">
                  <Button onClick={() => history.push(`/questionnaire/edit/${questionnaire._id}`)} variant="outline" size="icon" className="text-muted-foreground w-20">
                    Edit
                  </Button>
                </div>
              )}
              <FinalRapport questionnaire={questionnaire} />
              <div className="ml-2">
                <ExportCSV questionnaire={questionnaire} />
              </div>
            </div>
          </div>
          <nav>
            <ul className="flex items-center lg:gap-x-10 gap-x-5 overflow-x-auto -mx-8 px-8">
              <Tab name="questionnaire" value={`${questionnaire.current_index}/${questionnaire.total_indicators}`} selected={activeTab === "questionnaire"} />
              <Tab name="documents" selected={activeTab === "documents"} />
              {user.role === "partner" && <Tab name="participants" selected={activeTab === "participants"} />}
            </ul>
          </nav>
        </div>
      </header>

      <div className="container lg:py-10 py-3">
        {activeTab === "questionnaire" && <Questionnaire questionnaire={questionnaire} onUpdate={fetch} />}
        {activeTab === "participants" && <Participants questionnaire={questionnaire} />}
        {activeTab === "documents" && <Documents questionnaire={questionnaire} />}
      </div>
    </section>
  );
};

const FinalRapport = ({ questionnaire }) => {
  const [loading, setLoading] = useState(false);

  async function handleDownload() {
    setLoading(true);
    try {
      try {
        const response = await api.pdf(`/questionnaire/${questionnaire._id}/pdf`);
        const blob = new Blob([response], { type: "application/pdf" });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        a.download = "Matter.pdf"; // temporary
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
      } catch (error) {
        console.log(error);
      }
    } catch (e) {
      console.error(e);
    }
    setLoading(false);
  }

  return (
    <div className="ml-2">
      <Button className="bg-accent hover:bg-[#3760fa]" onClick={handleDownload} disabled={loading}>
        Download report as PDF <Loader2 className={`inline-block transition-all ${loading ? "animate-spin w-4 ml-2" : "w-0"}`} size={16} />
      </Button>
    </div>
  );
};

const Tab = ({ name, value = "", selected }) => {
  return (
    <li key={name}>
      <Link
        to={`?tab=${name}`}
        className={`block text-sm font-medium pb-3 border-b-2 transition-colors hover:border-purple-2 hover:text-purple-2 capitalize ${
          selected ? "border-purple-2 text-purple-2" : "border-transparent text-muted-foreground"
        }`}
      >
        {name}
        {/* {value && <span className="py-1 px-2 text-xs rounded-3xl inline-block bg-gray-200">{value}</span>} */}
      </Link>
    </li>
  );
};

const ProgressBar = ({ value, total }) => {
  const progressWidth = (value / total) * 100;
  const barColor = progressWidth < 50 ? "bg-red-500" : progressWidth < 80 ? "bg-yellow-500" : "bg-blue-500";

  return (
    <div className="pt-4">
      <div className="text-sm text-muted-foreground font-medium">{`${value} / ${total} questions`}</div>
      <div className="flex items-center mt-2">
        <div className="w-full bg-gray-300 rounded-full">
          <div className={`h-2 rounded-full transition-all ${barColor}`} style={{ width: `${progressWidth}%` }}></div>
        </div>
      </div>
    </div>
  );
};
