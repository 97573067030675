import { useEffect, useRef, useState } from "react";
import { ChevronsUpDown, Check, ChevronLeft } from "lucide-react";
import toast from "react-hot-toast";
import { useHistory } from "react-router-dom";

import { Command, CommandEmpty, CommandGroup, CommandInput, CommandItem } from "@/components/ui/command";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "@/components/ui/select";
import { Popover, PopoverContent, PopoverTrigger } from "@/components/ui/popover";
import { Label } from "@/components/ui/label";
import { Input } from "@/components/ui/input";
import { Button } from "@/components/ui/button";
import Loader from "@/components/loader";

import { BUSINESS_SECTOR, CURRENCY, DOMICILE, NACE_CODE } from "@/services/constants";

import { cn } from "@/utils";

import api from "@/services/api";
import FileInput from "@/components/file-input";
import MultiSelect from "@/components/MultiSelect";

export const CompanyEdit = ({ match }) => {
  const [company, setCompany] = useState();
  const [values, setValues] = useState({});
  const [selectedNaceCodes, setSelectedNaceCodes] = useState([]);
  const initialSelectedNaceCodesRef = useRef(true);
  const history = useHistory();

  useEffect(() => {
    (async () => {
      const { data } = await api.get(`/company/${match.params.id}`);
      setCompany(data);
      setValues(data);
    })();
  }, [match.params.id]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const { ok } = await api.put(`/company/${match.params.id}`, values);
    if (!ok) return toast.error("Something went wrong");
    toast.success("company updated successfully");
  };

  const handleDelete = async () => {
    if (!confirm("Are you sure?")) return;
    const { ok } = await api.remove(`/company/${match.params.id}`);
    if (!ok) return toast.error("Something went wrong");
    toast.success("Delete!");
    history.push("/");
  };

  useEffect(() => {
    if (values.nace_codes && values.nace_codes.length > 0) {
      if (initialSelectedNaceCodesRef.current) {
        initialSelectedNaceCodesRef.current = false;
        const tempSelectedNaceCodes = values.nace_codes.map((codeString) => {
          const naceCode = NACE_CODE.find((item) => `${item.code} - ${item.name}` === codeString);
          return naceCode ? naceCode : null;
        });
        setSelectedNaceCodes(tempSelectedNaceCodes);
      }
    }
  }, [values.nace_codes]);

  useEffect(() => {
    if (Array.isArray(selectedNaceCodes)) {
      const tempNaceCodes = selectedNaceCodes.map((naceCode) => `${naceCode.code} - ${naceCode.name}`);
      setValues({ ...values, nace_codes: tempNaceCodes });
    }
  }, [selectedNaceCodes]);

  const handleSelectedNaceCode = (e) => {
    const selectedNaceCodes = [];
    e.forEach((codeString) => {
      const section = codeString.value?.substring(0, 1);
      if (codeString.value?.length > 2) {
        const code = codeString.value?.substring(1);
        const naceCode = NACE_CODE.find((item) => item.section === section && item.code === code);

        if (naceCode) {
          selectedNaceCodes.push(naceCode);
        }
      } else {
        const naceCode = NACE_CODE.find((item) => item.section === section && item.code === "");

        if (naceCode) {
          selectedNaceCodes.push(naceCode);
        }
      }
    });
    setSelectedNaceCodes(selectedNaceCodes);
  };

  if (!company) return <Loader />;

  return (
    <div className="container mx-auto p-6">
      <h1 className="text-2xl font-bold mb-6 flex items-center space-x-2">
        <ChevronLeft onClick={() => history.goBack()} className="cursor-pointer" /> <span>{company.name}</span>
      </h1>
      <form onSubmit={handleSubmit} className="grid grid-cols-2 lg:grid-cols-3 gap-4">
        <fieldset className="space-y-2 col-span-3">
          <Label>Logo</Label>
          <div className="w-[130px] aspect-square rounded-lg overflow-hidden border-muted-foreground">
            <FileInput value={values.logo} folder="/logo" onChange={(e) => setValues({ ...values, logo: e.target.value[0] })} />
          </div>
        </fieldset>
        <fieldset className="space-y-2">
          <Label>Company Name</Label>
          <Input value={values.name} onChange={(e) => setValues({ ...values, name: e.target.value })} placeholder="Full name" />
        </fieldset>
        <fieldset className="space-y-2">
          <Label>SIREN</Label>
          <Input value={values.SIREN} onChange={(e) => setValues({ ...values, SIREN: e.target.value })} placeholder="SIREN" />
        </fieldset>
        <fieldset className="space-y-2">
          <Label>Legal Structure</Label>
          <Input value={values.structure_code || ""} onChange={(e) => setValues({ ...values, structure_code: e.target.value })} placeholder="Structure Code" />
        </fieldset>
        <fieldset className="space-y-2">
          <Label>Address</Label>
          <Input value={values.address} onChange={(e) => setValues({ ...values, address: e.target.value })} placeholder="Address" />
        </fieldset>
        <fieldset className="space-y-2">
          <Label>City</Label>
          <Input value={values.city} onChange={(e) => setValues({ ...values, city: e.target.value })} placeholder="City" />
        </fieldset>
        <fieldset className="space-y-2">
          <Label>Zip code</Label>
          <Input value={values.zipcode} onChange={(e) => setValues({ ...values, zipcode: e.target.value })} placeholder="Zip code" />
        </fieldset>
        <fieldset className="space-y-2">
          <Label>Country</Label>
          <Input value={values.country} onChange={(e) => setValues({ ...values, country: e.target.value })} placeholder="Country" />
        </fieldset>
        <fieldset className="space-y-2">
          <Label>Domicile of Origin</Label>
          <Select defaultValue={values.region} onValueChange={(region) => setValues({ ...values, region })}>
            <SelectTrigger className="text-left">
              <SelectValue placeholder="Domicile" />
            </SelectTrigger>
            <SelectContent>
              {DOMICILE.map((domicile, i) => (
                <SelectItem value={domicile.code} key={i}>
                  {domicile.name}
                </SelectItem>
              ))}
            </SelectContent>
          </Select>
        </fieldset>
        <fieldset className="space-y-2">
          <Label>Currency</Label>
          <Select defaultValue={values.currency} onValueChange={(currency) => setValues({ ...values, currency })}>
            <SelectTrigger className="text-left">
              <SelectValue placeholder="Currency" />
            </SelectTrigger>
            <SelectContent>
              {CURRENCY.map((currency, i) => (
                <SelectItem value={currency.code} key={i}>
                  {currency.code} - {currency.name}
                </SelectItem>
              ))}
            </SelectContent>
          </Select>
        </fieldset>

        <fieldset className="space-y-2">
          <Label>Incorporation Date</Label>
          <Input
            value={values.incorporation_at ? values.incorporation_at.split("T")[0] : ""}
            onChange={(e) => setValues({ ...values, incorporation_at: e.target.value })}
            placeholder="Incorporation Date"
            type="date"
          />
        </fieldset>
        <fieldset className="space-y-2">
          <Label>Investment Date</Label>
          <Input
            value={values.investment_at ? values?.investment_at.split("T")[0] : ""}
            onChange={(e) => setValues({ ...values, investment_at: e.target.value })}
            placeholder="Invesment Date"
            type="date"
          />
        </fieldset>
        <fieldset className="space-y-2">
          <Label>Turnover</Label>
          <Input type="number" value={values.turnover} onChange={(e) => setValues({ ...values, turnover: e.target.value })} />
        </fieldset>

        <fieldset className="space-y-2">
          <Label>Total employees</Label>
          <Input type="number" value={values.employees} onChange={(e) => setValues({ ...values, employees: e.target.value })} />
        </fieldset>

        <fieldset className="space-y-2"></fieldset>
        <fieldset className="space-y-2"></fieldset>

        <fieldset className="space-y-2">
          <Label>Sector</Label>
          <Select defaultValue={values.sector} onValueChange={(sector) => setValues({ ...values, sector })}>
            <SelectTrigger className="text-left">
              <SelectValue placeholder="Sector" />
            </SelectTrigger>
            <SelectContent>
              {BUSINESS_SECTOR.map((sector, i) => (
                <SelectItem value={sector} key={i}>
                  {sector}
                </SelectItem>
              ))}
            </SelectContent>
          </Select>
        </fieldset>
        <fieldset className="space-y-2">
          <Label>NACE code(s) - Enter to add other NACE code(s)</Label>
          <MultiSelect
            width="w-full"
            naceCode={true}
            values={selectedNaceCodes?.map((naceCode) => ({ value: `${naceCode.section}${naceCode.code}` }))}
            onSelectedChange={handleSelectedNaceCode}
            placeholder="Select..."
            options={NACE_CODE.map((nc) => ({ value: `${nc.section}${nc.code}`, label: `${nc.code} ${nc.name}` }))}
            renderOption={(item) => <div>{item}</div>}
          />
        </fieldset>
        <fieldset className="space-y-2"></fieldset>
        <fieldset className="space-y-2">
          <Label>Website (Optional)</Label>
          <Input value={values.website} onChange={(e) => setValues({ ...values, website: e.target.value })} placeholder="Website" />
        </fieldset>
      </form>
      <div className="space-x-2 mt-6">
        <Button type="submit" onClick={handleSubmit} className="bg-accent hover:bg-[#3760fa]">
          Update
        </Button>
        <Button type="button" onClick={handleDelete} className="bg-red-500 hover:bg-red-400">
          Delete
        </Button>
      </div>
    </div>
  );
};

const NaceCode = ({ value, onChange }) => {
  const [open, setOpen] = useState(false);

  return (
    <Popover open={open} onOpenChange={setOpen}>
      <PopoverTrigger asChild>
        <Button variant="outline" role="combobox" aria-expanded={open} className={`justify-between w-full font-normal ${!value && "text-[#BDBFC0]"}`}>
          {value || "Choose an option..."}
          <ChevronsUpDown className="ml-2 h-4 w-4 shrink-0 opacity-50" />
        </Button>
      </PopoverTrigger>
      <PopoverContent className="p-0 lg:w-[720px] w-max">
        <Command>
          <CommandInput placeholder="Search framework..." />
          <CommandEmpty>No data found</CommandEmpty>
          <CommandGroup className="max-h-56 overflow-y-auto">
            {/* we might want to limit it to 20 because later if it will cause lag */}
            {NACE_CODE.map((code, i) => (
              <CommandItem
                key={i}
                value={`${code.code} - ${code.name}`}
                onSelect={() => {
                  onChange(`${code.code} - ${code.name}` === value ? "" : `${code.code} - ${code.name}`);
                  setOpen(false);
                }}
              >
                <Check className={cn("mr-2 h-4 w-4", value === code.name ? "opacity-100" : "opacity-0")} />
                {code.code} - {code.name}
              </CommandItem>
            ))}
          </CommandGroup>
        </Command>
      </PopoverContent>
    </Popover>
  );
};

const FundSelector = ({ value, onChange }) => {
  const [funds, setFunds] = useState([]);

  useEffect(() => {
    getFunds();
  }, []);

  const getFunds = async () => {
    const { data, ok } = await api.post("/fund/search", { search: "" });
    if (!ok) return toast.error("Something went wrong");
    setFunds(data);
  };

  return (
    <Select
      defaultValue={value}
      onValueChange={(fundId) => {
        const fund = funds.find((p) => fundId === p._id);

        onChange(fund);
      }}
    >
      <SelectTrigger className="text-left">
        <SelectValue placeholder="Controller fund" />
      </SelectTrigger>
      <SelectContent>
        {funds.map((fund) => (
          <SelectItem value={fund._id} key={fund._id}>
            {fund.name}
          </SelectItem>
        ))}
      </SelectContent>
    </Select>
  );
};
