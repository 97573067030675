import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { Search } from "lucide-react";
import toast from "react-hot-toast";

import { Dialog, DialogContent, DialogFooter, DialogHeader, DialogTitle, DialogTrigger } from "@/components/ui/dialog";
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from "@/components/ui/table";
import { Avatar, AvatarFallback, AvatarImage } from "@/components/ui/avatar";
import { Label } from "@/components/ui/label";
import { Input } from "@/components/ui/input";
import { Button } from "@/components/ui/button";

import api from "@/services/api";
import useStore from "@/store";

export const List = () => {
  const [users, setUsers] = useState([]);
  const [filters, setFilters] = useState({ search: "" });
  const { user } = useStore();
  const { fund } = useStore();

  const history = useHistory();

  useEffect(() => {
    getTeam();
  }, [filters]);

  const getTeam = async () => {
    filters.fund_id = fund._id;
    filters.role = "partner"
    const { data } = await api.post("/user/search", filters);
    setUsers(data);
  };

  return (
    <section>
      <div className="flex flex-col md:flex-row gap-5 items-center justify-between mb-10">
        <h1 className="text-4xl font-bold shrink-0">Team</h1>
        <div className="flex items-center justify-end gap-x-2.5 w-full lg:w-1/2">
          <div className="w-full relative">
            <Input className="relative pl-10" placeholder="Type here..." value={filters.search} onChange={(e) => setFilters({ ...filters, search: e.target.value })} />
            <Search className="absolute top-1/2 -translate-y-1/2 left-2 text-muted-foreground" />
          </div>
          <Adduser onDone={getTeam} />
        </div>
      </div>

      <div className="border rounded-xl overflow-hidden">
        <Table>
          <TableHeader>
            <TableRow>
              <TableHead>Name</TableHead>
              <TableHead className="text-center whitespace-nowrap">Date Added</TableHead>
              <TableHead></TableHead>
            </TableRow>
          </TableHeader>
          <TableBody>
            {users.filter(e => e._id !== user._id).map((user) => (
              <TableRow key={user._id} className="cursor-pointer" onClick={() => history.push(`/team/${user._id}`)}>
                <TableCell className="flex items-center gap-x-4 whitespace-nowrap">
                  <Avatar className="w-9 h-9">
                    {/* <AvatarImage src={user.avatar} alt={user.name} /> */}
                    <AvatarFallback className="bg-amber-700 text-white">{user.name.charAt(0).toUpperCase()}</AvatarFallback>
                  </Avatar>
                  <div>
                    <p className="text-black">{user.name}</p>
                    <p className="text-gray-400">{user.role}</p>
                  </div>
                </TableCell>
                <TableCell className="text-center whitespace-nowrap">
                  {new Date().toLocaleDateString("en-US", {
                    year: "numeric",
                    month: "long",
                    day: "numeric",
                  })}
                </TableCell>
                <TableCell className="text-right">
                  {user.invitation_token && (
                    <div
                      className="text-blue-700 underline cursor-pointer"
                      onClick={(e) => {
                        e.stopPropagation();
                        navigator.clipboard.writeText(
                          `${window.location.host}/auth/signup_partner?token=${user.invitation_token}`
                        );
                        toast.success("URL copied to clipboard");
                      }}
                    >
                      Renew invitation
                    </div>
                  )}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </div>
    </section>
  );
};

const Adduser = ({ onDone }) => {
  const [values, setValues] = useState({});
  const [open, setOpen] = useState(false);
  const { fund } = useStore();

  const history = useHistory();

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      values.fund_id = fund._id;
      values.fund_name = fund.name;
      values.role = "partner";

      const { data } = await api.post("/user/invite", values);
      toast.success("user created successfully");
      setOpen(false);
      onDone();
      // history.push(`/team/${data._id}`);
    } catch (e) {
      console.log(e);
      toast.error(e?.code || "ERROR");
    }
  };

  return (
    <Dialog open={open} onOpenChange={() => setOpen(!open)}>
      <DialogTrigger asChild>
        <Button variant="blue">Invite new team member</Button>
      </DialogTrigger>
      <DialogContent className="max-w-lg">
        <DialogHeader>
          <DialogTitle className="mb-5">Invite new team member</DialogTitle>
        </DialogHeader>
        <form onSubmit={handleSubmit}>
          <div className="space-y-4">
            <fieldset className="space-y-2">
              <Label>Name</Label>
              <Input placeholder="Jhon dee" required value={values.name} onChange={(e) => setValues({ ...values, name: e.target.value })} />
            </fieldset>
            <fieldset className="space-y-2">
              <Label>Email</Label>
              <Input placeholder="jhon@doe.com" required value={values.email} onChange={(e) => setValues({ ...values, email: e.target.value })} />
            </fieldset>
          </div>
          <DialogFooter className="mt-3">
            <Button type="submit" className="bg-accent hover:bg-[#3760fa]">
              Send invite
            </Button>
          </DialogFooter>
        </form>
      </DialogContent>
    </Dialog>
  );
};
