export const BUSINESS_SECTOR = [
  "Agriculture, Forestry and Fishing",
  "Construction",
  "Electricity, Gas, Steam & Air Conditioning Supply",
  "Entertainment",
  "Financial & Insurance Activities",
  "Health Care & Services",
  "Accomodation & Food Service Activities",
  "Institutions",
  "Manufacturing",
  "Mining & Quarrying",
  "Real Estate Activities",
  "Services",
  "Information & Communication",
  "Transportation & Storage",
  "Wholesale and Retail Trade & Repair of Motor Vehicles and Mororcycles",
];

export const COMPANY_TYPE = ["Investee company", "Private company"];

export const DOMICILE = [
  { name: "Afghanistan", code: "AF" },
  { name: "Åland Islands", code: "AX" },
  { name: "Albania", code: "AL" },
  { name: "Algeria", code: "DZ" },
  { name: "American Samoa", code: "AS" },
  { name: "AndorrA", code: "AD" },
  { name: "Angola", code: "AO" },
  { name: "Anguilla", code: "AI" },
  { name: "Antarctica", code: "AQ" },
  { name: "Antigua and Barbuda", code: "AG" },
  { name: "Argentina", code: "AR" },
  { name: "Armenia", code: "AM" },
  { name: "Aruba", code: "AW" },
  { name: "Australia", code: "AU" },
  { name: "Austria", code: "AT" },
  { name: "Azerbaijan", code: "AZ" },
  { name: "Bahamas", code: "BS" },
  { name: "Bahrain", code: "BH" },
  { name: "Bangladesh", code: "BD" },
  { name: "Barbados", code: "BB" },
  { name: "Belarus", code: "BY" },
  { name: "Belgium", code: "BE" },
  { name: "Belize", code: "BZ" },
  { name: "Benin", code: "BJ" },
  { name: "Bermuda", code: "BM" },
  { name: "Bhutan", code: "BT" },
  { name: "Bolivia", code: "BO" },
  { name: "Bosnia and Herzegovina", code: "BA" },
  { name: "Botswana", code: "BW" },
  { name: "Bouvet Island", code: "BV" },
  { name: "Brazil", code: "BR" },
  { name: "British Indian Ocean Territory", code: "IO" },
  { name: "Brunei Darussalam", code: "BN" },
  { name: "Bulgaria", code: "BG" },
  { name: "Burkina Faso", code: "BF" },
  { name: "Burundi", code: "BI" },
  { name: "Cambodia", code: "KH" },
  { name: "Cameroon", code: "CM" },
  { name: "Canada", code: "CA" },
  { name: "Cape Verde", code: "CV" },
  { name: "Cayman Islands", code: "KY" },
  { name: "Central African Republic", code: "CF" },
  { name: "Chad", code: "TD" },
  { name: "Chile", code: "CL" },
  { name: "China", code: "CN" },
  { name: "Christmas Island", code: "CX" },
  { name: "Cocos (Keeling) Islands", code: "CC" },
  { name: "Colombia", code: "CO" },
  { name: "Comoros", code: "KM" },
  { name: "Congo", code: "CG" },
  { name: "Congo, The Democratic Republic of the", code: "CD" },
  { name: "Cook Islands", code: "CK" },
  { name: "Costa Rica", code: "CR" },
  { name: "Cote D'Ivoire", code: "CI" },
  { name: "Croatia", code: "HR" },
  { name: "Cuba", code: "CU" },
  { name: "Cyprus", code: "CY" },
  { name: "Czech Republic", code: "CZ" },
  { name: "Denmark", code: "DK" },
  { name: "Djibouti", code: "DJ" },
  { name: "Dominica", code: "DM" },
  { name: "Dominican Republic", code: "DO" },
  { name: "Ecuador", code: "EC" },
  { name: "Egypt", code: "EG" },
  { name: "El Salvador", code: "SV" },
  { name: "Equatorial Guinea", code: "GQ" },
  { name: "Eritrea", code: "ER" },
  { name: "Estonia", code: "EE" },
  { name: "Ethiopia", code: "ET" },
  { name: "Falkland Islands (Malvinas)", code: "FK" },
  { name: "Faroe Islands", code: "FO" },
  { name: "Fiji", code: "FJ" },
  { name: "Finland", code: "FI" },
  { name: "France", code: "FR" },
  { name: "French Guiana", code: "GF" },
  { name: "French Polynesia", code: "PF" },
  { name: "French Southern Territories", code: "TF" },
  { name: "Gabon", code: "GA" },
  { name: "Gambia", code: "GM" },
  { name: "Georgia", code: "GE" },
  { name: "Germany", code: "DE" },
  { name: "Ghana", code: "GH" },
  { name: "Gibraltar", code: "GI" },
  { name: "Greece", code: "GR" },
  { name: "Greenland", code: "GL" },
  { name: "Grenada", code: "GD" },
  { name: "Guadeloupe", code: "GP" },
  { name: "Guam", code: "GU" },
  { name: "Guatemala", code: "GT" },
  { name: "Guernsey", code: "GG" },
  { name: "Guinea", code: "GN" },
  { name: "Guinea-Bissau", code: "GW" },
  { name: "Guyana", code: "GY" },
  { name: "Haiti", code: "HT" },
  { name: "Heard Island and Mcdonald Islands", code: "HM" },
  { name: "Holy See (Vatican City State)", code: "VA" },
  { name: "Honduras", code: "HN" },
  { name: "Hong Kong", code: "HK" },
  { name: "Hungary", code: "HU" },
  { name: "Iceland", code: "IS" },
  { name: "India", code: "IN" },
  { name: "Indonesia", code: "ID" },
  { name: "Iran, Islamic Republic Of", code: "IR" },
  { name: "Iraq", code: "IQ" },
  { name: "Ireland", code: "IE" },
  { name: "Isle of Man", code: "IM" },
  { name: "Israel", code: "IL" },
  { name: "Italy", code: "IT" },
  { name: "Jamaica", code: "JM" },
  { name: "Japan", code: "JP" },
  { name: "Jersey", code: "JE" },
  { name: "Jordan", code: "JO" },
  { name: "Kazakhstan", code: "KZ" },
  { name: "Kenya", code: "KE" },
  { name: "Kiribati", code: "KI" },
  { name: "Korea, Democratic People'S Republic of", code: "KP" },
  { name: "Korea, Republic of", code: "KR" },
  { name: "Kuwait", code: "KW" },
  { name: "Kyrgyzstan", code: "KG" },
  { name: "Lao People'S Democratic Republic", code: "LA" },
  { name: "Latvia", code: "LV" },
  { name: "Lebanon", code: "LB" },
  { name: "Lesotho", code: "LS" },
  { name: "Liberia", code: "LR" },
  { name: "Libyan Arab Jamahiriya", code: "LY" },
  { name: "Liechtenstein", code: "LI" },
  { name: "Lithuania", code: "LT" },
  { name: "Luxembourg", code: "LU" },
  { name: "Macao", code: "MO" },
  { name: "Macedonia, The Former Yugoslav Republic of", code: "MK" },
  { name: "Madagascar", code: "MG" },
  { name: "Malawi", code: "MW" },
  { name: "Malaysia", code: "MY" },
  { name: "Maldives", code: "MV" },
  { name: "Mali", code: "ML" },
  { name: "Malta", code: "MT" },
  { name: "Marshall Islands", code: "MH" },
  { name: "Martinique", code: "MQ" },
  { name: "Mauritania", code: "MR" },
  { name: "Mauritius", code: "MU" },
  { name: "Mayotte", code: "YT" },
  { name: "Mexico", code: "MX" },
  { name: "Micronesia, Federated States of", code: "FM" },
  { name: "Moldova, Republic of", code: "MD" },
  { name: "Monaco", code: "MC" },
  { name: "Mongolia", code: "MN" },
  { name: "Montserrat", code: "MS" },
  { name: "Morocco", code: "MA" },
  { name: "Mozambique", code: "MZ" },
  { name: "Myanmar", code: "MM" },
  { name: "Namibia", code: "NA" },
  { name: "Nauru", code: "NR" },
  { name: "Nepal", code: "NP" },
  { name: "Netherlands", code: "NL" },
  { name: "Netherlands Antilles", code: "AN" },
  { name: "New Caledonia", code: "NC" },
  { name: "New Zealand", code: "NZ" },
  { name: "Nicaragua", code: "NI" },
  { name: "Niger", code: "NE" },
  { name: "Nigeria", code: "NG" },
  { name: "Niue", code: "NU" },
  { name: "Norfolk Island", code: "NF" },
  { name: "Northern Mariana Islands", code: "MP" },
  { name: "Norway", code: "NO" },
  { name: "Oman", code: "OM" },
  { name: "Pakistan", code: "PK" },
  { name: "Palau", code: "PW" },
  { name: "Palestinian Territory, Occupied", code: "PS" },
  { name: "Panama", code: "PA" },
  { name: "Papua New Guinea", code: "PG" },
  { name: "Paraguay", code: "PY" },
  { name: "Peru", code: "PE" },
  { name: "Philippines", code: "PH" },
  { name: "Pitcairn", code: "PN" },
  { name: "Poland", code: "PL" },
  { name: "Portugal", code: "PT" },
  { name: "Puerto Rico", code: "PR" },
  { name: "Qatar", code: "QA" },
  { name: "Reunion", code: "RE" },
  { name: "Romania", code: "RO" },
  { name: "Russian Federation", code: "RU" },
  { name: "RWANDA", code: "RW" },
  { name: "Saint Helena", code: "SH" },
  { name: "Saint Kitts and Nevis", code: "KN" },
  { name: "Saint Lucia", code: "LC" },
  { name: "Saint Pierre and Miquelon", code: "PM" },
  { name: "Saint Vincent and the Grenadines", code: "VC" },
  { name: "Samoa", code: "WS" },
  { name: "San Marino", code: "SM" },
  { name: "Sao Tome and Principe", code: "ST" },
  { name: "Saudi Arabia", code: "SA" },
  { name: "Senegal", code: "SN" },
  { name: "Serbia and Montenegro", code: "CS" },
  { name: "Seychelles", code: "SC" },
  { name: "Sierra Leone", code: "SL" },
  { name: "Singapore", code: "SG" },
  { name: "Slovakia", code: "SK" },
  { name: "Slovenia", code: "SI" },
  { name: "Solomon Islands", code: "SB" },
  { name: "Somalia", code: "SO" },
  { name: "South Africa", code: "ZA" },
  { name: "South Georgia and the South Sandwich Islands", code: "GS" },
  { name: "Spain", code: "ES" },
  { name: "Sri Lanka", code: "LK" },
  { name: "Sudan", code: "SD" },
  { name: "Suriname", code: "SR" },
  { name: "Svalbard and Jan Mayen", code: "SJ" },
  { name: "Swaziland", code: "SZ" },
  { name: "Sweden", code: "SE" },
  { name: "Switzerland", code: "CH" },
  { name: "Syrian Arab Republic", code: "SY" },
  { name: "Taiwan, Province of China", code: "TW" },
  { name: "Tajikistan", code: "TJ" },
  { name: "Tanzania, United Republic of", code: "TZ" },
  { name: "Thailand", code: "TH" },
  { name: "Timor-Leste", code: "TL" },
  { name: "Togo", code: "TG" },
  { name: "Tokelau", code: "TK" },
  { name: "Tonga", code: "TO" },
  { name: "Trinidad and Tobago", code: "TT" },
  { name: "Tunisia", code: "TN" },
  { name: "Turkey", code: "TR" },
  { name: "Turkmenistan", code: "TM" },
  { name: "Turks and Caicos Islands", code: "TC" },
  { name: "Tuvalu", code: "TV" },
  { name: "Uganda", code: "UG" },
  { name: "Ukraine", code: "UA" },
  { name: "United Arab Emirates", code: "AE" },
  { name: "United Kingdom", code: "GB" },
  { name: "United States", code: "US" },
  { name: "United States Minor Outlying Islands", code: "UM" },
  { name: "Uruguay", code: "UY" },
  { name: "Uzbekistan", code: "UZ" },
  { name: "Vanuatu", code: "VU" },
  { name: "Venezuela", code: "VE" },
  { name: "Viet Nam", code: "VN" },
  { name: "Virgin Islands, British", code: "VG" },
  { name: "Virgin Islands, U.S.", code: "VI" },
  { name: "Wallis and Futuna", code: "WF" },
  { name: "Western Sahara", code: "EH" },
  { name: "Yemen", code: "YE" },
  { name: "Zambia", code: "ZM" },
  { name: "Zimbabwe", code: "ZW" },
];

export const NACE_CODE = [
  {
    section: "A",
    code: "",
    name: "AGRICULTURE, FORESTRY AND FISHING",
    level: 1,
  },
  {
    section: "A",
    code: "01",
    name: "Crop and animal production, hunting and related service activities",
    level: 2,
  },
  {
    section: "A",
    code: "01.1",
    name: "Growing of non-perennial crops",
    level: 3,
  },
  {
    section: "A",
    code: "01.11",
    name: "Growing of cereals (except rice), leguminous crops and oil seeds",
    level: 4,
  },
  {
    section: "A",
    code: "01.12",
    name: "Growing of rice",
    level: 4,
  },
  {
    section: "A",
    code: "01.13",
    name: "Growing of vegetables and melons, roots and tubers",
    level: 4,
  },
  {
    section: "A",
    code: "01.14",
    name: "Growing of sugar cane",
    level: 4,
  },
  {
    section: "A",
    code: "01.15",
    name: "Growing of tobacco",
    level: 4,
  },
  {
    section: "A",
    code: "01.16",
    name: "Growing of fibre crops",
    level: 4,
  },
  {
    section: "A",
    code: "01.19",
    name: "Growing of other non-perennial crops",
    level: 4,
  },
  {
    section: "A",
    code: "01.2",
    name: "Growing of perennial crops",
    level: 3,
  },
  {
    section: "A",
    code: "01.21",
    name: "Growing of grapes",
    level: 4,
  },
  {
    section: "A",
    code: "01.22",
    name: "Growing of tropical and subtropical fruits",
    level: 4,
  },
  {
    section: "A",
    code: "01.23",
    name: "Growing of citrus fruits",
    level: 4,
  },
  {
    section: "A",
    code: "01.24",
    name: "Growing of pome fruits and stone fruits",
    level: 4,
  },
  {
    section: "A",
    code: "01.25",
    name: "Growing of other tree and bush fruits and nuts",
    level: 4,
  },
  {
    section: "A",
    code: "01.26",
    name: "Growing of oleaginous fruits",
    level: 4,
  },
  {
    section: "A",
    code: "01.27",
    name: "Growing of beverage crops",
    level: 4,
  },
  {
    section: "A",
    code: "01.28",
    name: "Growing of spices, aromatic, drug and pharmaceutical crops",
    level: 4,
  },
  {
    section: "A",
    code: "01.29",
    name: "Growing of other perennial crops",
    level: 4,
  },
  {
    section: "A",
    code: "01.3",
    name: "Plant propagation",
    level: 3,
  },
  {
    section: "A",
    code: "01.30",
    name: "Plant propagation",
    level: 4,
  },
  {
    section: "A",
    code: "01.4",
    name: "Animal production",
    level: 3,
  },
  {
    section: "A",
    code: "01.41",
    name: "Raising of dairy cattle",
    level: 4,
  },
  {
    section: "A",
    code: "01.42",
    name: "Raising of other cattle and buffaloes",
    level: 4,
  },
  {
    section: "A",
    code: "01.43",
    name: "Raising of horses and other equines",
    level: 4,
  },
  {
    section: "A",
    code: "01.44",
    name: "Raising of camels and camelids",
    level: 4,
  },
  {
    section: "A",
    code: "01.45",
    name: "Raising of sheep and goats",
    level: 4,
  },
  {
    section: "A",
    code: "01.46",
    name: "Raising of swine/pigs",
    level: 4,
  },
  {
    section: "A",
    code: "01.47",
    name: "Raising of poultry",
    level: 4,
  },
  {
    section: "A",
    code: "01.49",
    name: "Raising of other animals",
    level: 4,
  },
  {
    section: "A",
    code: "01.5",
    name: "Mixed farming",
    level: 3,
  },
  {
    section: "A",
    code: "01.50",
    name: "Mixed farming",
    level: 4,
  },
  {
    section: "A",
    code: "01.6",
    name: "Support activities to agriculture and post-harvest crop activities",
    level: 3,
  },
  {
    section: "A",
    code: "01.61",
    name: "Support activities for crop production",
    level: 4,
  },
  {
    section: "A",
    code: "01.62",
    name: "Support activities for animal production",
    level: 4,
  },
  {
    section: "A",
    code: "01.63",
    name: "Post-harvest crop activities",
    level: 4,
  },
  {
    section: "A",
    code: "01.64",
    name: "Seed processing for propagation",
    level: 4,
  },
  {
    section: "A",
    code: "01.7",
    name: "Hunting, trapping and related service activities",
    level: 3,
  },
  {
    section: "A",
    code: "01.70",
    name: "Hunting, trapping and related service activities",
    level: 4,
  },
  {
    section: "A",
    code: "02",
    name: "Forestry and logging",
    level: 2,
  },
  {
    section: "A",
    code: "02.1",
    name: "Silviculture and other forestry activities",
    level: 3,
  },
  {
    section: "A",
    code: "02.10",
    name: "Silviculture and other forestry activities",
    level: 4,
  },
  {
    section: "A",
    code: "02.2",
    name: "Logging",
    level: 3,
  },
  {
    section: "A",
    code: "02.20",
    name: "Logging",
    level: 4,
  },
  {
    section: "A",
    code: "02.3",
    name: "Gathering of wild growing non-wood products",
    level: 3,
  },
  {
    section: "A",
    code: "02.30",
    name: "Gathering of wild growing non-wood products",
    level: 4,
  },
  {
    section: "A",
    code: "02.4",
    name: "Support services to forestry",
    level: 3,
  },
  {
    section: "A",
    code: "02.40",
    name: "Support services to forestry",
    level: 4,
  },
  {
    section: "A",
    code: "03",
    name: "Fishing and aquaculture",
    level: 2,
  },
  {
    section: "A",
    code: "03.1",
    name: "Fishing",
    level: 3,
  },
  {
    section: "A",
    code: "03.11",
    name: "Marine fishing",
    level: 4,
  },
  {
    section: "A",
    code: "03.12",
    name: "Freshwater fishing",
    level: 4,
  },
  {
    section: "A",
    code: "03.2",
    name: "Aquaculture",
    level: 3,
  },
  {
    section: "A",
    code: "03.21",
    name: "Marine aquaculture",
    level: 4,
  },
  {
    section: "A",
    code: "03.22",
    name: "Freshwater aquaculture",
    level: 4,
  },
  {
    section: "B",
    code: "",
    name: "MINING AND QUARRYING",
    level: 1,
  },
  {
    section: "B",
    code: "05",
    name: "Mining of coal and lignite",
    level: 2,
  },
  {
    section: "B",
    code: "05.1",
    name: "Mining of hard coal",
    level: 3,
  },
  {
    section: "B",
    code: "05.10",
    name: "Mining of hard coal",
    level: 4,
  },
  {
    section: "B",
    code: "05.2",
    name: "Mining of lignite",
    level: 3,
  },
  {
    section: "B",
    code: "05.20",
    name: "Mining of lignite",
    level: 4,
  },
  {
    section: "B",
    code: "06",
    name: "Extraction of crude petroleum and natural gas",
    level: 2,
  },
  {
    section: "B",
    code: "06.1",
    name: "Extraction of crude petroleum",
    level: 3,
  },
  {
    section: "B",
    code: "06.10",
    name: "Extraction of crude petroleum",
    level: 4,
  },
  {
    section: "B",
    code: "06.2",
    name: "Extraction of natural gas",
    level: 3,
  },
  {
    section: "B",
    code: "06.20",
    name: "Extraction of natural gas",
    level: 4,
  },
  {
    section: "B",
    code: "07",
    name: "Mining of metal ores",
    level: 2,
  },
  {
    section: "B",
    code: "07.1",
    name: "Mining of iron ores",
    level: 3,
  },
  {
    section: "B",
    code: "07.10",
    name: "Mining of iron ores",
    level: 4,
  },
  {
    section: "B",
    code: "07.2",
    name: "Mining of non-ferrous metal ores",
    level: 3,
  },
  {
    section: "B",
    code: "07.21",
    name: "Mining of uranium and thorium ores",
    level: 4,
  },
  {
    section: "B",
    code: "07.29",
    name: "Mining of other non-ferrous metal ores",
    level: 4,
  },
  {
    section: "B",
    code: "08",
    name: "Other mining and quarrying",
    level: 2,
  },
  {
    section: "B",
    code: "08.1",
    name: "Quarrying of stone, sand and clay",
    level: 3,
  },
  {
    section: "B",
    code: "08.11",
    name: "Quarrying of ornamental and building stone, limestone, gypsum, chalk and slate",
    level: 4,
  },
  {
    section: "B",
    code: "08.12",
    name: "Operation of gravel and sand pits; mining of clays and kaolin",
    level: 4,
  },
  {
    section: "B",
    code: "08.9",
    name: "Mining and quarrying n.e.c.",
    level: 3,
  },
  {
    section: "B",
    code: "08.91",
    name: "Mining of chemical and fertiliser minerals",
    level: 4,
  },
  {
    section: "B",
    code: "08.92",
    name: "Extraction of peat",
    level: 4,
  },
  {
    section: "B",
    code: "08.93",
    name: "Extraction of salt",
    level: 4,
  },
  {
    section: "B",
    code: "08.99",
    name: "Other mining and quarrying n.e.c.",
    level: 4,
  },
  {
    section: "B",
    code: "09",
    name: "Mining support service activities",
    level: 2,
  },
  {
    section: "B",
    code: "09.1",
    name: "Support activities for petroleum and natural gas extraction",
    level: 3,
  },
  {
    section: "B",
    code: "09.10",
    name: "Support activities for petroleum and natural gas extraction",
    level: 4,
  },
  {
    section: "B",
    code: "09.9",
    name: "Support activities for other mining and quarrying",
    level: 3,
  },
  {
    section: "B",
    code: "09.90",
    name: "Support activities for other mining and quarrying",
    level: 4,
  },
  {
    section: "C",
    code: "",
    name: "MANUFACTURING",
    level: 1,
  },
  {
    section: "C",
    code: "10",
    name: "Manufacture of food products",
    level: 2,
  },
  {
    section: "C",
    code: "10.1",
    name: "Processing and preserving of meat and production of meat products",
    level: 3,
  },
  {
    section: "C",
    code: "10.11",
    name: "Processing and preserving of meat",
    level: 4,
  },
  {
    section: "C",
    code: "10.12",
    name: "Processing and preserving of poultry meat",
    level: 4,
  },
  {
    section: "C",
    code: "10.13",
    name: "Production of meat and poultry meat products",
    level: 4,
  },
  {
    section: "C",
    code: "10.2",
    name: "Processing and preserving of fish, crustaceans and molluscs",
    level: 3,
  },
  {
    section: "C",
    code: "10.20",
    name: "Processing and preserving of fish, crustaceans and molluscs",
    level: 4,
  },
  {
    section: "C",
    code: "10.3",
    name: "Processing and preserving of fruit and vegetables",
    level: 3,
  },
  {
    section: "C",
    code: "10.31",
    name: "Processing and preserving of potatoes",
    level: 4,
  },
  {
    section: "C",
    code: "10.32",
    name: "Manufacture of fruit and vegetable juice",
    level: 4,
  },
  {
    section: "C",
    code: "10.39",
    name: "Other processing and preserving of fruit and vegetables",
    level: 4,
  },
  {
    section: "C",
    code: "10.4",
    name: "Manufacture of vegetable and animal oils and fats",
    level: 3,
  },
  {
    section: "C",
    code: "10.41",
    name: "Manufacture of oils and fats",
    level: 4,
  },
  {
    section: "C",
    code: "10.42",
    name: "Manufacture of margarine and similar edible fats",
    level: 4,
  },
  {
    section: "C",
    code: "10.5",
    name: "Manufacture of dairy products",
    level: 3,
  },
  {
    section: "C",
    code: "10.51",
    name: "Operation of dairies and cheese making",
    level: 4,
  },
  {
    section: "C",
    code: "10.52",
    name: "Manufacture of ice cream",
    level: 4,
  },
  {
    section: "C",
    code: "10.6",
    name: "Manufacture of grain mill products, starches and starch products",
    level: 3,
  },
  {
    section: "C",
    code: "10.61",
    name: "Manufacture of grain mill products",
    level: 4,
  },
  {
    section: "C",
    code: "10.62",
    name: "Manufacture of starches and starch products",
    level: 4,
  },
  {
    section: "C",
    code: "10.7",
    name: "Manufacture of bakery and farinaceous products",
    level: 3,
  },
  {
    section: "C",
    code: "10.71",
    name: "Manufacture of bread; manufacture of fresh pastry goods and cakes",
    level: 4,
  },
  {
    section: "C",
    code: "10.72",
    name: "Manufacture of rusks and biscuits; manufacture of preserved pastry goods and cakes",
    level: 4,
  },
  {
    section: "C",
    code: "10.73",
    name: "Manufacture of macaroni, noodles, couscous and similar farinaceous products",
    level: 4,
  },
  {
    section: "C",
    code: "10.8",
    name: "Manufacture of other food products",
    level: 3,
  },
  {
    section: "C",
    code: "10.81",
    name: "Manufacture of sugar",
    level: 4,
  },
  {
    section: "C",
    code: "10.82",
    name: "Manufacture of cocoa, chocolate and sugar confectionery",
    level: 4,
  },
  {
    section: "C",
    code: "10.83",
    name: "Processing of tea and coffee",
    level: 4,
  },
  {
    section: "C",
    code: "10.84",
    name: "Manufacture of condiments and seasonings",
    level: 4,
  },
  {
    section: "C",
    code: "10.85",
    name: "Manufacture of prepared meals and dishes",
    level: 4,
  },
  {
    section: "C",
    code: "10.86",
    name: "Manufacture of homogenised food preparations and dietetic food",
    level: 4,
  },
  {
    section: "C",
    code: "10.89",
    name: "Manufacture of other food products n.e.c.",
    level: 4,
  },
  {
    section: "C",
    code: "10.9",
    name: "Manufacture of prepared animal feeds",
    level: 3,
  },
  {
    section: "C",
    code: "10.91",
    name: "Manufacture of prepared feeds for farm animals",
    level: 4,
  },
  {
    section: "C",
    code: "10.92",
    name: "Manufacture of prepared pet foods",
    level: 4,
  },
  {
    section: "C",
    code: "11",
    name: "Manufacture of beverages",
    level: 2,
  },
  {
    section: "C",
    code: "11.0",
    name: "Manufacture of beverages",
    level: 3,
  },
  {
    section: "C",
    code: "11.01",
    name: "Distilling, rectifying and blending of spirits",
    level: 4,
  },
  {
    section: "C",
    code: "11.02",
    name: "Manufacture of wine from grape",
    level: 4,
  },
  {
    section: "C",
    code: "11.03",
    name: "Manufacture of cider and other fruit wines",
    level: 4,
  },
  {
    section: "C",
    code: "11.04",
    name: "Manufacture of other non-distilled fermented beverages",
    level: 4,
  },
  {
    section: "C",
    code: "11.05",
    name: "Manufacture of beer",
    level: 4,
  },
  {
    section: "C",
    code: "11.06",
    name: "Manufacture of malt",
    level: 4,
  },
  {
    section: "C",
    code: "11.07",
    name: "Manufacture of soft drinks; production of mineral waters and other bottled waters",
    level: 4,
  },
  {
    section: "C",
    code: "12",
    name: "Manufacture of tobacco products",
    level: 2,
  },
  {
    section: "C",
    code: "12.0",
    name: "Manufacture of tobacco products",
    level: 3,
  },
  {
    section: "C",
    code: "12.00",
    name: "Manufacture of tobacco products",
    level: 4,
  },
  {
    section: "C",
    code: "13",
    name: "Manufacture of textiles",
    level: 2,
  },
  {
    section: "C",
    code: "13.1",
    name: "Preparation and spinning of textile fibres",
    level: 3,
  },
  {
    section: "C",
    code: "13.10",
    name: "Preparation and spinning of textile fibres",
    level: 4,
  },
  {
    section: "C",
    code: "13.2",
    name: "Weaving of textiles",
    level: 3,
  },
  {
    section: "C",
    code: "13.20",
    name: "Weaving of textiles",
    level: 4,
  },
  {
    section: "C",
    code: "13.3",
    name: "Finishing of textiles",
    level: 3,
  },
  {
    section: "C",
    code: "13.30",
    name: "Finishing of textiles",
    level: 4,
  },
  {
    section: "C",
    code: "13.9",
    name: "Manufacture of other textiles",
    level: 3,
  },
  {
    section: "C",
    code: "13.91",
    name: "Manufacture of knitted and crocheted fabrics",
    level: 4,
  },
  {
    section: "C",
    code: "13.92",
    name: "Manufacture of made-up textile articles, except apparel",
    level: 4,
  },
  {
    section: "C",
    code: "13.93",
    name: "Manufacture of carpets and rugs",
    level: 4,
  },
  {
    section: "C",
    code: "13.94",
    name: "Manufacture of cordage, rope, twine and netting",
    level: 4,
  },
  {
    section: "C",
    code: "13.95",
    name: "Manufacture of non-wovens and articles made from non-wovens, except apparel",
    level: 4,
  },
  {
    section: "C",
    code: "13.96",
    name: "Manufacture of other technical and industrial textiles",
    level: 4,
  },
  {
    section: "C",
    code: "13.99",
    name: "Manufacture of other textiles n.e.c.",
    level: 4,
  },
  {
    section: "C",
    code: "14",
    name: "Manufacture of wearing apparel",
    level: 2,
  },
  {
    section: "C",
    code: "14.1",
    name: "Manufacture of wearing apparel, except fur apparel",
    level: 3,
  },
  {
    section: "C",
    code: "14.11",
    name: "Manufacture of leather clothes",
    level: 4,
  },
  {
    section: "C",
    code: "14.12",
    name: "Manufacture of workwear",
    level: 4,
  },
  {
    section: "C",
    code: "14.13",
    name: "Manufacture of other outerwear",
    level: 4,
  },
  {
    section: "C",
    code: "14.14",
    name: "Manufacture of underwear",
    level: 4,
  },
  {
    section: "C",
    code: "14.19",
    name: "Manufacture of other wearing apparel and accessories",
    level: 4,
  },
  {
    section: "C",
    code: "14.2",
    name: "Manufacture of articles of fur",
    level: 3,
  },
  {
    section: "C",
    code: "14.20",
    name: "Manufacture of articles of fur",
    level: 4,
  },
  {
    section: "C",
    code: "14.3",
    name: "Manufacture of knitted and crocheted apparel",
    level: 3,
  },
  {
    section: "C",
    code: "14.31",
    name: "Manufacture of knitted and crocheted hosiery",
    level: 4,
  },
  {
    section: "C",
    code: "14.39",
    name: "Manufacture of other knitted and crocheted apparel",
    level: 4,
  },
  {
    section: "C",
    code: "15",
    name: "Manufacture of leather and related products",
    level: 2,
  },
  {
    section: "C",
    code: "15.1",
    name: "Tanning and dressing of leather; manufacture of luggage, handbags, saddlery and harness; dressing and dyeing of fur",
    level: 3,
  },
  {
    section: "C",
    code: "15.11",
    name: "Tanning and dressing of leather; dressing and dyeing of fur",
    level: 4,
  },
  {
    section: "C",
    code: "15.12",
    name: "Manufacture of luggage, handbags and the like, saddlery and harness",
    level: 4,
  },
  {
    section: "C",
    code: "15.2",
    name: "Manufacture of footwear",
    level: 3,
  },
  {
    section: "C",
    code: "15.20",
    name: "Manufacture of footwear",
    level: 4,
  },
  {
    section: "C",
    code: "16",
    name: "Manufacture of wood and of products of wood and cork, except furniture; manufacture of articles of straw and plaiting materials",
    level: 2,
  },
  {
    section: "C",
    code: "16.1",
    name: "Sawmilling and planing of wood",
    level: 3,
  },
  {
    section: "C",
    code: "16.10",
    name: "Sawmilling and planing of wood",
    level: 4,
  },
  {
    section: "C",
    code: "16.2",
    name: "Manufacture of products of wood, cork, straw and plaiting materials",
    level: 3,
  },
  {
    section: "C",
    code: "16.21",
    name: "Manufacture of veneer sheets and wood-based panels",
    level: 4,
  },
  {
    section: "C",
    code: "16.22",
    name: "Manufacture of assembled parquet floors",
    level: 4,
  },
  {
    section: "C",
    code: "16.23",
    name: "Manufacture of other builders' carpentry and joinery",
    level: 4,
  },
  {
    section: "C",
    code: "16.24",
    name: "Manufacture of wooden containers",
    level: 4,
  },
  {
    section: "C",
    code: "16.29",
    name: "Manufacture of other products of wood; manufacture of articles of cork, straw and plaiting materials",
    level: 4,
  },
  {
    section: "C",
    code: "17",
    name: "Manufacture of paper and paper products",
    level: 2,
  },
  {
    section: "C",
    code: "17.1",
    name: "Manufacture of pulp, paper and paperboard",
    level: 3,
  },
  {
    section: "C",
    code: "17.11",
    name: "Manufacture of pulp",
    level: 4,
  },
  {
    section: "C",
    code: "17.12",
    name: "Manufacture of paper and paperboard",
    level: 4,
  },
  {
    section: "C",
    code: "17.2",
    name: "Manufacture of articles of paper and paperboard",
    level: 3,
  },
  {
    section: "C",
    code: "17.21",
    name: "Manufacture of corrugated paper and paperboard and of containers of paper and paperboard",
    level: 4,
  },
  {
    section: "C",
    code: "17.22",
    name: "Manufacture of household and sanitary goods and of toilet requisites",
    level: 4,
  },
  {
    section: "C",
    code: "17.23",
    name: "Manufacture of paper stationery",
    level: 4,
  },
  {
    section: "C",
    code: "17.24",
    name: "Manufacture of wallpaper",
    level: 4,
  },
  {
    section: "C",
    code: "17.29",
    name: "Manufacture of other articles of paper and paperboard",
    level: 4,
  },
  {
    section: "C",
    code: "18",
    name: "Printing and reproduction of recorded media",
    level: 2,
  },
  {
    section: "C",
    code: "18.1",
    name: "Printing and service activities related to printing",
    level: 3,
  },
  {
    section: "C",
    code: "18.11",
    name: "Printing of newspapers",
    level: 4,
  },
  {
    section: "C",
    code: "18.12",
    name: "Other printing",
    level: 4,
  },
  {
    section: "C",
    code: "18.13",
    name: "Pre-press and pre-media services",
    level: 4,
  },
  {
    section: "C",
    code: "18.14",
    name: "Binding and related services",
    level: 4,
  },
  {
    section: "C",
    code: "18.2",
    name: "Reproduction of recorded media",
    level: 3,
  },
  {
    section: "C",
    code: "18.20",
    name: "Reproduction of recorded media",
    level: 4,
  },
  {
    section: "C",
    code: "19",
    name: "Manufacture of coke and refined petroleum products",
    level: 2,
  },
  {
    section: "C",
    code: "19.1",
    name: "Manufacture of coke oven products",
    level: 3,
  },
  {
    section: "C",
    code: "19.10",
    name: "Manufacture of coke oven products",
    level: 4,
  },
  {
    section: "C",
    code: "19.2",
    name: "Manufacture of refined petroleum products",
    level: 3,
  },
  {
    section: "C",
    code: "19.20",
    name: "Manufacture of refined petroleum products",
    level: 4,
  },
  {
    section: "C",
    code: "20",
    name: "Manufacture of chemicals and chemical products",
    level: 2,
  },
  {
    section: "C",
    code: "20.1",
    name: "Manufacture of basic chemicals, fertilisers and nitrogen compounds, plastics and synthetic rubber in primary forms",
    level: 3,
  },
  {
    section: "C",
    code: "20.11",
    name: "Manufacture of industrial gases",
    level: 4,
  },
  {
    section: "C",
    code: "20.12",
    name: "Manufacture of dyes and pigments",
    level: 4,
  },
  {
    section: "C",
    code: "20.13",
    name: "Manufacture of other inorganic basic chemicals",
    level: 4,
  },
  {
    section: "C",
    code: "20.14",
    name: "Manufacture of other organic basic chemicals",
    level: 4,
  },
  {
    section: "C",
    code: "20.15",
    name: "Manufacture of fertilisers and nitrogen compounds",
    level: 4,
  },
  {
    section: "C",
    code: "20.16",
    name: "Manufacture of plastics in primary forms",
    level: 4,
  },
  {
    section: "C",
    code: "20.17",
    name: "Manufacture of synthetic rubber in primary forms",
    level: 4,
  },
  {
    section: "C",
    code: "20.2",
    name: "Manufacture of pesticides and other agrochemical products",
    level: 3,
  },
  {
    section: "C",
    code: "20.20",
    name: "Manufacture of pesticides and other agrochemical products",
    level: 4,
  },
  {
    section: "C",
    code: "20.3",
    name: "Manufacture of paints, varnishes and similar coatings, printing ink and mastics",
    level: 3,
  },
  {
    section: "C",
    code: "20.30",
    name: "Manufacture of paints, varnishes and similar coatings, printing ink and mastics",
    level: 4,
  },
  {
    section: "C",
    code: "20.4",
    name: "Manufacture of soap and detergents, cleaning and polishing preparations, perfumes and toilet preparations",
    level: 3,
  },
  {
    section: "C",
    code: "20.41",
    name: "Manufacture of soap and detergents, cleaning and polishing preparations",
    level: 4,
  },
  {
    section: "C",
    code: "20.42",
    name: "Manufacture of perfumes and toilet preparations",
    level: 4,
  },
  {
    section: "C",
    code: "20.5",
    name: "Manufacture of other chemical products",
    level: 3,
  },
  {
    section: "C",
    code: "20.51",
    name: "Manufacture of explosives",
    level: 4,
  },
  {
    section: "C",
    code: "20.52",
    name: "Manufacture of glues",
    level: 4,
  },
  {
    section: "C",
    code: "20.53",
    name: "Manufacture of essential oils",
    level: 4,
  },
  {
    section: "C",
    code: "20.59",
    name: "Manufacture of other chemical products n.e.c.",
    level: 4,
  },
  {
    section: "C",
    code: "20.6",
    name: "Manufacture of man-made fibres",
    level: 3,
  },
  {
    section: "C",
    code: "20.60",
    name: "Manufacture of man-made fibres",
    level: 4,
  },
  {
    section: "C",
    code: "21",
    name: "Manufacture of basic pharmaceutical products and pharmaceutical preparations",
    level: 2,
  },
  {
    section: "C",
    code: "21.1",
    name: "Manufacture of basic pharmaceutical products",
    level: 3,
  },
  {
    section: "C",
    code: "21.10",
    name: "Manufacture of basic pharmaceutical products",
    level: 4,
  },
  {
    section: "C",
    code: "21.2",
    name: "Manufacture of pharmaceutical preparations",
    level: 3,
  },
  {
    section: "C",
    code: "21.20",
    name: "Manufacture of pharmaceutical preparations",
    level: 4,
  },
  {
    section: "C",
    code: "22",
    name: "Manufacture of rubber and plastic products",
    level: 2,
  },
  {
    section: "C",
    code: "22.1",
    name: "Manufacture of rubber products",
    level: 3,
  },
  {
    section: "C",
    code: "22.11",
    name: "Manufacture of rubber tyres and tubes; retreading and rebuilding of rubber tyres",
    level: 4,
  },
  {
    section: "C",
    code: "22.19",
    name: "Manufacture of other rubber products",
    level: 4,
  },
  {
    section: "C",
    code: "22.2",
    name: "Manufacture of plastic products",
    level: 3,
  },
  {
    section: "C",
    code: "22.21",
    name: "Manufacture of plastic plates, sheets, tubes and profiles",
    level: 4,
  },
  {
    section: "C",
    code: "22.22",
    name: "Manufacture of plastic packing goods",
    level: 4,
  },
  {
    section: "C",
    code: "22.23",
    name: "Manufacture of builders’ ware of plastic",
    level: 4,
  },
  {
    section: "C",
    code: "22.29",
    name: "Manufacture of other plastic products",
    level: 4,
  },
  {
    section: "C",
    code: "23",
    name: "Manufacture of other non-metallic mineral products",
    level: 2,
  },
  {
    section: "C",
    code: "23.1",
    name: "Manufacture of glass and glass products",
    level: 3,
  },
  {
    section: "C",
    code: "23.11",
    name: "Manufacture of flat glass",
    level: 4,
  },
  {
    section: "C",
    code: "23.12",
    name: "Shaping and processing of flat glass",
    level: 4,
  },
  {
    section: "C",
    code: "23.13",
    name: "Manufacture of hollow glass",
    level: 4,
  },
  {
    section: "C",
    code: "23.14",
    name: "Manufacture of glass fibres",
    level: 4,
  },
  {
    section: "C",
    code: "23.19",
    name: "Manufacture and processing of other glass, including technical glassware",
    level: 4,
  },
  {
    section: "C",
    code: "23.2",
    name: "Manufacture of refractory products",
    level: 3,
  },
  {
    section: "C",
    code: "23.20",
    name: "Manufacture of refractory products",
    level: 4,
  },
  {
    section: "C",
    code: "23.3",
    name: "Manufacture of clay building materials",
    level: 3,
  },
  {
    section: "C",
    code: "23.31",
    name: "Manufacture of ceramic tiles and flags",
    level: 4,
  },
  {
    section: "C",
    code: "23.32",
    name: "Manufacture of bricks, tiles and construction products, in baked clay",
    level: 4,
  },
  {
    section: "C",
    code: "23.4",
    name: "Manufacture of other porcelain and ceramic products",
    level: 3,
  },
  {
    section: "C",
    code: "23.41",
    name: "Manufacture of ceramic household and ornamental articles",
    level: 4,
  },
  {
    section: "C",
    code: "23.42",
    name: "Manufacture of ceramic sanitary fixtures",
    level: 4,
  },
  {
    section: "C",
    code: "23.43",
    name: "Manufacture of ceramic insulators and insulating fittings",
    level: 4,
  },
  {
    section: "C",
    code: "23.44",
    name: "Manufacture of other technical ceramic products",
    level: 4,
  },
  {
    section: "C",
    code: "23.49",
    name: "Manufacture of other ceramic products",
    level: 4,
  },
  {
    section: "C",
    code: "23.5",
    name: "Manufacture of cement, lime and plaster",
    level: 3,
  },
  {
    section: "C",
    code: "23.51",
    name: "Manufacture of cement",
    level: 4,
  },
  {
    section: "C",
    code: "23.52",
    name: "Manufacture of lime and plaster",
    level: 4,
  },
  {
    section: "C",
    code: "23.6",
    name: "Manufacture of articles of concrete, cement and plaster",
    level: 3,
  },
  {
    section: "C",
    code: "23.61",
    name: "Manufacture of concrete products for construction purposes",
    level: 4,
  },
  {
    section: "C",
    code: "23.62",
    name: "Manufacture of plaster products for construction purposes",
    level: 4,
  },
  {
    section: "C",
    code: "23.63",
    name: "Manufacture of ready-mixed concrete",
    level: 4,
  },
  {
    section: "C",
    code: "23.64",
    name: "Manufacture of mortars",
    level: 4,
  },
  {
    section: "C",
    code: "23.65",
    name: "Manufacture of fibre cement",
    level: 4,
  },
  {
    section: "C",
    code: "23.69",
    name: "Manufacture of other articles of concrete, plaster and cement",
    level: 4,
  },
  {
    section: "C",
    code: "23.7",
    name: "Cutting, shaping and finishing of stone",
    level: 3,
  },
  {
    section: "C",
    code: "23.70",
    name: "Cutting, shaping and finishing of stone",
    level: 4,
  },
  {
    section: "C",
    code: "23.9",
    name: "Manufacture of abrasive products and non-metallic mineral products n.e.c.",
    level: 3,
  },
  {
    section: "C",
    code: "23.91",
    name: "Production of abrasive products",
    level: 4,
  },
  {
    section: "C",
    code: "23.99",
    name: "Manufacture of other non-metallic mineral products n.e.c.",
    level: 4,
  },
  {
    section: "C",
    code: "24",
    name: "Manufacture of basic metals",
    level: 2,
  },
  {
    section: "C",
    code: "24.1",
    name: "Manufacture of basic iron and steel and of ferro-alloys",
    level: 3,
  },
  {
    section: "C",
    code: "24.10",
    name: "Manufacture of basic iron and steel and of ferro-alloys",
    level: 4,
  },
  {
    section: "C",
    code: "24.2",
    name: "Manufacture of tubes, pipes, hollow profiles and related fittings, of steel",
    level: 3,
  },
  {
    section: "C",
    code: "24.20",
    name: "Manufacture of tubes, pipes, hollow profiles and related fittings, of steel",
    level: 4,
  },
  {
    section: "C",
    code: "24.3",
    name: "Manufacture of other products of first processing of steel",
    level: 3,
  },
  {
    section: "C",
    code: "24.31",
    name: "Cold drawing of bars",
    level: 4,
  },
  {
    section: "C",
    code: "24.32",
    name: "Cold rolling of narrow strip",
    level: 4,
  },
  {
    section: "C",
    code: "24.33",
    name: "Cold forming or folding",
    level: 4,
  },
  {
    section: "C",
    code: "24.34",
    name: "Cold drawing of wire",
    level: 4,
  },
  {
    section: "C",
    code: "24.4",
    name: "Manufacture of basic precious and other non-ferrous metals",
    level: 3,
  },
  {
    section: "C",
    code: "24.41",
    name: "Precious metals production",
    level: 4,
  },
  {
    section: "C",
    code: "24.42",
    name: "Aluminium production",
    level: 4,
  },
  {
    section: "C",
    code: "24.43",
    name: "Lead, zinc and tin production",
    level: 4,
  },
  {
    section: "C",
    code: "24.44",
    name: "Copper production",
    level: 4,
  },
  {
    section: "C",
    code: "24.45",
    name: "Other non-ferrous metal production",
    level: 4,
  },
  {
    section: "C",
    code: "24.46",
    name: "Processing of nuclear fuel",
    level: 4,
  },
  {
    section: "C",
    code: "24.5",
    name: "Casting of metals",
    level: 3,
  },
  {
    section: "C",
    code: "24.51",
    name: "Casting of iron",
    level: 4,
  },
  {
    section: "C",
    code: "24.52",
    name: "Casting of steel",
    level: 4,
  },
  {
    section: "C",
    code: "24.53",
    name: "Casting of light metals",
    level: 4,
  },
  {
    section: "C",
    code: "24.54",
    name: "Casting of other non-ferrous metals",
    level: 4,
  },
  {
    section: "C",
    code: "25",
    name: "Manufacture of fabricated metal products, except machinery and equipment",
    level: 2,
  },
  {
    section: "C",
    code: "25.1",
    name: "Manufacture of structural metal products",
    level: 3,
  },
  {
    section: "C",
    code: "25.11",
    name: "Manufacture of metal structures and parts of structures",
    level: 4,
  },
  {
    section: "C",
    code: "25.12",
    name: "Manufacture of doors and windows of metal",
    level: 4,
  },
  {
    section: "C",
    code: "25.2",
    name: "Manufacture of tanks, reservoirs and containers of metal",
    level: 3,
  },
  {
    section: "C",
    code: "25.21",
    name: "Manufacture of central heating radiators and boilers",
    level: 4,
  },
  {
    section: "C",
    code: "25.29",
    name: "Manufacture of other tanks, reservoirs and containers of metal",
    level: 4,
  },
  {
    section: "C",
    code: "25.3",
    name: "Manufacture of steam generators, except central heating hot water boilers",
    level: 3,
  },
  {
    section: "C",
    code: "25.30",
    name: "Manufacture of steam generators, except central heating hot water boilers",
    level: 4,
  },
  {
    section: "C",
    code: "25.4",
    name: "Manufacture of weapons and ammunition",
    level: 3,
  },
  {
    section: "C",
    code: "25.40",
    name: "Manufacture of weapons and ammunition",
    level: 4,
  },
  {
    section: "C",
    code: "25.5",
    name: "Forging, pressing, stamping and roll-forming of metal; powder metallurgy",
    level: 3,
  },
  {
    section: "C",
    code: "25.50",
    name: "Forging, pressing, stamping and roll-forming of metal; powder metallurgy",
    level: 4,
  },
  {
    section: "C",
    code: "25.6",
    name: "Treatment and coating of metals; machining",
    level: 3,
  },
  {
    section: "C",
    code: "25.61",
    name: "Treatment and coating of metals",
    level: 4,
  },
  {
    section: "C",
    code: "25.62",
    name: "Machining",
    level: 4,
  },
  {
    section: "C",
    code: "25.7",
    name: "Manufacture of cutlery, tools and general hardware",
    level: 3,
  },
  {
    section: "C",
    code: "25.71",
    name: "Manufacture of cutlery",
    level: 4,
  },
  {
    section: "C",
    code: "25.72",
    name: "Manufacture of locks and hinges",
    level: 4,
  },
  {
    section: "C",
    code: "25.73",
    name: "Manufacture of tools",
    level: 4,
  },
  {
    section: "C",
    code: "25.9",
    name: "Manufacture of other fabricated metal products",
    level: 3,
  },
  {
    section: "C",
    code: "25.91",
    name: "Manufacture of steel drums and similar containers",
    level: 4,
  },
  {
    section: "C",
    code: "25.92",
    name: "Manufacture of light metal packaging",
    level: 4,
  },
  {
    section: "C",
    code: "25.93",
    name: "Manufacture of wire products, chain and springs",
    level: 4,
  },
  {
    section: "C",
    code: "25.94",
    name: "Manufacture of fasteners and screw machine products",
    level: 4,
  },
  {
    section: "C",
    code: "25.99",
    name: "Manufacture of other fabricated metal products n.e.c.",
    level: 4,
  },
  {
    section: "C",
    code: "26",
    name: "Manufacture of computer, electronic and optical products",
    level: 2,
  },
  {
    section: "C",
    code: "26.1",
    name: "Manufacture of electronic components and boards",
    level: 3,
  },
  {
    section: "C",
    code: "26.11",
    name: "Manufacture of electronic components",
    level: 4,
  },
  {
    section: "C",
    code: "26.12",
    name: "Manufacture of loaded electronic boards",
    level: 4,
  },
  {
    section: "C",
    code: "26.2",
    name: "Manufacture of computers and peripheral equipment",
    level: 3,
  },
  {
    section: "C",
    code: "26.20",
    name: "Manufacture of computers and peripheral equipment",
    level: 4,
  },
  {
    section: "C",
    code: "26.3",
    name: "Manufacture of communication equipment",
    level: 3,
  },
  {
    section: "C",
    code: "26.30",
    name: "Manufacture of communication equipment",
    level: 4,
  },
  {
    section: "C",
    code: "26.4",
    name: "Manufacture of consumer electronics",
    level: 3,
  },
  {
    section: "C",
    code: "26.40",
    name: "Manufacture of consumer electronics",
    level: 4,
  },
  {
    section: "C",
    code: "26.5",
    name: "Manufacture of instruments and appliances for measuring, testing and navigation; watches and clocks",
    level: 3,
  },
  {
    section: "C",
    code: "26.51",
    name: "Manufacture of instruments and appliances for measuring, testing and navigation",
    level: 4,
  },
  {
    section: "C",
    code: "26.52",
    name: "Manufacture of watches and clocks",
    level: 4,
  },
  {
    section: "C",
    code: "26.6",
    name: "Manufacture of irradiation, electromedical and electrotherapeutic equipment",
    level: 3,
  },
  {
    section: "C",
    code: "26.60",
    name: "Manufacture of irradiation, electromedical and electrotherapeutic equipment",
    level: 4,
  },
  {
    section: "C",
    code: "26.7",
    name: "Manufacture of optical instruments and photographic equipment",
    level: 3,
  },
  {
    section: "C",
    code: "26.70",
    name: "Manufacture of optical instruments and photographic equipment",
    level: 4,
  },
  {
    section: "C",
    code: "26.8",
    name: "Manufacture of magnetic and optical media",
    level: 3,
  },
  {
    section: "C",
    code: "26.80",
    name: "Manufacture of magnetic and optical media",
    level: 4,
  },
  {
    section: "C",
    code: "27",
    name: "Manufacture of electrical equipment",
    level: 2,
  },
  {
    section: "C",
    code: "27.1",
    name: "Manufacture of electric motors, generators, transformers and electricity distribution and control apparatus",
    level: 3,
  },
  {
    section: "C",
    code: "27.11",
    name: "Manufacture of electric motors, generators and transformers",
    level: 4,
  },
  {
    section: "C",
    code: "27.12",
    name: "Manufacture of electricity distribution and control apparatus",
    level: 4,
  },
  {
    section: "C",
    code: "27.2",
    name: "Manufacture of batteries and accumulators",
    level: 3,
  },
  {
    section: "C",
    code: "27.20",
    name: "Manufacture of batteries and accumulators",
    level: 4,
  },
  {
    section: "C",
    code: "27.3",
    name: "Manufacture of wiring and wiring devices",
    level: 3,
  },
  {
    section: "C",
    code: "27.31",
    name: "Manufacture of fibre optic cables",
    level: 4,
  },
  {
    section: "C",
    code: "27.32",
    name: "Manufacture of other electronic and electric wires and cables",
    level: 4,
  },
  {
    section: "C",
    code: "27.33",
    name: "Manufacture of wiring devices",
    level: 4,
  },
  {
    section: "C",
    code: "27.4",
    name: "Manufacture of electric lighting equipment",
    level: 3,
  },
  {
    section: "C",
    code: "27.40",
    name: "Manufacture of electric lighting equipment",
    level: 4,
  },
  {
    section: "C",
    code: "27.5",
    name: "Manufacture of domestic appliances",
    level: 3,
  },
  {
    section: "C",
    code: "27.51",
    name: "Manufacture of electric domestic appliances",
    level: 4,
  },
  {
    section: "C",
    code: "27.52",
    name: "Manufacture of non-electric domestic appliances",
    level: 4,
  },
  {
    section: "C",
    code: "27.9",
    name: "Manufacture of other electrical equipment",
    level: 3,
  },
  {
    section: "C",
    code: "27.90",
    name: "Manufacture of other electrical equipment",
    level: 4,
  },
  {
    section: "C",
    code: "28",
    name: "Manufacture of machinery and equipment n.e.c.",
    level: 2,
  },
  {
    section: "C",
    code: "28.1",
    name: "Manufacture of general-purpose machinery",
    level: 3,
  },
  {
    section: "C",
    code: "28.11",
    name: "Manufacture of engines and turbines, except aircraft, vehicle and cycle engines",
    level: 4,
  },
  {
    section: "C",
    code: "28.12",
    name: "Manufacture of fluid power equipment",
    level: 4,
  },
  {
    section: "C",
    code: "28.13",
    name: "Manufacture of other pumps and compressors",
    level: 4,
  },
  {
    section: "C",
    code: "28.14",
    name: "Manufacture of other taps and valves",
    level: 4,
  },
  {
    section: "C",
    code: "28.15",
    name: "Manufacture of bearings, gears, gearing and driving elements",
    level: 4,
  },
  {
    section: "C",
    code: "28.2",
    name: "Manufacture of other general-purpose machinery",
    level: 3,
  },
  {
    section: "C",
    code: "28.21",
    name: "Manufacture of ovens, furnaces and furnace burners",
    level: 4,
  },
  {
    section: "C",
    code: "28.22",
    name: "Manufacture of lifting and handling equipment",
    level: 4,
  },
  {
    section: "C",
    code: "28.23",
    name: "Manufacture of office machinery and equipment (except computers and peripheral equipment)",
    level: 4,
  },
  {
    section: "C",
    code: "28.24",
    name: "Manufacture of power-driven hand tools",
    level: 4,
  },
  {
    section: "C",
    code: "28.25",
    name: "Manufacture of non-domestic cooling and ventilation equipment",
    level: 4,
  },
  {
    section: "C",
    code: "28.29",
    name: "Manufacture of other general-purpose machinery n.e.c.",
    level: 4,
  },
  {
    section: "C",
    code: "28.3",
    name: "Manufacture of agricultural and forestry machinery",
    level: 3,
  },
  {
    section: "C",
    code: "28.30",
    name: "Manufacture of agricultural and forestry machinery",
    level: 4,
  },
  {
    section: "C",
    code: "28.4",
    name: "Manufacture of metal forming machinery and machine tools",
    level: 3,
  },
  {
    section: "C",
    code: "28.41",
    name: "Manufacture of metal forming machinery",
    level: 4,
  },
  {
    section: "C",
    code: "28.49",
    name: "Manufacture of other machine tools",
    level: 4,
  },
  {
    section: "C",
    code: "28.9",
    name: "Manufacture of other special-purpose machinery",
    level: 3,
  },
  {
    section: "C",
    code: "28.91",
    name: "Manufacture of machinery for metallurgy",
    level: 4,
  },
  {
    section: "C",
    code: "28.92",
    name: "Manufacture of machinery for mining, quarrying and construction",
    level: 4,
  },
  {
    section: "C",
    code: "28.93",
    name: "Manufacture of machinery for food, beverage and tobacco processing",
    level: 4,
  },
  {
    section: "C",
    code: "28.94",
    name: "Manufacture of machinery for textile, apparel and leather production",
    level: 4,
  },
  {
    section: "C",
    code: "28.95",
    name: "Manufacture of machinery for paper and paperboard production",
    level: 4,
  },
  {
    section: "C",
    code: "28.96",
    name: "Manufacture of plastics and rubber machinery",
    level: 4,
  },
  {
    section: "C",
    code: "28.99",
    name: "Manufacture of other special-purpose machinery n.e.c.",
    level: 4,
  },
  {
    section: "C",
    code: "29",
    name: "Manufacture of motor vehicles, trailers and semi-trailers",
    level: 2,
  },
  {
    section: "C",
    code: "29.1",
    name: "Manufacture of motor vehicles",
    level: 3,
  },
  {
    section: "C",
    code: "29.10",
    name: "Manufacture of motor vehicles",
    level: 4,
  },
  {
    section: "C",
    code: "29.2",
    name: "Manufacture of bodies (coachwork) for motor vehicles; manufacture of trailers and semi-trailers",
    level: 3,
  },
  {
    section: "C",
    code: "29.20",
    name: "Manufacture of bodies (coachwork) for motor vehicles; manufacture of trailers and semi-trailers",
    level: 4,
  },
  {
    section: "C",
    code: "29.3",
    name: "Manufacture of parts and accessories for motor vehicles",
    level: 3,
  },
  {
    section: "C",
    code: "29.31",
    name: "Manufacture of electrical and electronic equipment for motor vehicles",
    level: 4,
  },
  {
    section: "C",
    code: "29.32",
    name: "Manufacture of other parts and accessories for motor vehicles",
    level: 4,
  },
  {
    section: "C",
    code: "30",
    name: "Manufacture of other transport equipment",
    level: 2,
  },
  {
    section: "C",
    code: "30.1",
    name: "Building of ships and boats",
    level: 3,
  },
  {
    section: "C",
    code: "30.11",
    name: "Building of ships and floating structures",
    level: 4,
  },
  {
    section: "C",
    code: "30.12",
    name: "Building of pleasure and sporting boats",
    level: 4,
  },
  {
    section: "C",
    code: "30.2",
    name: "Manufacture of railway locomotives and rolling stock",
    level: 3,
  },
  {
    section: "C",
    code: "30.20",
    name: "Manufacture of railway locomotives and rolling stock",
    level: 4,
  },
  {
    section: "C",
    code: "30.3",
    name: "Manufacture of air and spacecraft and related machinery",
    level: 3,
  },
  {
    section: "C",
    code: "30.30",
    name: "Manufacture of air and spacecraft and related machinery",
    level: 4,
  },
  {
    section: "C",
    code: "30.4",
    name: "Manufacture of military fighting vehicles",
    level: 3,
  },
  {
    section: "C",
    code: "30.40",
    name: "Manufacture of military fighting vehicles",
    level: 4,
  },
  {
    section: "C",
    code: "30.9",
    name: "Manufacture of transport equipment n.e.c.",
    level: 3,
  },
  {
    section: "C",
    code: "30.91",
    name: "Manufacture of motorcycles",
    level: 4,
  },
  {
    section: "C",
    code: "30.92",
    name: "Manufacture of bicycles and invalid carriages",
    level: 4,
  },
  {
    section: "C",
    code: "30.99",
    name: "Manufacture of other transport equipment n.e.c.",
    level: 4,
  },
  {
    section: "C",
    code: "31",
    name: "Manufacture of furniture",
    level: 2,
  },
  {
    section: "C",
    code: "31.0",
    name: "Manufacture of furniture",
    level: 3,
  },
  {
    section: "C",
    code: "31.01",
    name: "Manufacture of office and shop furniture",
    level: 4,
  },
  {
    section: "C",
    code: "31.02",
    name: "Manufacture of kitchen furniture",
    level: 4,
  },
  {
    section: "C",
    code: "31.03",
    name: "Manufacture of mattresses",
    level: 4,
  },
  {
    section: "C",
    code: "31.09",
    name: "Manufacture of other furniture",
    level: 4,
  },
  {
    section: "C",
    code: "32",
    name: "Other manufacturing",
    level: 2,
  },
  {
    section: "C",
    code: "32.1",
    name: "Manufacture of jewellery, bijouterie and related articles",
    level: 3,
  },
  {
    section: "C",
    code: "32.11",
    name: "Striking of coins",
    level: 4,
  },
  {
    section: "C",
    code: "32.12",
    name: "Manufacture of jewellery and related articles",
    level: 4,
  },
  {
    section: "C",
    code: "32.13",
    name: "Manufacture of imitation jewellery and related articles",
    level: 4,
  },
  {
    section: "C",
    code: "32.2",
    name: "Manufacture of musical instruments",
    level: 3,
  },
  {
    section: "C",
    code: "32.20",
    name: "Manufacture of musical instruments",
    level: 4,
  },
  {
    section: "C",
    code: "32.3",
    name: "Manufacture of sports goods",
    level: 3,
  },
  {
    section: "C",
    code: "32.30",
    name: "Manufacture of sports goods",
    level: 4,
  },
  {
    section: "C",
    code: "32.4",
    name: "Manufacture of games and toys",
    level: 3,
  },
  {
    section: "C",
    code: "32.40",
    name: "Manufacture of games and toys",
    level: 4,
  },
  {
    section: "C",
    code: "32.5",
    name: "Manufacture of medical and dental instruments and supplies",
    level: 3,
  },
  {
    section: "C",
    code: "32.50",
    name: "Manufacture of medical and dental instruments and supplies",
    level: 4,
  },
  {
    section: "C",
    code: "32.9",
    name: "Manufacturing n.e.c.",
    level: 3,
  },
  {
    section: "C",
    code: "32.91",
    name: "Manufacture of brooms and brushes",
    level: 4,
  },
  {
    section: "C",
    code: "32.99",
    name: "Other manufacturing n.e.c.",
    level: 4,
  },
  {
    section: "C",
    code: "33",
    name: "Repair and installation of machinery and equipment",
    level: 2,
  },
  {
    section: "C",
    code: "33.1",
    name: "Repair of fabricated metal products, machinery and equipment",
    level: 3,
  },
  {
    section: "C",
    code: "33.11",
    name: "Repair of fabricated metal products",
    level: 4,
  },
  {
    section: "C",
    code: "33.12",
    name: "Repair of machinery",
    level: 4,
  },
  {
    section: "C",
    code: "33.13",
    name: "Repair of electronic and optical equipment",
    level: 4,
  },
  {
    section: "C",
    code: "33.14",
    name: "Repair of electrical equipment",
    level: 4,
  },
  {
    section: "C",
    code: "33.15",
    name: "Repair and maintenance of ships and boats",
    level: 4,
  },
  {
    section: "C",
    code: "33.16",
    name: "Repair and maintenance of aircraft and spacecraft",
    level: 4,
  },
  {
    section: "C",
    code: "33.17",
    name: "Repair and maintenance of other transport equipment",
    level: 4,
  },
  {
    section: "C",
    code: "33.19",
    name: "Repair of other equipment",
    level: 4,
  },
  {
    section: "C",
    code: "33.2",
    name: "Installation of industrial machinery and equipment",
    level: 3,
  },
  {
    section: "C",
    code: "33.20",
    name: "Installation of industrial machinery and equipment",
    level: 4,
  },
  {
    section: "D",
    code: "",
    name: "ELECTRICITY, GAS, STEAM AND AIR CONDITIONING SUPPLY",
    level: 1,
  },
  {
    section: "D",
    code: "35",
    name: "Electricity, gas, steam and air conditioning supply",
    level: 2,
  },
  {
    section: "D",
    code: "35.1",
    name: "Electric power generation, transmission and distribution",
    level: 3,
  },
  {
    section: "D",
    code: "35.11",
    name: "Production of electricity",
    level: 4,
  },
  {
    section: "D",
    code: "35.12",
    name: "Transmission of electricity",
    level: 4,
  },
  {
    section: "D",
    code: "35.13",
    name: "Distribution of electricity",
    level: 4,
  },
  {
    section: "D",
    code: "35.14",
    name: "Trade of electricity",
    level: 4,
  },
  {
    section: "D",
    code: "35.2",
    name: "Manufacture of gas; distribution of gaseous fuels through mains",
    level: 3,
  },
  {
    section: "D",
    code: "35.21",
    name: "Manufacture of gas",
    level: 4,
  },
  {
    section: "D",
    code: "35.22",
    name: "Distribution of gaseous fuels through mains",
    level: 4,
  },
  {
    section: "D",
    code: "35.23",
    name: "Trade of gas through mains",
    level: 4,
  },
  {
    section: "D",
    code: "35.3",
    name: "Steam and air conditioning supply",
    level: 3,
  },
  {
    section: "D",
    code: "35.30",
    name: "Steam and air conditioning supply",
    level: 4,
  },
  {
    section: "E",
    code: "",
    name: "WATER SUPPLY; SEWERAGE, WASTE MANAGEMENT AND REMEDIATION ACTIVITIES",
    level: 1,
  },
  {
    section: "E",
    code: "36",
    name: "Water collection, treatment and supply",
    level: 2,
  },
  {
    section: "E",
    code: "36.0",
    name: "Water collection, treatment and supply",
    level: 3,
  },
  {
    section: "E",
    code: "36.00",
    name: "Water collection, treatment and supply",
    level: 4,
  },
  {
    section: "E",
    code: "37",
    name: "Sewerage",
    level: 2,
  },
  {
    section: "E",
    code: "37.0",
    name: "Sewerage",
    level: 3,
  },
  {
    section: "E",
    code: "37.00",
    name: "Sewerage",
    level: 4,
  },
  {
    section: "E",
    code: "38",
    name: "Waste collection, treatment and disposal activities; materials recovery",
    level: 2,
  },
  {
    section: "E",
    code: "38.1",
    name: "Waste collection",
    level: 3,
  },
  {
    section: "E",
    code: "38.11",
    name: "Collection of non-hazardous waste",
    level: 4,
  },
  {
    section: "E",
    code: "38.12",
    name: "Collection of hazardous waste",
    level: 4,
  },
  {
    section: "E",
    code: "38.2",
    name: "Waste treatment and disposal",
    level: 3,
  },
  {
    section: "E",
    code: "38.21",
    name: "Treatment and disposal of non-hazardous waste",
    level: 4,
  },
  {
    section: "E",
    code: "38.22",
    name: "Treatment and disposal of hazardous waste",
    level: 4,
  },
  {
    section: "E",
    code: "38.3",
    name: "Materials recovery",
    level: 3,
  },
  {
    section: "E",
    code: "38.31",
    name: "Dismantling of wrecks",
    level: 4,
  },
  {
    section: "E",
    code: "38.32",
    name: "Recovery of sorted materials",
    level: 4,
  },
  {
    section: "E",
    code: "39",
    name: "Remediation activities and other waste management services",
    level: 2,
  },
  {
    section: "E",
    code: "39.0",
    name: "Remediation activities and other waste management services",
    level: 3,
  },
  {
    section: "E",
    code: "39.00",
    name: "Remediation activities and other waste management services",
    level: 4,
  },
  {
    section: "F",
    code: "",
    name: "CONSTRUCTION",
    level: 1,
  },
  {
    section: "F",
    code: "41",
    name: "Construction of buildings",
    level: 2,
  },
  {
    section: "F",
    code: "41.1",
    name: "Development of building projects",
    level: 3,
  },
  {
    section: "F",
    code: "41.10",
    name: "Development of building projects",
    level: 4,
  },
  {
    section: "F",
    code: "41.2",
    name: "Construction of residential and non-residential buildings",
    level: 3,
  },
  {
    section: "F",
    code: "41.20",
    name: "Construction of residential and non-residential buildings",
    level: 4,
  },
  {
    section: "F",
    code: "42",
    name: "Civil engineering",
    level: 2,
  },
  {
    section: "F",
    code: "42.1",
    name: "Construction of roads and railways",
    level: 3,
  },
  {
    section: "F",
    code: "42.11",
    name: "Construction of roads and motorways",
    level: 4,
  },
  {
    section: "F",
    code: "42.12",
    name: "Construction of railways and underground railways",
    level: 4,
  },
  {
    section: "F",
    code: "42.13",
    name: "Construction of bridges and tunnels",
    level: 4,
  },
  {
    section: "F",
    code: "42.2",
    name: "Construction of utility projects",
    level: 3,
  },
  {
    section: "F",
    code: "42.21",
    name: "Construction of utility projects for fluids",
    level: 4,
  },
  {
    section: "F",
    code: "42.22",
    name: "Construction of utility projects for electricity and telecommunications",
    level: 4,
  },
  {
    section: "F",
    code: "42.9",
    name: "Construction of other civil engineering projects",
    level: 3,
  },
  {
    section: "F",
    code: "42.91",
    name: "Construction of water projects",
    level: 4,
  },
  {
    section: "F",
    code: "42.99",
    name: "Construction of other civil engineering projects n.e.c.",
    level: 4,
  },
  {
    section: "F",
    code: "43",
    name: "Specialised construction activities",
    level: 2,
  },
  {
    section: "F",
    code: "43.1",
    name: "Demolition and site preparation",
    level: 3,
  },
  {
    section: "F",
    code: "43.11",
    name: "Demolition",
    level: 4,
  },
  {
    section: "F",
    code: "43.12",
    name: "Site preparation",
    level: 4,
  },
  {
    section: "F",
    code: "43.13",
    name: "Test drilling and boring",
    level: 4,
  },
  {
    section: "F",
    code: "43.2",
    name: "Electrical, plumbing and other construction installation activities",
    level: 3,
  },
  {
    section: "F",
    code: "43.21",
    name: "Electrical installation",
    level: 4,
  },
  {
    section: "F",
    code: "43.22",
    name: "Plumbing, heat and air-conditioning installation",
    level: 4,
  },
  {
    section: "F",
    code: "43.29",
    name: "Other construction installation",
    level: 4,
  },
  {
    section: "F",
    code: "43.3",
    name: "Building completion and finishing",
    level: 3,
  },
  {
    section: "F",
    code: "43.31",
    name: "Plastering",
    level: 4,
  },
  {
    section: "F",
    code: "43.32",
    name: "Joinery installation",
    level: 4,
  },
  {
    section: "F",
    code: "43.33",
    name: "Floor and wall covering",
    level: 4,
  },
  {
    section: "F",
    code: "43.34",
    name: "Painting and glazing",
    level: 4,
  },
  {
    section: "F",
    code: "43.39",
    name: "Other building completion and finishing",
    level: 4,
  },
  {
    section: "F",
    code: "43.9",
    name: "Other specialised construction activities",
    level: 3,
  },
  {
    section: "F",
    code: "43.91",
    name: "Roofing activities",
    level: 4,
  },
  {
    section: "F",
    code: "43.99",
    name: "Other specialised construction activities n.e.c.",
    level: 4,
  },
  {
    section: "G",
    code: "",
    name: "WHOLESALE AND RETAIL TRADE; REPAIR OF MOTOR VEHICLES AND MOTORCYCLES",
    level: 1,
  },
  {
    section: "G",
    code: "45",
    name: "Wholesale and retail trade and repair of motor vehicles and motorcycles",
    level: 2,
  },
  {
    section: "G",
    code: "45.1",
    name: "Sale of motor vehicles",
    level: 3,
  },
  {
    section: "G",
    code: "45.11",
    name: "Sale of cars and light motor vehicles",
    level: 4,
  },
  {
    section: "G",
    code: "45.19",
    name: "Sale of other motor vehicles",
    level: 4,
  },
  {
    section: "G",
    code: "45.2",
    name: "Maintenance and repair of motor vehicles",
    level: 3,
  },
  {
    section: "G",
    code: "45.20",
    name: "Maintenance and repair of motor vehicles",
    level: 4,
  },
  {
    section: "G",
    code: "45.3",
    name: "Sale of motor vehicle parts and accessories",
    level: 3,
  },
  {
    section: "G",
    code: "45.31",
    name: "Wholesale trade of motor vehicle parts and accessories",
    level: 4,
  },
  {
    section: "G",
    code: "45.32",
    name: "Retail trade of motor vehicle parts and accessories",
    level: 4,
  },
  {
    section: "G",
    code: "45.4",
    name: "Sale, maintenance and repair of motorcycles and related parts and accessories",
    level: 3,
  },
  {
    section: "G",
    code: "45.40",
    name: "Sale, maintenance and repair of motorcycles and related parts and accessories",
    level: 4,
  },
  {
    section: "G",
    code: "46",
    name: "Wholesale trade, except of motor vehicles and motorcycles",
    level: 2,
  },
  {
    section: "G",
    code: "46.1",
    name: "Wholesale on a fee or contract basis",
    level: 3,
  },
  {
    section: "G",
    code: "46.11",
    name: "Agents involved in the sale of agricultural raw materials, live animals, textile raw materials and semi-finished goods",
    level: 4,
  },
  {
    section: "G",
    code: "46.12",
    name: "Agents involved in the sale of fuels, ores, metals and industrial chemicals",
    level: 4,
  },
  {
    section: "G",
    code: "46.13",
    name: "Agents involved in the sale of timber and building materials",
    level: 4,
  },
  {
    section: "G",
    code: "46.14",
    name: "Agents involved in the sale of machinery, industrial equipment, ships and aircraft",
    level: 4,
  },
  {
    section: "G",
    code: "46.15",
    name: "Agents involved in the sale of furniture, household goods, hardware and ironmongery",
    level: 4,
  },
  {
    section: "G",
    code: "46.16",
    name: "Agents involved in the sale of textiles, clothing, fur, footwear and leather goods",
    level: 4,
  },
  {
    section: "G",
    code: "46.17",
    name: "Agents involved in the sale of food, beverages and tobacco",
    level: 4,
  },
  {
    section: "G",
    code: "46.18",
    name: "Agents specialised in the sale of other particular products",
    level: 4,
  },
  {
    section: "G",
    code: "46.19",
    name: "Agents involved in the sale of a variety of goods",
    level: 4,
  },
  {
    section: "G",
    code: "46.2",
    name: "Wholesale of agricultural raw materials and live animals",
    level: 3,
  },
  {
    section: "G",
    code: "46.21",
    name: "Wholesale of grain, unmanufactured tobacco, seeds and animal feeds",
    level: 4,
  },
  {
    section: "G",
    code: "46.22",
    name: "Wholesale of flowers and plants",
    level: 4,
  },
  {
    section: "G",
    code: "46.23",
    name: "Wholesale of live animals",
    level: 4,
  },
  {
    section: "G",
    code: "46.24",
    name: "Wholesale of hides, skins and leather",
    level: 4,
  },
  {
    section: "G",
    code: "46.3",
    name: "Wholesale of food, beverages and tobacco",
    level: 3,
  },
  {
    section: "G",
    code: "46.31",
    name: "Wholesale of fruit and vegetables",
    level: 4,
  },
  {
    section: "G",
    code: "46.32",
    name: "Wholesale of meat and meat products",
    level: 4,
  },
  {
    section: "G",
    code: "46.33",
    name: "Wholesale of dairy products, eggs and edible oils and fats",
    level: 4,
  },
  {
    section: "G",
    code: "46.34",
    name: "Wholesale of beverages",
    level: 4,
  },
  {
    section: "G",
    code: "46.35",
    name: "Wholesale of tobacco products",
    level: 4,
  },
  {
    section: "G",
    code: "46.36",
    name: "Wholesale of sugar and chocolate and sugar confectionery",
    level: 4,
  },
  {
    section: "G",
    code: "46.37",
    name: "Wholesale of coffee, tea, cocoa and spices",
    level: 4,
  },
  {
    section: "G",
    code: "46.38",
    name: "Wholesale of other food, including fish, crustaceans and molluscs",
    level: 4,
  },
  {
    section: "G",
    code: "46.39",
    name: "Non-specialised wholesale of food, beverages and tobacco",
    level: 4,
  },
  {
    section: "G",
    code: "46.4",
    name: "Wholesale of household goods",
    level: 3,
  },
  {
    section: "G",
    code: "46.41",
    name: "Wholesale of textiles",
    level: 4,
  },
  {
    section: "G",
    code: "46.42",
    name: "Wholesale of clothing and footwear",
    level: 4,
  },
  {
    section: "G",
    code: "46.43",
    name: "Wholesale of electrical household appliances",
    level: 4,
  },
  {
    section: "G",
    code: "46.44",
    name: "Wholesale of china and glassware and cleaning materials",
    level: 4,
  },
  {
    section: "G",
    code: "46.45",
    name: "Wholesale of perfume and cosmetics",
    level: 4,
  },
  {
    section: "G",
    code: "46.46",
    name: "Wholesale of pharmaceutical goods",
    level: 4,
  },
  {
    section: "G",
    code: "46.47",
    name: "Wholesale of furniture, carpets and lighting equipment",
    level: 4,
  },
  {
    section: "G",
    code: "46.48",
    name: "Wholesale of watches and jewellery",
    level: 4,
  },
  {
    section: "G",
    code: "46.49",
    name: "Wholesale of other household goods",
    level: 4,
  },
  {
    section: "G",
    code: "46.5",
    name: "Wholesale of information and communication equipment",
    level: 3,
  },
  {
    section: "G",
    code: "46.51",
    name: "Wholesale of computers, computer peripheral equipment and software",
    level: 4,
  },
  {
    section: "G",
    code: "46.52",
    name: "Wholesale of electronic and telecommunications equipment and parts",
    level: 4,
  },
  {
    section: "G",
    code: "46.6",
    name: "Wholesale of other machinery, equipment and supplies",
    level: 3,
  },
  {
    section: "G",
    code: "46.61",
    name: "Wholesale of agricultural machinery, equipment and supplies",
    level: 4,
  },
  {
    section: "G",
    code: "46.62",
    name: "Wholesale of machine tools",
    level: 4,
  },
  {
    section: "G",
    code: "46.63",
    name: "Wholesale of mining, construction and civil engineering machinery",
    level: 4,
  },
  {
    section: "G",
    code: "46.64",
    name: "Wholesale of machinery for the textile industry and of sewing and knitting machines",
    level: 4,
  },
  {
    section: "G",
    code: "46.65",
    name: "Wholesale of office furniture",
    level: 4,
  },
  {
    section: "G",
    code: "46.66",
    name: "Wholesale of other office machinery and equipment",
    level: 4,
  },
  {
    section: "G",
    code: "46.69",
    name: "Wholesale of other machinery and equipment",
    level: 4,
  },
  {
    section: "G",
    code: "46.7",
    name: "Other specialised wholesale",
    level: 3,
  },
  {
    section: "G",
    code: "46.71",
    name: "Wholesale of solid, liquid and gaseous fuels and related products",
    level: 4,
  },
  {
    section: "G",
    code: "46.72",
    name: "Wholesale of metals and metal ores",
    level: 4,
  },
  {
    section: "G",
    code: "46.73",
    name: "Wholesale of wood, construction materials and sanitary equipment",
    level: 4,
  },
  {
    section: "G",
    code: "46.74",
    name: "Wholesale of hardware, plumbing and heating equipment and supplies",
    level: 4,
  },
  {
    section: "G",
    code: "46.75",
    name: "Wholesale of chemical products",
    level: 4,
  },
  {
    section: "G",
    code: "46.76",
    name: "Wholesale of other intermediate products",
    level: 4,
  },
  {
    section: "G",
    code: "46.77",
    name: "Wholesale of waste and scrap",
    level: 4,
  },
  {
    section: "G",
    code: "46.9",
    name: "Non-specialised wholesale trade",
    level: 3,
  },
  {
    section: "G",
    code: "46.90",
    name: "Non-specialised wholesale trade",
    level: 4,
  },
  {
    section: "G",
    code: "47",
    name: "Retail trade, except of motor vehicles and motorcycles",
    level: 2,
  },
  {
    section: "G",
    code: "47.1",
    name: "Retail sale in non-specialised stores",
    level: 3,
  },
  {
    section: "G",
    code: "47.11",
    name: "Retail sale in non-specialised stores with food, beverages or tobacco predominating",
    level: 4,
  },
  {
    section: "G",
    code: "47.19",
    name: "Other retail sale in non-specialised stores",
    level: 4,
  },
  {
    section: "G",
    code: "47.2",
    name: "Retail sale of food, beverages and tobacco in specialised stores",
    level: 3,
  },
  {
    section: "G",
    code: "47.21",
    name: "Retail sale of fruit and vegetables in specialised stores",
    level: 4,
  },
  {
    section: "G",
    code: "47.22",
    name: "Retail sale of meat and meat products in specialised stores",
    level: 4,
  },
  {
    section: "G",
    code: "47.23",
    name: "Retail sale of fish, crustaceans and molluscs in specialised stores",
    level: 4,
  },
  {
    section: "G",
    code: "47.24",
    name: "Retail sale of bread, cakes, flour confectionery and sugar confectionery in specialised stores",
    level: 4,
  },
  {
    section: "G",
    code: "47.25",
    name: "Retail sale of beverages in specialised stores",
    level: 4,
  },
  {
    section: "G",
    code: "47.26",
    name: "Retail sale of tobacco products in specialised stores",
    level: 4,
  },
  {
    section: "G",
    code: "47.29",
    name: "Other retail sale of food in specialised stores",
    level: 4,
  },
  {
    section: "G",
    code: "47.3",
    name: "Retail sale of automotive fuel in specialised stores",
    level: 3,
  },
  {
    section: "G",
    code: "47.30",
    name: "Retail sale of automotive fuel in specialised stores",
    level: 4,
  },
  {
    section: "G",
    code: "47.4",
    name: "Retail sale of information and communication equipment in specialised stores",
    level: 3,
  },
  {
    section: "G",
    code: "47.41",
    name: "Retail sale of computers, peripheral units and software in specialised stores",
    level: 4,
  },
  {
    section: "G",
    code: "47.42",
    name: "Retail sale of telecommunications equipment in specialised stores",
    level: 4,
  },
  {
    section: "G",
    code: "47.43",
    name: "Retail sale of audio and video equipment in specialised stores",
    level: 4,
  },
  {
    section: "G",
    code: "47.5",
    name: "Retail sale of other household equipment in specialised stores",
    level: 3,
  },
  {
    section: "G",
    code: "47.51",
    name: "Retail sale of textiles in specialised stores",
    level: 4,
  },
  {
    section: "G",
    code: "47.52",
    name: "Retail sale of hardware, paints and glass in specialised stores",
    level: 4,
  },
  {
    section: "G",
    code: "47.53",
    name: "Retail sale of carpets, rugs, wall and floor coverings in specialised stores",
    level: 4,
  },
  {
    section: "G",
    code: "47.54",
    name: "Retail sale of electrical household appliances in specialised stores",
    level: 4,
  },
  {
    section: "G",
    code: "47.59",
    name: "Retail sale of furniture, lighting equipment and other household articles in specialised stores",
    level: 4,
  },
  {
    section: "G",
    code: "47.6",
    name: "Retail sale of cultural and recreation goods in specialised stores",
    level: 3,
  },
  {
    section: "G",
    code: "47.61",
    name: "Retail sale of books in specialised stores",
    level: 4,
  },
  {
    section: "G",
    code: "47.62",
    name: "Retail sale of newspapers and stationery in specialised stores",
    level: 4,
  },
  {
    section: "G",
    code: "47.63",
    name: "Retail sale of music and video recordings in specialised stores",
    level: 4,
  },
  {
    section: "G",
    code: "47.64",
    name: "Retail sale of sporting equipment in specialised stores",
    level: 4,
  },
  {
    section: "G",
    code: "47.65",
    name: "Retail sale of games and toys in specialised stores",
    level: 4,
  },
  {
    section: "G",
    code: "47.7",
    name: "Retail sale of other goods in specialised stores",
    level: 3,
  },
  {
    section: "G",
    code: "47.71",
    name: "Retail sale of clothing in specialised stores",
    level: 4,
  },
  {
    section: "G",
    code: "47.72",
    name: "Retail sale of footwear and leather goods in specialised stores",
    level: 4,
  },
  {
    section: "G",
    code: "47.73",
    name: "Dispensing chemist in specialised stores",
    level: 4,
  },
  {
    section: "G",
    code: "47.74",
    name: "Retail sale of medical and orthopaedic goods in specialised stores",
    level: 4,
  },
  {
    section: "G",
    code: "47.75",
    name: "Retail sale of cosmetic and toilet articles in specialised stores",
    level: 4,
  },
  {
    section: "G",
    code: "47.76",
    name: "Retail sale of flowers, plants, seeds, fertilisers, pet animals and pet food in specialised stores",
    level: 4,
  },
  {
    section: "G",
    code: "47.77",
    name: "Retail sale of watches and jewellery in specialised stores",
    level: 4,
  },
  {
    section: "G",
    code: "47.78",
    name: "Other retail sale of new goods in specialised stores",
    level: 4,
  },
  {
    section: "G",
    code: "47.79",
    name: "Retail sale of second-hand goods in stores",
    level: 4,
  },
  {
    section: "G",
    code: "47.8",
    name: "Retail sale via stalls and markets",
    level: 3,
  },
  {
    section: "G",
    code: "47.81",
    name: "Retail sale via stalls and markets of food, beverages and tobacco products",
    level: 4,
  },
  {
    section: "G",
    code: "47.82",
    name: "Retail sale via stalls and markets of textiles, clothing and footwear",
    level: 4,
  },
  {
    section: "G",
    code: "47.89",
    name: "Retail sale via stalls and markets of other goods",
    level: 4,
  },
  {
    section: "G",
    code: "47.9",
    name: "Retail trade not in stores, stalls or markets",
    level: 3,
  },
  {
    section: "G",
    code: "47.91",
    name: "Retail sale via mail order houses or via Internet",
    level: 4,
  },
  {
    section: "G",
    code: "47.99",
    name: "Other retail sale not in stores, stalls or markets",
    level: 4,
  },
  {
    section: "H",
    code: "",
    name: "TRANSPORTATION AND STORAGE",
    level: 1,
  },
  {
    section: "H",
    code: "49",
    name: "Land transport and transport via pipelines",
    level: 2,
  },
  {
    section: "H",
    code: "49.1",
    name: "Passenger rail transport, interurban",
    level: 3,
  },
  {
    section: "H",
    code: "49.10",
    name: "Passenger rail transport, interurban",
    level: 4,
  },
  {
    section: "H",
    code: "49.2",
    name: "Freight rail transport",
    level: 3,
  },
  {
    section: "H",
    code: "49.20",
    name: "Freight rail transport",
    level: 4,
  },
  {
    section: "H",
    code: "49.3",
    name: "Other passenger land transport",
    level: 3,
  },
  {
    section: "H",
    code: "49.31",
    name: "Urban and suburban passenger land transport",
    level: 4,
  },
  {
    section: "H",
    code: "49.32",
    name: "Taxi operation",
    level: 4,
  },
  {
    section: "H",
    code: "49.39",
    name: "Other passenger land transport n.e.c.",
    level: 4,
  },
  {
    section: "H",
    code: "49.4",
    name: "Freight transport by road and removal services",
    level: 3,
  },
  {
    section: "H",
    code: "49.41",
    name: "Freight transport by road",
    level: 4,
  },
  {
    section: "H",
    code: "49.42",
    name: "Removal services",
    level: 4,
  },
  {
    section: "H",
    code: "49.5",
    name: "Transport via pipeline",
    level: 3,
  },
  {
    section: "H",
    code: "49.50",
    name: "Transport via pipeline",
    level: 4,
  },
  {
    section: "H",
    code: "50",
    name: "Water transport",
    level: 2,
  },
  {
    section: "H",
    code: "50.1",
    name: "Sea and coastal passenger water transport",
    level: 3,
  },
  {
    section: "H",
    code: "50.10",
    name: "Sea and coastal passenger water transport",
    level: 4,
  },
  {
    section: "H",
    code: "50.2",
    name: "Sea and coastal freight water transport",
    level: 3,
  },
  {
    section: "H",
    code: "50.20",
    name: "Sea and coastal freight water transport",
    level: 4,
  },
  {
    section: "H",
    code: "50.3",
    name: "Inland passenger water transport",
    level: 3,
  },
  {
    section: "H",
    code: "50.30",
    name: "Inland passenger water transport",
    level: 4,
  },
  {
    section: "H",
    code: "50.4",
    name: "Inland freight water transport",
    level: 3,
  },
  {
    section: "H",
    code: "50.40",
    name: "Inland freight water transport",
    level: 4,
  },
  {
    section: "H",
    code: "51",
    name: "Air transport",
    level: 2,
  },
  {
    section: "H",
    code: "51.1",
    name: "Passenger air transport",
    level: 3,
  },
  {
    section: "H",
    code: "51.10",
    name: "Passenger air transport",
    level: 4,
  },
  {
    section: "H",
    code: "51.2",
    name: "Freight air transport and space transport",
    level: 3,
  },
  {
    section: "H",
    code: "51.21",
    name: "Freight air transport",
    level: 4,
  },
  {
    section: "H",
    code: "51.22",
    name: "Space transport",
    level: 4,
  },
  {
    section: "H",
    code: "52",
    name: "Warehousing and support activities for transportation",
    level: 2,
  },
  {
    section: "H",
    code: "52.1",
    name: "Warehousing and storage",
    level: 3,
  },
  {
    section: "H",
    code: "52.10",
    name: "Warehousing and storage",
    level: 4,
  },
  {
    section: "H",
    code: "52.2",
    name: "Support activities for transportation",
    level: 3,
  },
  {
    section: "H",
    code: "52.21",
    name: "Service activities incidental to land transportation",
    level: 4,
  },
  {
    section: "H",
    code: "52.22",
    name: "Service activities incidental to water transportation",
    level: 4,
  },
  {
    section: "H",
    code: "52.23",
    name: "Service activities incidental to air transportation",
    level: 4,
  },
  {
    section: "H",
    code: "52.24",
    name: "Cargo handling",
    level: 4,
  },
  {
    section: "H",
    code: "52.29",
    name: "Other transportation support activities",
    level: 4,
  },
  {
    section: "H",
    code: "53",
    name: "Postal and courier activities",
    level: 2,
  },
  {
    section: "H",
    code: "53.1",
    name: "Postal activities under universal service obligation",
    level: 3,
  },
  {
    section: "H",
    code: "53.10",
    name: "Postal activities under universal service obligation",
    level: 4,
  },
  {
    section: "H",
    code: "53.2",
    name: "Other postal and courier activities",
    level: 3,
  },
  {
    section: "H",
    code: "53.20",
    name: "Other postal and courier activities",
    level: 4,
  },
  {
    section: "I",
    code: "",
    name: "ACCOMMODATION AND FOOD SERVICE ACTIVITIES",
    level: 1,
  },
  {
    section: "I",
    code: "55",
    name: "Accommodation",
    level: 2,
  },
  {
    section: "I",
    code: "55.1",
    name: "Hotels and similar accommodation",
    level: 3,
  },
  {
    section: "I",
    code: "55.10",
    name: "Hotels and similar accommodation",
    level: 4,
  },
  {
    section: "I",
    code: "55.2",
    name: "Holiday and other short-stay accommodation",
    level: 3,
  },
  {
    section: "I",
    code: "55.20",
    name: "Holiday and other short-stay accommodation",
    level: 4,
  },
  {
    section: "I",
    code: "55.3",
    name: "Camping grounds, recreational vehicle parks and trailer parks",
    level: 3,
  },
  {
    section: "I",
    code: "55.30",
    name: "Camping grounds, recreational vehicle parks and trailer parks",
    level: 4,
  },
  {
    section: "I",
    code: "55.9",
    name: "Other accommodation",
    level: 3,
  },
  {
    section: "I",
    code: "55.90",
    name: "Other accommodation",
    level: 4,
  },
  {
    section: "I",
    code: "56",
    name: "Food and beverage service activities",
    level: 2,
  },
  {
    section: "I",
    code: "56.1",
    name: "Restaurants and mobile food service activities",
    level: 3,
  },
  {
    section: "I",
    code: "56.10",
    name: "Restaurants and mobile food service activities",
    level: 4,
  },
  {
    section: "I",
    code: "56.2",
    name: "Event catering and other food service activities",
    level: 3,
  },
  {
    section: "I",
    code: "56.21",
    name: "Event catering activities",
    level: 4,
  },
  {
    section: "I",
    code: "56.29",
    name: "Other food service activities",
    level: 4,
  },
  {
    section: "I",
    code: "56.3",
    name: "Beverage serving activities",
    level: 3,
  },
  {
    section: "I",
    code: "56.30",
    name: "Beverage serving activities",
    level: 4,
  },
  {
    section: "J",
    code: "",
    name: "INFORMATION AND COMMUNICATION",
    level: 1,
  },
  {
    section: "J",
    code: "58",
    name: "Publishing activities",
    level: 2,
  },
  {
    section: "J",
    code: "58.1",
    name: "Publishing of books, periodicals and other publishing activities",
    level: 3,
  },
  {
    section: "J",
    code: "58.11",
    name: "Book publishing",
    level: 4,
  },
  {
    section: "J",
    code: "58.12",
    name: "Publishing of directories and mailing lists",
    level: 4,
  },
  {
    section: "J",
    code: "58.13",
    name: "Publishing of newspapers",
    level: 4,
  },
  {
    section: "J",
    code: "58.14",
    name: "Publishing of journals and periodicals",
    level: 4,
  },
  {
    section: "J",
    code: "58.19",
    name: "Other publishing activities",
    level: 4,
  },
  {
    section: "J",
    code: "58.2",
    name: "Software publishing",
    level: 3,
  },
  {
    section: "J",
    code: "58.21",
    name: "Publishing of computer games",
    level: 4,
  },
  {
    section: "J",
    code: "58.29",
    name: "Other software publishing",
    level: 4,
  },
  {
    section: "J",
    code: "59",
    name: "Motion picture, video and television programme production, sound recording and music publishing activities",
    level: 2,
  },
  {
    section: "J",
    code: "59.1",
    name: "Motion picture, video and television programme activities",
    level: 3,
  },
  {
    section: "J",
    code: "59.11",
    name: "Motion picture, video and television programme production activities",
    level: 4,
  },
  {
    section: "J",
    code: "59.12",
    name: "Motion picture, video and television programme post-production activities",
    level: 4,
  },
  {
    section: "J",
    code: "59.13",
    name: "Motion picture, video and television programme distribution activities",
    level: 4,
  },
  {
    section: "J",
    code: "59.14",
    name: "Motion picture projection activities",
    level: 4,
  },
  {
    section: "J",
    code: "59.2",
    name: "Sound recording and music publishing activities",
    level: 3,
  },
  {
    section: "J",
    code: "59.20",
    name: "Sound recording and music publishing activities",
    level: 4,
  },
  {
    section: "J",
    code: "60",
    name: "Programming and broadcasting activities",
    level: 2,
  },
  {
    section: "J",
    code: "60.1",
    name: "Radio broadcasting",
    level: 3,
  },
  {
    section: "J",
    code: "60.10",
    name: "Radio broadcasting",
    level: 4,
  },
  {
    section: "J",
    code: "60.2",
    name: "Television programming and broadcasting activities",
    level: 3,
  },
  {
    section: "J",
    code: "60.20",
    name: "Television programming and broadcasting activities",
    level: 4,
  },
  {
    section: "J",
    code: "61",
    name: "Telecommunications",
    level: 2,
  },
  {
    section: "J",
    code: "61.1",
    name: "Wired telecommunications activities",
    level: 3,
  },
  {
    section: "J",
    code: "61.10",
    name: "Wired telecommunications activities",
    level: 4,
  },
  {
    section: "J",
    code: "61.2",
    name: "Wireless telecommunications activities",
    level: 3,
  },
  {
    section: "J",
    code: "61.20",
    name: "Wireless telecommunications activities",
    level: 4,
  },
  {
    section: "J",
    code: "61.3",
    name: "Satellite telecommunications activities",
    level: 3,
  },
  {
    section: "J",
    code: "61.30",
    name: "Satellite telecommunications activities",
    level: 4,
  },
  {
    section: "J",
    code: "61.9",
    name: "Other telecommunications activities",
    level: 3,
  },
  {
    section: "J",
    code: "61.90",
    name: "Other telecommunications activities",
    level: 4,
  },
  {
    section: "J",
    code: "62",
    name: "Computer programming, consultancy and related activities",
    level: 2,
  },
  {
    section: "J",
    code: "62.0",
    name: "Computer programming, consultancy and related activities",
    level: 3,
  },
  {
    section: "J",
    code: "62.01",
    name: "Computer programming activities",
    level: 4,
  },
  {
    section: "J",
    code: "62.02",
    name: "Computer consultancy activities",
    level: 4,
  },
  {
    section: "J",
    code: "62.03",
    name: "Computer facilities management activities",
    level: 4,
  },
  {
    section: "J",
    code: "62.09",
    name: "Other information technology and computer service activities",
    level: 4,
  },
  {
    section: "J",
    code: "63",
    name: "Information service activities",
    level: 2,
  },
  {
    section: "J",
    code: "63.1",
    name: "Data processing, hosting and related activities; web portals",
    level: 3,
  },
  {
    section: "J",
    code: "63.11",
    name: "Data processing, hosting and related activities",
    level: 4,
  },
  {
    section: "J",
    code: "63.12",
    name: "Web portals",
    level: 4,
  },
  {
    section: "J",
    code: "63.9",
    name: "Other information service activities",
    level: 3,
  },
  {
    section: "J",
    code: "63.91",
    name: "News agency activities",
    level: 4,
  },
  {
    section: "J",
    code: "63.99",
    name: "Other information service activities n.e.c.",
    level: 4,
  },
  {
    section: "K",
    code: "",
    name: "FINANCIAL AND INSURANCE ACTIVITIES",
    level: 1,
  },
  {
    section: "K",
    code: "64",
    name: "Financial service activities, except insurance and pension funding",
    level: 2,
  },
  {
    section: "K",
    code: "64.1",
    name: "Monetary intermediation",
    level: 3,
  },
  {
    section: "K",
    code: "64.11",
    name: "Central banking",
    level: 4,
  },
  {
    section: "K",
    code: "64.19",
    name: "Other monetary intermediation",
    level: 4,
  },
  {
    section: "K",
    code: "64.2",
    name: "Activities of holding companies",
    level: 3,
  },
  {
    section: "K",
    code: "64.20",
    name: "Activities of holding companies",
    level: 4,
  },
  {
    section: "K",
    code: "64.3",
    name: "Trusts, funds and similar financial entities",
    level: 3,
  },
  {
    section: "K",
    code: "64.30",
    name: "Trusts, funds and similar financial entities",
    level: 4,
  },
  {
    section: "K",
    code: "64.9",
    name: "Other financial service activities, except insurance and pension funding",
    level: 3,
  },
  {
    section: "K",
    code: "64.91",
    name: "Financial leasing",
    level: 4,
  },
  {
    section: "K",
    code: "64.92",
    name: "Other credit granting",
    level: 4,
  },
  {
    section: "K",
    code: "64.99",
    name: "Other financial service activities, except insurance and pension funding n.e.c.",
    level: 4,
  },
  {
    section: "K",
    code: "65",
    name: "Insurance, reinsurance and pension funding, except compulsory social security",
    level: 2,
  },
  {
    section: "K",
    code: "65.1",
    name: "Insurance",
    level: 3,
  },
  {
    section: "K",
    code: "65.11",
    name: "Life insurance",
    level: 4,
  },
  {
    section: "K",
    code: "65.12",
    name: "Non-life insurance",
    level: 4,
  },
  {
    section: "K",
    code: "65.2",
    name: "Reinsurance",
    level: 3,
  },
  {
    section: "K",
    code: "65.20",
    name: "Reinsurance",
    level: 4,
  },
  {
    section: "K",
    code: "65.3",
    name: "Pension funding",
    level: 3,
  },
  {
    section: "K",
    code: "65.30",
    name: "Pension funding",
    level: 4,
  },
  {
    section: "K",
    code: "66",
    name: "Activities auxiliary to financial services and insurance activities",
    level: 2,
  },
  {
    section: "K",
    code: "66.1",
    name: "Activities auxiliary to financial services, except insurance and pension funding",
    level: 3,
  },
  {
    section: "K",
    code: "66.11",
    name: "Administration of financial markets",
    level: 4,
  },
  {
    section: "K",
    code: "66.12",
    name: "Security and commodity contracts brokerage",
    level: 4,
  },
  {
    section: "K",
    code: "66.19",
    name: "Other activities auxiliary to financial services, except insurance and pension funding",
    level: 4,
  },
  {
    section: "K",
    code: "66.2",
    name: "Activities auxiliary to insurance and pension funding",
    level: 3,
  },
  {
    section: "K",
    code: "66.21",
    name: "Risk and damage evaluation",
    level: 4,
  },
  {
    section: "K",
    code: "66.22",
    name: "Activities of insurance agents and brokers",
    level: 4,
  },
  {
    section: "K",
    code: "66.29",
    name: "Other activities auxiliary to insurance and pension funding",
    level: 4,
  },
  {
    section: "K",
    code: "66.3",
    name: "Fund management activities",
    level: 3,
  },
  {
    section: "K",
    code: "66.30",
    name: "Fund management activities",
    level: 4,
  },
  {
    section: "L",
    code: "",
    name: "REAL ESTATE ACTIVITIES",
    level: 1,
  },
  {
    section: "L",
    code: "68",
    name: "Real estate activities",
    level: 2,
  },
  {
    section: "L",
    code: "68.1",
    name: "Buying and selling of own real estate",
    level: 3,
  },
  {
    section: "L",
    code: "68.10",
    name: "Buying and selling of own real estate",
    level: 4,
  },
  {
    section: "L",
    code: "68.2",
    name: "Renting and operating of own or leased real estate",
    level: 3,
  },
  {
    section: "L",
    code: "68.20",
    name: "Renting and operating of own or leased real estate",
    level: 4,
  },
  {
    section: "L",
    code: "68.3",
    name: "Real estate activities on a fee or contract basis",
    level: 3,
  },
  {
    section: "L",
    code: "68.31",
    name: "Real estate agencies",
    level: 4,
  },
  {
    section: "L",
    code: "68.32",
    name: "Management of real estate on a fee or contract basis",
    level: 4,
  },
  {
    section: "M",
    code: "",
    name: "PROFESSIONAL, SCIENTIFIC AND TECHNICAL ACTIVITIES",
    level: 1,
  },
  {
    section: "M",
    code: "69",
    name: "Legal and accounting activities",
    level: 2,
  },
  {
    section: "M",
    code: "69.1",
    name: "Legal activities",
    level: 3,
  },
  {
    section: "M",
    code: "69.10",
    name: "Legal activities",
    level: 4,
  },
  {
    section: "M",
    code: "69.2",
    name: "Accounting, bookkeeping and auditing activities; tax consultancy",
    level: 3,
  },
  {
    section: "M",
    code: "69.20",
    name: "Accounting, bookkeeping and auditing activities; tax consultancy",
    level: 4,
  },
  {
    section: "M",
    code: "70",
    name: "Activities of head offices; management consultancy activities",
    level: 2,
  },
  {
    section: "M",
    code: "70.1",
    name: "Activities of head offices",
    level: 3,
  },
  {
    section: "M",
    code: "70.10",
    name: "Activities of head offices",
    level: 4,
  },
  {
    section: "M",
    code: "70.2",
    name: "Management consultancy activities",
    level: 3,
  },
  {
    section: "M",
    code: "70.21",
    name: "Public relations and communication activities",
    level: 4,
  },
  {
    section: "M",
    code: "70.22",
    name: "Business and other management consultancy activities",
    level: 4,
  },
  {
    section: "M",
    code: "71",
    name: "Architectural and engineering activities; technical testing and analysis",
    level: 2,
  },
  {
    section: "M",
    code: "71.1",
    name: "Architectural and engineering activities and related technical consultancy",
    level: 3,
  },
  {
    section: "M",
    code: "71.11",
    name: "Architectural activities",
    level: 4,
  },
  {
    section: "M",
    code: "71.12",
    name: "Engineering activities and related technical consultancy",
    level: 4,
  },
  {
    section: "M",
    code: "71.2",
    name: "Technical testing and analysis",
    level: 3,
  },
  {
    section: "M",
    code: "71.20",
    name: "Technical testing and analysis",
    level: 4,
  },
  {
    section: "M",
    code: "72",
    name: "Scientific research and development",
    level: 2,
  },
  {
    section: "M",
    code: "72.1",
    name: "Research and experimental development on natural sciences and engineering",
    level: 3,
  },
  {
    section: "M",
    code: "72.11",
    name: "Research and experimental development on biotechnology",
    level: 4,
  },
  {
    section: "M",
    code: "72.19",
    name: "Other research and experimental development on natural sciences and engineering",
    level: 4,
  },
  {
    section: "M",
    code: "72.2",
    name: "Research and experimental development on social sciences and humanities",
    level: 3,
  },
  {
    section: "M",
    code: "72.20",
    name: "Research and experimental development on social sciences and humanities",
    level: 4,
  },
  {
    section: "M",
    code: "73",
    name: "Advertising and market research",
    level: 2,
  },
  {
    section: "M",
    code: "73.1",
    name: "Advertising",
    level: 3,
  },
  {
    section: "M",
    code: "73.11",
    name: "Advertising agencies",
    level: 4,
  },
  {
    section: "M",
    code: "73.12",
    name: "Media representation",
    level: 4,
  },
  {
    section: "M",
    code: "73.2",
    name: "Market research and public opinion polling",
    level: 3,
  },
  {
    section: "M",
    code: "73.20",
    name: "Market research and public opinion polling",
    level: 4,
  },
  {
    section: "M",
    code: "74",
    name: "Other professional, scientific and technical activities",
    level: 2,
  },
  {
    section: "M",
    code: "74.1",
    name: "Specialised design activities",
    level: 3,
  },
  {
    section: "M",
    code: "74.10",
    name: "Specialised design activities",
    level: 4,
  },
  {
    section: "M",
    code: "74.2",
    name: "Photographic activities",
    level: 3,
  },
  {
    section: "M",
    code: "74.20",
    name: "Photographic activities",
    level: 4,
  },
  {
    section: "M",
    code: "74.3",
    name: "Translation and interpretation activities",
    level: 3,
  },
  {
    section: "M",
    code: "74.30",
    name: "Translation and interpretation activities",
    level: 4,
  },
  {
    section: "M",
    code: "74.9",
    name: "Other professional, scientific and technical activities n.e.c.",
    level: 3,
  },
  {
    section: "M",
    code: "74.90",
    name: "Other professional, scientific and technical activities n.e.c.",
    level: 4,
  },
  {
    section: "M",
    code: "75",
    name: "Veterinary activities",
    level: 2,
  },
  {
    section: "M",
    code: "75.0",
    name: "Veterinary activities",
    level: 3,
  },
  {
    section: "M",
    code: "75.00",
    name: "Veterinary activities",
    level: 4,
  },
  {
    section: "N",
    code: "",
    name: "ADMINISTRATIVE AND SUPPORT SERVICE ACTIVITIES",
    level: 1,
  },
  {
    section: "N",
    code: "77",
    name: "Rental and leasing activities",
    level: 2,
  },
  {
    section: "N",
    code: "77.1",
    name: "Renting and leasing of motor vehicles",
    level: 3,
  },
  {
    section: "N",
    code: "77.11",
    name: "Renting and leasing of cars and light motor vehicles",
    level: 4,
  },
  {
    section: "N",
    code: "77.12",
    name: "Renting and leasing of trucks",
    level: 4,
  },
  {
    section: "N",
    code: "77.2",
    name: "Renting and leasing of personal and household goods",
    level: 3,
  },
  {
    section: "N",
    code: "77.21",
    name: "Renting and leasing of recreational and sports goods",
    level: 4,
  },
  {
    section: "N",
    code: "77.22",
    name: "Renting of video tapes and disks",
    level: 4,
  },
  {
    section: "N",
    code: "77.29",
    name: "Renting and leasing of other personal and household goods",
    level: 4,
  },
  {
    section: "N",
    code: "77.3",
    name: "Renting and leasing of other machinery, equipment and tangible goods",
    level: 3,
  },
  {
    section: "N",
    code: "77.31",
    name: "Renting and leasing of agricultural machinery and equipment",
    level: 4,
  },
  {
    section: "N",
    code: "77.32",
    name: "Renting and leasing of construction and civil engineering machinery and equipment",
    level: 4,
  },
  {
    section: "N",
    code: "77.33",
    name: "Renting and leasing of office machinery and equipment (including computers)",
    level: 4,
  },
  {
    section: "N",
    code: "77.34",
    name: "Renting and leasing of water transport equipment",
    level: 4,
  },
  {
    section: "N",
    code: "77.35",
    name: "Renting and leasing of air transport equipment",
    level: 4,
  },
  {
    section: "N",
    code: "77.39",
    name: "Renting and leasing of other machinery, equipment and tangible goods n.e.c.",
    level: 4,
  },
  {
    section: "N",
    code: "77.4",
    name: "Leasing of intellectual property and similar products, except copyrighted works",
    level: 3,
  },
  {
    section: "N",
    code: "77.40",
    name: "Leasing of intellectual property and similar products, except copyrighted works",
    level: 4,
  },
  {
    section: "N",
    code: "78",
    name: "Employment activities",
    level: 2,
  },
  {
    section: "N",
    code: "78.1",
    name: "Activities of employment placement agencies",
    level: 3,
  },
  {
    section: "N",
    code: "78.10",
    name: "Activities of employment placement agencies",
    level: 4,
  },
  {
    section: "N",
    code: "78.2",
    name: "Temporary employment agency activities",
    level: 3,
  },
  {
    section: "N",
    code: "78.20",
    name: "Temporary employment agency activities",
    level: 4,
  },
  {
    section: "N",
    code: "78.3",
    name: "Other human resources provision",
    level: 3,
  },
  {
    section: "N",
    code: "78.30",
    name: "Other human resources provision",
    level: 4,
  },
  {
    section: "N",
    code: "79",
    name: "Travel agency, tour operator and other reservation service and related activities",
    level: 2,
  },
  {
    section: "N",
    code: "79.1",
    name: "Travel agency and tour operator activities",
    level: 3,
  },
  {
    section: "N",
    code: "79.11",
    name: "Travel agency activities",
    level: 4,
  },
  {
    section: "N",
    code: "79.12",
    name: "Tour operator activities",
    level: 4,
  },
  {
    section: "N",
    code: "79.9",
    name: "Other reservation service and related activities",
    level: 3,
  },
  {
    section: "N",
    code: "79.90",
    name: "Other reservation service and related activities",
    level: 4,
  },
  {
    section: "N",
    code: "80",
    name: "Security and investigation activities",
    level: 2,
  },
  {
    section: "N",
    code: "80.1",
    name: "Private security activities",
    level: 3,
  },
  {
    section: "N",
    code: "80.10",
    name: "Private security activities",
    level: 4,
  },
  {
    section: "N",
    code: "80.2",
    name: "Security systems service activities",
    level: 3,
  },
  {
    section: "N",
    code: "80.20",
    name: "Security systems service activities",
    level: 4,
  },
  {
    section: "N",
    code: "80.3",
    name: "Investigation activities",
    level: 3,
  },
  {
    section: "N",
    code: "80.30",
    name: "Investigation activities",
    level: 4,
  },
  {
    section: "N",
    code: "81",
    name: "Services to buildings and landscape activities",
    level: 2,
  },
  {
    section: "N",
    code: "81.1",
    name: "Combined facilities support activities",
    level: 3,
  },
  {
    section: "N",
    code: "81.10",
    name: "Combined facilities support activities",
    level: 4,
  },
  {
    section: "N",
    code: "81.2",
    name: "Cleaning activities",
    level: 3,
  },
  {
    section: "N",
    code: "81.21",
    name: "General cleaning of buildings",
    level: 4,
  },
  {
    section: "N",
    code: "81.22",
    name: "Other building and industrial cleaning activities",
    level: 4,
  },
  {
    section: "N",
    code: "81.29",
    name: "Other cleaning activities",
    level: 4,
  },
  {
    section: "N",
    code: "81.3",
    name: "Landscape service activities",
    level: 3,
  },
  {
    section: "N",
    code: "81.30",
    name: "Landscape service activities",
    level: 4,
  },
  {
    section: "N",
    code: "82",
    name: "Office administrative, office support and other business support activities",
    level: 2,
  },
  {
    section: "N",
    code: "82.1",
    name: "Office administrative and support activities",
    level: 3,
  },
  {
    section: "N",
    code: "82.11",
    name: "Combined office administrative service activities",
    level: 4,
  },
  {
    section: "N",
    code: "82.19",
    name: "Photocopying, document preparation and other specialised office support activities",
    level: 4,
  },
  {
    section: "N",
    code: "82.2",
    name: "Activities of call centres",
    level: 3,
  },
  {
    section: "N",
    code: "82.20",
    name: "Activities of call centres",
    level: 4,
  },
  {
    section: "N",
    code: "82.3",
    name: "Organisation of conventions and trade shows",
    level: 3,
  },
  {
    section: "N",
    code: "82.30",
    name: "Organisation of conventions and trade shows",
    level: 4,
  },
  {
    section: "N",
    code: "82.9",
    name: "Business support service activities n.e.c.",
    level: 3,
  },
  {
    section: "N",
    code: "82.91",
    name: "Activities of collection agencies and credit bureaus",
    level: 4,
  },
  {
    section: "N",
    code: "82.92",
    name: "Packaging activities",
    level: 4,
  },
  {
    section: "N",
    code: "82.99",
    name: "Other business support service activities n.e.c.",
    level: 4,
  },
  {
    section: "O",
    code: "",
    name: "PUBLIC ADMINISTRATION AND DEFENCE; COMPULSORY SOCIAL SECURITY",
    level: 1,
  },
  {
    section: "O",
    code: "84",
    name: "Public administration and defence; compulsory social security",
    level: 2,
  },
  {
    section: "O",
    code: "84.1",
    name: "Administration of the State and the economic and social policy of the community",
    level: 3,
  },
  {
    section: "O",
    code: "84.11",
    name: "General public administration activities",
    level: 4,
  },
  {
    section: "O",
    code: "84.12",
    name: "Regulation of the activities of providing health care, education, cultural services and other social services, excluding social security",
    level: 4,
  },
  {
    section: "O",
    code: "84.13",
    name: "Regulation of and contribution to more efficient operation of businesses",
    level: 4,
  },
  {
    section: "O",
    code: "84.2",
    name: "Provision of services to the community as a whole",
    level: 3,
  },
  {
    section: "O",
    code: "84.21",
    name: "Foreign affairs",
    level: 4,
  },
  {
    section: "O",
    code: "84.22",
    name: "Defence activities",
    level: 4,
  },
  {
    section: "O",
    code: "84.23",
    name: "Justice and judicial activities",
    level: 4,
  },
  {
    section: "O",
    code: "84.24",
    name: "Public order and safety activities",
    level: 4,
  },
  {
    section: "O",
    code: "84.25",
    name: "Fire service activities",
    level: 4,
  },
  {
    section: "O",
    code: "84.3",
    name: "Compulsory social security activities",
    level: 3,
  },
  {
    section: "O",
    code: "84.30",
    name: "Compulsory social security activities",
    level: 4,
  },
  {
    section: "P",
    code: "",
    name: "EDUCATION",
    level: 1,
  },
  {
    section: "P",
    code: "85",
    name: "Education",
    level: 2,
  },
  {
    section: "P",
    code: "85.1",
    name: "Pre-primary education",
    level: 3,
  },
  {
    section: "P",
    code: "85.10",
    name: "Pre-primary education",
    level: 4,
  },
  {
    section: "P",
    code: "85.2",
    name: "Primary education",
    level: 3,
  },
  {
    section: "P",
    code: "85.20",
    name: "Primary education",
    level: 4,
  },
  {
    section: "P",
    code: "85.3",
    name: "Secondary education",
    level: 3,
  },
  {
    section: "P",
    code: "85.31",
    name: "General secondary education",
    level: 4,
  },
  {
    section: "P",
    code: "85.32",
    name: "Technical and vocational secondary education",
    level: 4,
  },
  {
    section: "P",
    code: "85.4",
    name: "Higher education",
    level: 3,
  },
  {
    section: "P",
    code: "85.41",
    name: "Post-secondary non-tertiary education",
    level: 4,
  },
  {
    section: "P",
    code: "85.42",
    name: "Tertiary education",
    level: 4,
  },
  {
    section: "P",
    code: "85.5",
    name: "Other education",
    level: 3,
  },
  {
    section: "P",
    code: "85.51",
    name: "Sports and recreation education",
    level: 4,
  },
  {
    section: "P",
    code: "85.52",
    name: "Cultural education",
    level: 4,
  },
  {
    section: "P",
    code: "85.53",
    name: "Driving school activities",
    level: 4,
  },
  {
    section: "P",
    code: "85.59",
    name: "Other education n.e.c.",
    level: 4,
  },
  {
    section: "P",
    code: "85.6",
    name: "Educational support activities",
    level: 3,
  },
  {
    section: "P",
    code: "85.60",
    name: "Educational support activities",
    level: 4,
  },
  {
    section: "Q",
    code: "",
    name: "HUMAN HEALTH AND SOCIAL WORK ACTIVITIES",
    level: 1,
  },
  {
    section: "Q",
    code: "86",
    name: "Human health activities",
    level: 2,
  },
  {
    section: "Q",
    code: "86.1",
    name: "Hospital activities",
    level: 3,
  },
  {
    section: "Q",
    code: "86.10",
    name: "Hospital activities",
    level: 4,
  },
  {
    section: "Q",
    code: "86.2",
    name: "Medical and dental practice activities",
    level: 3,
  },
  {
    section: "Q",
    code: "86.21",
    name: "General medical practice activities",
    level: 4,
  },
  {
    section: "Q",
    code: "86.22",
    name: "Specialist medical practice activities",
    level: 4,
  },
  {
    section: "Q",
    code: "86.23",
    name: "Dental practice activities",
    level: 4,
  },
  {
    section: "Q",
    code: "86.9",
    name: "Other human health activities",
    level: 3,
  },
  {
    section: "Q",
    code: "86.90",
    name: "Other human health activities",
    level: 4,
  },
  {
    section: "Q",
    code: "87",
    name: "Residential care activities",
    level: 2,
  },
  {
    section: "Q",
    code: "87.1",
    name: "Residential nursing care activities",
    level: 3,
  },
  {
    section: "Q",
    code: "87.10",
    name: "Residential nursing care activities",
    level: 4,
  },
  {
    section: "Q",
    code: "87.2",
    name: "Residential care activities for mental retardation, mental health and substance abuse",
    level: 3,
  },
  {
    section: "Q",
    code: "87.20",
    name: "Residential care activities for mental retardation, mental health and substance abuse",
    level: 4,
  },
  {
    section: "Q",
    code: "87.3",
    name: "Residential care activities for the elderly and disabled",
    level: 3,
  },
  {
    section: "Q",
    code: "87.30",
    name: "Residential care activities for the elderly and disabled",
    level: 4,
  },
  {
    section: "Q",
    code: "87.9",
    name: "Other residential care activities",
    level: 3,
  },
  {
    section: "Q",
    code: "87.90",
    name: "Other residential care activities",
    level: 4,
  },
  {
    section: "Q",
    code: "88",
    name: "Social work activities without accommodation",
    level: 2,
  },
  {
    section: "Q",
    code: "88.1",
    name: "Social work activities without accommodation for the elderly and disabled",
    level: 3,
  },
  {
    section: "Q",
    code: "88.10",
    name: "Social work activities without accommodation for the elderly and disabled",
    level: 4,
  },
  {
    section: "Q",
    code: "88.9",
    name: "Other social work activities without accommodation",
    level: 3,
  },
  {
    section: "Q",
    code: "88.91",
    name: "Child day-care activities",
    level: 4,
  },
  {
    section: "Q",
    code: "88.99",
    name: "Other social work activities without accommodation n.e.c.",
    level: 4,
  },
  {
    section: "R",
    code: "",
    name: "ARTS, ENTERTAINMENT AND RECREATION",
    level: 1,
  },
  {
    section: "R",
    code: "90",
    name: "Creative, arts and entertainment activities",
    level: 2,
  },
  {
    section: "R",
    code: "90.0",
    name: "Creative, arts and entertainment activities",
    level: 3,
  },
  {
    section: "R",
    code: "90.01",
    name: "Performing arts",
    level: 4,
  },
  {
    section: "R",
    code: "90.02",
    name: "Support activities to performing arts",
    level: 4,
  },
  {
    section: "R",
    code: "90.03",
    name: "Artistic creation",
    level: 4,
  },
  {
    section: "R",
    code: "90.04",
    name: "Operation of arts facilities",
    level: 4,
  },
  {
    section: "R",
    code: "91",
    name: "Libraries, archives, museums and other cultural activities",
    level: 2,
  },
  {
    section: "R",
    code: "91.0",
    name: "Libraries, archives, museums and other cultural activities",
    level: 3,
  },
  {
    section: "R",
    code: "91.01",
    name: "Library and archives activities",
    level: 4,
  },
  {
    section: "R",
    code: "91.02",
    name: "Museums activities",
    level: 4,
  },
  {
    section: "R",
    code: "91.03",
    name: "Operation of historical sites and buildings and similar visitor attractions",
    level: 4,
  },
  {
    section: "R",
    code: "91.04",
    name: "Botanical and zoological gardens and nature reserves activities",
    level: 4,
  },
  {
    section: "R",
    code: "92",
    name: "Gambling and betting activities",
    level: 2,
  },
  {
    section: "R",
    code: "92.0",
    name: "Gambling and betting activities",
    level: 3,
  },
  {
    section: "R",
    code: "92.00",
    name: "Gambling and betting activities",
    level: 4,
  },
  {
    section: "R",
    code: "93",
    name: "Sports activities and amusement and recreation activities",
    level: 2,
  },
  {
    section: "R",
    code: "93.1",
    name: "Sports activities",
    level: 3,
  },
  {
    section: "R",
    code: "93.11",
    name: "Operation of sports facilities",
    level: 4,
  },
  {
    section: "R",
    code: "93.12",
    name: "Activities of sports clubs",
    level: 4,
  },
  {
    section: "R",
    code: "93.13",
    name: "Fitness facilities",
    level: 4,
  },
  {
    section: "R",
    code: "93.19",
    name: "Other sports activities",
    level: 4,
  },
  {
    section: "R",
    code: "93.2",
    name: "Amusement and recreation activities",
    level: 3,
  },
  {
    section: "R",
    code: "93.21",
    name: "Activities of amusement parks and theme parks",
    level: 4,
  },
  {
    section: "R",
    code: "93.29",
    name: "Other amusement and recreation activities",
    level: 4,
  },
  {
    section: "S",
    code: "",
    name: "OTHER SERVICE ACTIVITIES",
    level: 1,
  },
  {
    section: "S",
    code: "94",
    name: "Activities of membership organisations",
    level: 2,
  },
  {
    section: "S",
    code: "94.1",
    name: "Activities of business, employers and professional membership organisations",
    level: 3,
  },
  {
    section: "S",
    code: "94.11",
    name: "Activities of business and employers membership organisations",
    level: 4,
  },
  {
    section: "S",
    code: "94.12",
    name: "Activities of professional membership organisations",
    level: 4,
  },
  {
    section: "S",
    code: "94.2",
    name: "Activities of trade unions",
    level: 3,
  },
  {
    section: "S",
    code: "94.20",
    name: "Activities of trade unions",
    level: 4,
  },
  {
    section: "S",
    code: "94.9",
    name: "Activities of other membership organisations",
    level: 3,
  },
  {
    section: "S",
    code: "94.91",
    name: "Activities of religious organisations",
    level: 4,
  },
  {
    section: "S",
    code: "94.92",
    name: "Activities of political organisations",
    level: 4,
  },
  {
    section: "S",
    code: "94.99",
    name: "Activities of other membership organisations n.e.c.",
    level: 4,
  },
  {
    section: "S",
    code: "95",
    name: "Repair of computers and personal and household goods",
    level: 2,
  },
  {
    section: "S",
    code: "95.1",
    name: "Repair of computers and communication equipment",
    level: 3,
  },
  {
    section: "S",
    code: "95.11",
    name: "Repair of computers and peripheral equipment",
    level: 4,
  },
  {
    section: "S",
    code: "95.12",
    name: "Repair of communication equipment",
    level: 4,
  },
  {
    section: "S",
    code: "95.2",
    name: "Repair of personal and household goods",
    level: 3,
  },
  {
    section: "S",
    code: "95.21",
    name: "Repair of consumer electronics",
    level: 4,
  },
  {
    section: "S",
    code: "95.22",
    name: "Repair of household appliances and home and garden equipment",
    level: 4,
  },
  {
    section: "S",
    code: "95.23",
    name: "Repair of footwear and leather goods",
    level: 4,
  },
  {
    section: "S",
    code: "95.24",
    name: "Repair of furniture and home furnishings",
    level: 4,
  },
  {
    section: "S",
    code: "95.25",
    name: "Repair of watches, clocks and jewellery",
    level: 4,
  },
  {
    section: "S",
    code: "95.29",
    name: "Repair of other personal and household goods",
    level: 4,
  },
  {
    section: "S",
    code: "96",
    name: "Other personal service activities",
    level: 2,
  },
  {
    section: "S",
    code: "96.0",
    name: "Other personal service activities",
    level: 3,
  },
  {
    section: "S",
    code: "96.01",
    name: "Washing and (dry-)cleaning of textile and fur products",
    level: 4,
  },
  {
    section: "S",
    code: "96.02",
    name: "Hairdressing and other beauty treatment",
    level: 4,
  },
  {
    section: "S",
    code: "96.03",
    name: "Funeral and related activities",
    level: 4,
  },
  {
    section: "S",
    code: "96.04",
    name: "Physical well-being activities",
    level: 4,
  },
  {
    section: "S",
    code: "96.09",
    name: "Other personal service activities n.e.c.",
    level: 4,
  },
  {
    section: "T",
    code: "",
    name: "ACTIVITIES OF HOUSEHOLDS AS EMPLOYERS; U0NDIFFERENTIATED GOODS- AND SERVICES-PRODUCING ACTIVITIES OF HOUSEHOLDS FOR OWN USE",
    level: 1,
  },
  {
    section: "T",
    code: "97",
    name: "Activities of households as employers of domestic personnel",
    level: 2,
  },
  {
    section: "T",
    code: "97.0",
    name: "Activities of households as employers of domestic personnel",
    level: 3,
  },
  {
    section: "T",
    code: "97.00",
    name: "Activities of households as employers of domestic personnel",
    level: 4,
  },
  {
    section: "T",
    code: "98",
    name: "Undifferentiated goods- and services-producing activities of private households for own use",
    level: 2,
  },
  {
    section: "T",
    code: "98.1",
    name: "Undifferentiated goods-producing activities of private households for own use",
    level: 3,
  },
  {
    section: "T",
    code: "98.10",
    name: "Undifferentiated goods-producing activities of private households for own use",
    level: 4,
  },
  {
    section: "T",
    code: "98.2",
    name: "Undifferentiated service-producing activities of private households for own use",
    level: 3,
  },
  {
    section: "T",
    code: "98.20",
    name: "Undifferentiated service-producing activities of private households for own use",
    level: 4,
  },
  {
    section: "U",
    code: "",
    name: "ACTIVITIES OF EXTRATERRITORIAL ORGANISATIONS AND BODIES",
    level: 1,
  },
  {
    section: "U",
    code: "99",
    name: "Activities of extraterritorial organisations and bodies",
    level: 2,
  },
  {
    section: "U",
    code: "99.0",
    name: "Activities of extraterritorial organisations and bodies",
    level: 3,
  },
  {
    section: "U",
    code: "99.00",
    name: "Activities of extraterritorial organisations and bodies",
    level: 4,
  },
];

export const CURRENCY = [
  {
    symbol: "$",
    name: "US Dollar",
    symbol_native: "$",
    decimal_digits: 2,
    rounding: 0,
    code: "USD",
    name_plural: "US dollars",
  },
  {
    symbol: "CA$",
    name: "Canadian Dollar",
    symbol_native: "$",
    decimal_digits: 2,
    rounding: 0,
    code: "CAD",
    name_plural: "Canadian dollars",
  },
  {
    symbol: "€",
    name: "Euro",
    symbol_native: "€",
    decimal_digits: 2,
    rounding: 0,
    code: "EUR",
    name_plural: "euros",
  },
  {
    symbol: "AED",
    name: "United Arab Emirates Dirham",
    symbol_native: "د.إ.‏",
    decimal_digits: 2,
    rounding: 0,
    code: "AED",
    name_plural: "UAE dirhams",
  },
  {
    symbol: "Af",
    name: "Afghan Afghani",
    symbol_native: "؋",
    decimal_digits: 0,
    rounding: 0,
    code: "AFN",
    name_plural: "Afghan Afghanis",
  },
  {
    symbol: "ALL",
    name: "Albanian Lek",
    symbol_native: "Lek",
    decimal_digits: 0,
    rounding: 0,
    code: "ALL",
    name_plural: "Albanian lekë",
  },
  {
    symbol: "AMD",
    name: "Armenian Dram",
    symbol_native: "դր.",
    decimal_digits: 0,
    rounding: 0,
    code: "AMD",
    name_plural: "Armenian drams",
  },
  {
    symbol: "AR$",
    name: "Argentine Peso",
    symbol_native: "$",
    decimal_digits: 2,
    rounding: 0,
    code: "ARS",
    name_plural: "Argentine pesos",
  },
  {
    symbol: "AU$",
    name: "Australian Dollar",
    symbol_native: "$",
    decimal_digits: 2,
    rounding: 0,
    code: "AUD",
    name_plural: "Australian dollars",
  },
  {
    symbol: "man.",
    name: "Azerbaijani Manat",
    symbol_native: "ман.",
    decimal_digits: 2,
    rounding: 0,
    code: "AZN",
    name_plural: "Azerbaijani manats",
  },
  {
    symbol: "KM",
    name: "Bosnia-Herzegovina Convertible Mark",
    symbol_native: "KM",
    decimal_digits: 2,
    rounding: 0,
    code: "BAM",
    name_plural: "Bosnia-Herzegovina convertible marks",
  },
  {
    symbol: "Tk",
    name: "Bangladeshi Taka",
    symbol_native: "৳",
    decimal_digits: 2,
    rounding: 0,
    code: "BDT",
    name_plural: "Bangladeshi takas",
  },
  {
    symbol: "BGN",
    name: "Bulgarian Lev",
    symbol_native: "лв.",
    decimal_digits: 2,
    rounding: 0,
    code: "BGN",
    name_plural: "Bulgarian leva",
  },
  {
    symbol: "BD",
    name: "Bahraini Dinar",
    symbol_native: "د.ب.‏",
    decimal_digits: 3,
    rounding: 0,
    code: "BHD",
    name_plural: "Bahraini dinars",
  },
  {
    symbol: "FBu",
    name: "Burundian Franc",
    symbol_native: "FBu",
    decimal_digits: 0,
    rounding: 0,
    code: "BIF",
    name_plural: "Burundian francs",
  },
  {
    symbol: "BN$",
    name: "Brunei Dollar",
    symbol_native: "$",
    decimal_digits: 2,
    rounding: 0,
    code: "BND",
    name_plural: "Brunei dollars",
  },
  {
    symbol: "Bs",
    name: "Bolivian Boliviano",
    symbol_native: "Bs",
    decimal_digits: 2,
    rounding: 0,
    code: "BOB",
    name_plural: "Bolivian bolivianos",
  },
  {
    symbol: "R$",
    name: "Brazilian Real",
    symbol_native: "R$",
    decimal_digits: 2,
    rounding: 0,
    code: "BRL",
    name_plural: "Brazilian reals",
  },
  {
    symbol: "BWP",
    name: "Botswanan Pula",
    symbol_native: "P",
    decimal_digits: 2,
    rounding: 0,
    code: "BWP",
    name_plural: "Botswanan pulas",
  },
  {
    symbol: "BYR",
    name: "Belarusian Ruble",
    symbol_native: "BYR",
    decimal_digits: 0,
    rounding: 0,
    code: "BYR",
    name_plural: "Belarusian rubles",
  },
  {
    symbol: "BZ$",
    name: "Belize Dollar",
    symbol_native: "$",
    decimal_digits: 2,
    rounding: 0,
    code: "BZD",
    name_plural: "Belize dollars",
  },
  {
    symbol: "CDF",
    name: "Congolese Franc",
    symbol_native: "FrCD",
    decimal_digits: 2,
    rounding: 0,
    code: "CDF",
    name_plural: "Congolese francs",
  },
  {
    symbol: "CHF",
    name: "Swiss Franc",
    symbol_native: "CHF",
    decimal_digits: 2,
    rounding: 0.05,
    code: "CHF",
    name_plural: "Swiss francs",
  },
  {
    symbol: "CL$",
    name: "Chilean Peso",
    symbol_native: "$",
    decimal_digits: 0,
    rounding: 0,
    code: "CLP",
    name_plural: "Chilean pesos",
  },
  {
    symbol: "CN¥",
    name: "Chinese Yuan",
    symbol_native: "CN¥",
    decimal_digits: 2,
    rounding: 0,
    code: "CNY",
    name_plural: "Chinese yuan",
  },
  {
    symbol: "CO$",
    name: "Colombian Peso",
    symbol_native: "$",
    decimal_digits: 0,
    rounding: 0,
    code: "COP",
    name_plural: "Colombian pesos",
  },
  {
    symbol: "₡",
    name: "Costa Rican Colón",
    symbol_native: "₡",
    decimal_digits: 0,
    rounding: 0,
    code: "CRC",
    name_plural: "Costa Rican colóns",
  },
  {
    symbol: "CV$",
    name: "Cape Verdean Escudo",
    symbol_native: "CV$",
    decimal_digits: 2,
    rounding: 0,
    code: "CVE",
    name_plural: "Cape Verdean escudos",
  },
  {
    symbol: "Kč",
    name: "Czech Republic Koruna",
    symbol_native: "Kč",
    decimal_digits: 2,
    rounding: 0,
    code: "CZK",
    name_plural: "Czech Republic korunas",
  },
  {
    symbol: "Fdj",
    name: "Djiboutian Franc",
    symbol_native: "Fdj",
    decimal_digits: 0,
    rounding: 0,
    code: "DJF",
    name_plural: "Djiboutian francs",
  },
  {
    symbol: "Dkr",
    name: "Danish Krone",
    symbol_native: "kr",
    decimal_digits: 2,
    rounding: 0,
    code: "DKK",
    name_plural: "Danish kroner",
  },
  {
    symbol: "RD$",
    name: "Dominican Peso",
    symbol_native: "RD$",
    decimal_digits: 2,
    rounding: 0,
    code: "DOP",
    name_plural: "Dominican pesos",
  },
  {
    symbol: "DA",
    name: "Algerian Dinar",
    symbol_native: "د.ج.‏",
    decimal_digits: 2,
    rounding: 0,
    code: "DZD",
    name_plural: "Algerian dinars",
  },
  {
    symbol: "Ekr",
    name: "Estonian Kroon",
    symbol_native: "kr",
    decimal_digits: 2,
    rounding: 0,
    code: "EEK",
    name_plural: "Estonian kroons",
  },
  {
    symbol: "EGP",
    name: "Egyptian Pound",
    symbol_native: "ج.م.‏",
    decimal_digits: 2,
    rounding: 0,
    code: "EGP",
    name_plural: "Egyptian pounds",
  },
  {
    symbol: "Nfk",
    name: "Eritrean Nakfa",
    symbol_native: "Nfk",
    decimal_digits: 2,
    rounding: 0,
    code: "ERN",
    name_plural: "Eritrean nakfas",
  },
  {
    symbol: "Br",
    name: "Ethiopian Birr",
    symbol_native: "Br",
    decimal_digits: 2,
    rounding: 0,
    code: "ETB",
    name_plural: "Ethiopian birrs",
  },
  {
    symbol: "£",
    name: "British Pound Sterling",
    symbol_native: "£",
    decimal_digits: 2,
    rounding: 0,
    code: "GBP",
    name_plural: "British pounds sterling",
  },
  {
    symbol: "GEL",
    name: "Georgian Lari",
    symbol_native: "GEL",
    decimal_digits: 2,
    rounding: 0,
    code: "GEL",
    name_plural: "Georgian laris",
  },
  {
    symbol: "GH₵",
    name: "Ghanaian Cedi",
    symbol_native: "GH₵",
    decimal_digits: 2,
    rounding: 0,
    code: "GHS",
    name_plural: "Ghanaian cedis",
  },
  {
    symbol: "FG",
    name: "Guinean Franc",
    symbol_native: "FG",
    decimal_digits: 0,
    rounding: 0,
    code: "GNF",
    name_plural: "Guinean francs",
  },
  {
    symbol: "GTQ",
    name: "Guatemalan Quetzal",
    symbol_native: "Q",
    decimal_digits: 2,
    rounding: 0,
    code: "GTQ",
    name_plural: "Guatemalan quetzals",
  },
  {
    symbol: "HK$",
    name: "Hong Kong Dollar",
    symbol_native: "$",
    decimal_digits: 2,
    rounding: 0,
    code: "HKD",
    name_plural: "Hong Kong dollars",
  },
  {
    symbol: "HNL",
    name: "Honduran Lempira",
    symbol_native: "L",
    decimal_digits: 2,
    rounding: 0,
    code: "HNL",
    name_plural: "Honduran lempiras",
  },
  {
    symbol: "kn",
    name: "Croatian Kuna",
    symbol_native: "kn",
    decimal_digits: 2,
    rounding: 0,
    code: "HRK",
    name_plural: "Croatian kunas",
  },
  {
    symbol: "Ft",
    name: "Hungarian Forint",
    symbol_native: "Ft",
    decimal_digits: 0,
    rounding: 0,
    code: "HUF",
    name_plural: "Hungarian forints",
  },
  {
    symbol: "Rp",
    name: "Indonesian Rupiah",
    symbol_native: "Rp",
    decimal_digits: 0,
    rounding: 0,
    code: "IDR",
    name_plural: "Indonesian rupiahs",
  },
  {
    symbol: "₪",
    name: "Israeli New Sheqel",
    symbol_native: "₪",
    decimal_digits: 2,
    rounding: 0,
    code: "ILS",
    name_plural: "Israeli new sheqels",
  },
  {
    symbol: "Rs",
    name: "Indian Rupee",
    symbol_native: "টকা",
    decimal_digits: 2,
    rounding: 0,
    code: "INR",
    name_plural: "Indian rupees",
  },
  {
    symbol: "IQD",
    name: "Iraqi Dinar",
    symbol_native: "د.ع.‏",
    decimal_digits: 0,
    rounding: 0,
    code: "IQD",
    name_plural: "Iraqi dinars",
  },
  {
    symbol: "IRR",
    name: "Iranian Rial",
    symbol_native: "﷼",
    decimal_digits: 0,
    rounding: 0,
    code: "IRR",
    name_plural: "Iranian rials",
  },
  {
    symbol: "Ikr",
    name: "Icelandic Króna",
    symbol_native: "kr",
    decimal_digits: 0,
    rounding: 0,
    code: "ISK",
    name_plural: "Icelandic krónur",
  },
  {
    symbol: "J$",
    name: "Jamaican Dollar",
    symbol_native: "$",
    decimal_digits: 2,
    rounding: 0,
    code: "JMD",
    name_plural: "Jamaican dollars",
  },
  {
    symbol: "JD",
    name: "Jordanian Dinar",
    symbol_native: "د.أ.‏",
    decimal_digits: 3,
    rounding: 0,
    code: "JOD",
    name_plural: "Jordanian dinars",
  },
  {
    symbol: "¥",
    name: "Japanese Yen",
    symbol_native: "￥",
    decimal_digits: 0,
    rounding: 0,
    code: "JPY",
    name_plural: "Japanese yen",
  },
  {
    symbol: "Ksh",
    name: "Kenyan Shilling",
    symbol_native: "Ksh",
    decimal_digits: 2,
    rounding: 0,
    code: "KES",
    name_plural: "Kenyan shillings",
  },
  {
    symbol: "KHR",
    name: "Cambodian Riel",
    symbol_native: "៛",
    decimal_digits: 2,
    rounding: 0,
    code: "KHR",
    name_plural: "Cambodian riels",
  },
  {
    symbol: "CF",
    name: "Comorian Franc",
    symbol_native: "FC",
    decimal_digits: 0,
    rounding: 0,
    code: "KMF",
    name_plural: "Comorian francs",
  },
  {
    symbol: "₩",
    name: "South Korean Won",
    symbol_native: "₩",
    decimal_digits: 0,
    rounding: 0,
    code: "KRW",
    name_plural: "South Korean won",
  },
  {
    symbol: "KD",
    name: "Kuwaiti Dinar",
    symbol_native: "د.ك.‏",
    decimal_digits: 3,
    rounding: 0,
    code: "KWD",
    name_plural: "Kuwaiti dinars",
  },
  {
    symbol: "KZT",
    name: "Kazakhstani Tenge",
    symbol_native: "тңг.",
    decimal_digits: 2,
    rounding: 0,
    code: "KZT",
    name_plural: "Kazakhstani tenges",
  },
  {
    symbol: "LB£",
    name: "Lebanese Pound",
    symbol_native: "ل.ل.‏",
    decimal_digits: 0,
    rounding: 0,
    code: "LBP",
    name_plural: "Lebanese pounds",
  },
  {
    symbol: "SLRs",
    name: "Sri Lankan Rupee",
    symbol_native: "SL Re",
    decimal_digits: 2,
    rounding: 0,
    code: "LKR",
    name_plural: "Sri Lankan rupees",
  },
  {
    symbol: "Lt",
    name: "Lithuanian Litas",
    symbol_native: "Lt",
    decimal_digits: 2,
    rounding: 0,
    code: "LTL",
    name_plural: "Lithuanian litai",
  },
  {
    symbol: "Ls",
    name: "Latvian Lats",
    symbol_native: "Ls",
    decimal_digits: 2,
    rounding: 0,
    code: "LVL",
    name_plural: "Latvian lati",
  },
  {
    symbol: "LD",
    name: "Libyan Dinar",
    symbol_native: "د.ل.‏",
    decimal_digits: 3,
    rounding: 0,
    code: "LYD",
    name_plural: "Libyan dinars",
  },
  {
    symbol: "MAD",
    name: "Moroccan Dirham",
    symbol_native: "د.م.‏",
    decimal_digits: 2,
    rounding: 0,
    code: "MAD",
    name_plural: "Moroccan dirhams",
  },
  {
    symbol: "MDL",
    name: "Moldovan Leu",
    symbol_native: "MDL",
    decimal_digits: 2,
    rounding: 0,
    code: "MDL",
    name_plural: "Moldovan lei",
  },
  {
    symbol: "MGA",
    name: "Malagasy Ariary",
    symbol_native: "MGA",
    decimal_digits: 0,
    rounding: 0,
    code: "MGA",
    name_plural: "Malagasy Ariaries",
  },
  {
    symbol: "MKD",
    name: "Macedonian Denar",
    symbol_native: "MKD",
    decimal_digits: 2,
    rounding: 0,
    code: "MKD",
    name_plural: "Macedonian denari",
  },
  {
    symbol: "MMK",
    name: "Myanma Kyat",
    symbol_native: "K",
    decimal_digits: 0,
    rounding: 0,
    code: "MMK",
    name_plural: "Myanma kyats",
  },
  {
    symbol: "MOP$",
    name: "Macanese Pataca",
    symbol_native: "MOP$",
    decimal_digits: 2,
    rounding: 0,
    code: "MOP",
    name_plural: "Macanese patacas",
  },
  {
    symbol: "MURs",
    name: "Mauritian Rupee",
    symbol_native: "MURs",
    decimal_digits: 0,
    rounding: 0,
    code: "MUR",
    name_plural: "Mauritian rupees",
  },
  {
    symbol: "MX$",
    name: "Mexican Peso",
    symbol_native: "$",
    decimal_digits: 2,
    rounding: 0,
    code: "MXN",
    name_plural: "Mexican pesos",
  },
  {
    symbol: "RM",
    name: "Malaysian Ringgit",
    symbol_native: "RM",
    decimal_digits: 2,
    rounding: 0,
    code: "MYR",
    name_plural: "Malaysian ringgits",
  },
  {
    symbol: "MTn",
    name: "Mozambican Metical",
    symbol_native: "MTn",
    decimal_digits: 2,
    rounding: 0,
    code: "MZN",
    name_plural: "Mozambican meticals",
  },
  {
    symbol: "N$",
    name: "Namibian Dollar",
    symbol_native: "N$",
    decimal_digits: 2,
    rounding: 0,
    code: "NAD",
    name_plural: "Namibian dollars",
  },
  {
    symbol: "₦",
    name: "Nigerian Naira",
    symbol_native: "₦",
    decimal_digits: 2,
    rounding: 0,
    code: "NGN",
    name_plural: "Nigerian nairas",
  },
  {
    symbol: "C$",
    name: "Nicaraguan Córdoba",
    symbol_native: "C$",
    decimal_digits: 2,
    rounding: 0,
    code: "NIO",
    name_plural: "Nicaraguan córdobas",
  },
  {
    symbol: "Nkr",
    name: "Norwegian Krone",
    symbol_native: "kr",
    decimal_digits: 2,
    rounding: 0,
    code: "NOK",
    name_plural: "Norwegian kroner",
  },
  {
    symbol: "NPRs",
    name: "Nepalese Rupee",
    symbol_native: "नेरू",
    decimal_digits: 2,
    rounding: 0,
    code: "NPR",
    name_plural: "Nepalese rupees",
  },
  {
    symbol: "NZ$",
    name: "New Zealand Dollar",
    symbol_native: "$",
    decimal_digits: 2,
    rounding: 0,
    code: "NZD",
    name_plural: "New Zealand dollars",
  },
  {
    symbol: "OMR",
    name: "Omani Rial",
    symbol_native: "ر.ع.‏",
    decimal_digits: 3,
    rounding: 0,
    code: "OMR",
    name_plural: "Omani rials",
  },
  {
    symbol: "B/.",
    name: "Panamanian Balboa",
    symbol_native: "B/.",
    decimal_digits: 2,
    rounding: 0,
    code: "PAB",
    name_plural: "Panamanian balboas",
  },
  {
    symbol: "S/.",
    name: "Peruvian Nuevo Sol",
    symbol_native: "S/.",
    decimal_digits: 2,
    rounding: 0,
    code: "PEN",
    name_plural: "Peruvian nuevos soles",
  },
  {
    symbol: "₱",
    name: "Philippine Peso",
    symbol_native: "₱",
    decimal_digits: 2,
    rounding: 0,
    code: "PHP",
    name_plural: "Philippine pesos",
  },
  {
    symbol: "PKRs",
    name: "Pakistani Rupee",
    symbol_native: "₨",
    decimal_digits: 0,
    rounding: 0,
    code: "PKR",
    name_plural: "Pakistani rupees",
  },
  {
    symbol: "zł",
    name: "Polish Zloty",
    symbol_native: "zł",
    decimal_digits: 2,
    rounding: 0,
    code: "PLN",
    name_plural: "Polish zlotys",
  },
  {
    symbol: "₲",
    name: "Paraguayan Guarani",
    symbol_native: "₲",
    decimal_digits: 0,
    rounding: 0,
    code: "PYG",
    name_plural: "Paraguayan guaranis",
  },
  {
    symbol: "QR",
    name: "Qatari Rial",
    symbol_native: "ر.ق.‏",
    decimal_digits: 2,
    rounding: 0,
    code: "QAR",
    name_plural: "Qatari rials",
  },
  {
    symbol: "RON",
    name: "Romanian Leu",
    symbol_native: "RON",
    decimal_digits: 2,
    rounding: 0,
    code: "RON",
    name_plural: "Romanian lei",
  },
  {
    symbol: "din.",
    name: "Serbian Dinar",
    symbol_native: "дин.",
    decimal_digits: 0,
    rounding: 0,
    code: "RSD",
    name_plural: "Serbian dinars",
  },
  {
    symbol: "RUB",
    name: "Russian Ruble",
    symbol_native: "руб.",
    decimal_digits: 2,
    rounding: 0,
    code: "RUB",
    name_plural: "Russian rubles",
  },
  {
    symbol: "RWF",
    name: "Rwandan Franc",
    symbol_native: "FR",
    decimal_digits: 0,
    rounding: 0,
    code: "RWF",
    name_plural: "Rwandan francs",
  },
  {
    symbol: "SR",
    name: "Saudi Riyal",
    symbol_native: "ر.س.‏",
    decimal_digits: 2,
    rounding: 0,
    code: "SAR",
    name_plural: "Saudi riyals",
  },
  {
    symbol: "SDG",
    name: "Sudanese Pound",
    symbol_native: "SDG",
    decimal_digits: 2,
    rounding: 0,
    code: "SDG",
    name_plural: "Sudanese pounds",
  },
  {
    symbol: "Skr",
    name: "Swedish Krona",
    symbol_native: "kr",
    decimal_digits: 2,
    rounding: 0,
    code: "SEK",
    name_plural: "Swedish kronor",
  },
  {
    symbol: "S$",
    name: "Singapore Dollar",
    symbol_native: "$",
    decimal_digits: 2,
    rounding: 0,
    code: "SGD",
    name_plural: "Singapore dollars",
  },
  {
    symbol: "Ssh",
    name: "Somali Shilling",
    symbol_native: "Ssh",
    decimal_digits: 0,
    rounding: 0,
    code: "SOS",
    name_plural: "Somali shillings",
  },
  {
    symbol: "SY£",
    name: "Syrian Pound",
    symbol_native: "ل.س.‏",
    decimal_digits: 0,
    rounding: 0,
    code: "SYP",
    name_plural: "Syrian pounds",
  },
  {
    symbol: "฿",
    name: "Thai Baht",
    symbol_native: "฿",
    decimal_digits: 2,
    rounding: 0,
    code: "THB",
    name_plural: "Thai baht",
  },
  {
    symbol: "DT",
    name: "Tunisian Dinar",
    symbol_native: "د.ت.‏",
    decimal_digits: 3,
    rounding: 0,
    code: "TND",
    name_plural: "Tunisian dinars",
  },
  {
    symbol: "T$",
    name: "Tongan Paʻanga",
    symbol_native: "T$",
    decimal_digits: 2,
    rounding: 0,
    code: "TOP",
    name_plural: "Tongan paʻanga",
  },
  {
    symbol: "TL",
    name: "Turkish Lira",
    symbol_native: "TL",
    decimal_digits: 2,
    rounding: 0,
    code: "TRY",
    name_plural: "Turkish Lira",
  },
  {
    symbol: "TT$",
    name: "Trinidad and Tobago Dollar",
    symbol_native: "$",
    decimal_digits: 2,
    rounding: 0,
    code: "TTD",
    name_plural: "Trinidad and Tobago dollars",
  },
  {
    symbol: "NT$",
    name: "New Taiwan Dollar",
    symbol_native: "NT$",
    decimal_digits: 2,
    rounding: 0,
    code: "TWD",
    name_plural: "New Taiwan dollars",
  },
  {
    symbol: "TSh",
    name: "Tanzanian Shilling",
    symbol_native: "TSh",
    decimal_digits: 0,
    rounding: 0,
    code: "TZS",
    name_plural: "Tanzanian shillings",
  },
  {
    symbol: "₴",
    name: "Ukrainian Hryvnia",
    symbol_native: "₴",
    decimal_digits: 2,
    rounding: 0,
    code: "UAH",
    name_plural: "Ukrainian hryvnias",
  },
  {
    symbol: "USh",
    name: "Ugandan Shilling",
    symbol_native: "USh",
    decimal_digits: 0,
    rounding: 0,
    code: "UGX",
    name_plural: "Ugandan shillings",
  },
  {
    symbol: "$U",
    name: "Uruguayan Peso",
    symbol_native: "$",
    decimal_digits: 2,
    rounding: 0,
    code: "UYU",
    name_plural: "Uruguayan pesos",
  },
  {
    symbol: "UZS",
    name: "Uzbekistan Som",
    symbol_native: "UZS",
    decimal_digits: 0,
    rounding: 0,
    code: "UZS",
    name_plural: "Uzbekistan som",
  },
  {
    symbol: "Bs.F.",
    name: "Venezuelan Bolívar",
    symbol_native: "Bs.F.",
    decimal_digits: 2,
    rounding: 0,
    code: "VEF",
    name_plural: "Venezuelan bolívars",
  },
  {
    symbol: "₫",
    name: "Vietnamese Dong",
    symbol_native: "₫",
    decimal_digits: 0,
    rounding: 0,
    code: "VND",
    name_plural: "Vietnamese dong",
  },
  {
    symbol: "FCFA",
    name: "CFA Franc BEAC",
    symbol_native: "FCFA",
    decimal_digits: 0,
    rounding: 0,
    code: "XAF",
    name_plural: "CFA francs BEAC",
  },
  {
    symbol: "CFA",
    name: "CFA Franc BCEAO",
    symbol_native: "CFA",
    decimal_digits: 0,
    rounding: 0,
    code: "XOF",
    name_plural: "CFA francs BCEAO",
  },
  {
    symbol: "YR",
    name: "Yemeni Rial",
    symbol_native: "ر.ي.‏",
    decimal_digits: 0,
    rounding: 0,
    code: "YER",
    name_plural: "Yemeni rials",
  },
  {
    symbol: "R",
    name: "South African Rand",
    symbol_native: "R",
    decimal_digits: 2,
    rounding: 0,
    code: "ZAR",
    name_plural: "South African rand",
  },
  {
    symbol: "ZK",
    name: "Zambian Kwacha",
    symbol_native: "ZK",
    decimal_digits: 0,
    rounding: 0,
    code: "ZMK",
    name_plural: "Zambian kwachas",
  },
];
