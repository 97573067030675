import React, { useEffect, useState } from "react";
import { Table, TableHeader, TableBody, TableRow, TableHead, TableCell, TableCaption } from "@/components/ui/table";
import api from "@/services/api";
import toast from "react-hot-toast";
import { Check, Cross, X } from "lucide-react";
import { Dialog, DialogContent, DialogFooter, DialogHeader, DialogTitle, DialogTrigger } from "@/components/ui/dialog";
import { Button } from "@/components/ui/button";
import { Label } from "@/components/ui/label";
import { Input } from "@/components/ui/input";
import useStore from "@/store";
import { MdDeleteForever } from "react-icons/md";

export default ({ questionnaire }) => {
  const [users, setUsers] = useState([]);
  // const [selectedDocument, setSelectedDocument] = useState(null);

  useEffect(() => {
    fetchUsers();
  }, [questionnaire]);

  const fetchUsers = async () => {
    try {
      const { data } = await api.post("/user/search", { users: questionnaire.users });
      console.log(`data`, data);
      setUsers(data);
    } catch (error) {
      console.error("Error fetching documents:", error);
    }
  };

  const resendInvitation = async (user) => {
    if (!confirm("Are you sure?")) return;
    const { ok } = await api.post(`/user/resend_invitation/${user._id}`, { questionnaire_id: questionnaire._id, questionnaire_name: questionnaire.name });
    if (!ok) return toast.error("Something went wrong");
    toast.success("Invitation sent successfully");
  };

  const handleDelete = async (id) => {
    if (!confirm("Are you sure?")) return;
    const { data, ok } = await api.remove(`/user/${id}`);
    if (!ok) return toast.error("Something went wrong");
    setUsers(users.filter((u) => u._id !== id));
    toast.success("User deleted successfully");
  };

  const columns = ["Name", "Email", "Last login", "Registered at", "Created at", "Signup status", ""];

  return (
    <section>
      <div className="flex items-center justify-end mb-4">
        <Adduser onDone={fetchUsers} questionnaire={questionnaire} />
      </div>
      <div className="border rounded-xl overflow-hidden">
        <Table>
          <TableHeader style={{ fontWeight: "bold", borderBottom: "1px solid #ddd", backgroundColor: "transparent" }}>
            <TableRow>
              {columns.map((column, index) => (
                <TableHead key={index}>{column}</TableHead>
              ))}
            </TableRow>
          </TableHeader>
          <TableBody>
            {users.map((user) => (
              <TableRow
                key={user._id}
                // onClick={() => {
                //   toast.success("todo");
                // }}
              >
                <TableCell>
                  <div className="flex items-center space-x-4">
                    <img src={user.avatar} className="rounded-full w-8 h-8" />
                    <span>{user.name}</span>
                  </div>
                </TableCell>
                <TableCell>{user.email}</TableCell>
                <TableCell>{(user.last_login_at || "").slice(0, 10)}</TableCell>
                <TableCell>{(user.registered_at || "").slice(0, 10)}</TableCell>
                <TableCell>{(user.created_at || "").slice(0, 10)}</TableCell>
                <TableCell className="text-right">
                  {!user.registered_at ? (
                    <div className="flex items-center space-x-1">
                      <X className="text-red-700" />
                      <div className="text-blue-700 underline cursor-pointer" onClick={() => resendInvitation(user)}>
                        Resend invitation
                      </div>
                    </div>
                  ) : (
                    <Check className="text-green-700" />
                  )}
                </TableCell>
                <TableCell>
                  <MdDeleteForever onClick={() => handleDelete(user._id)} className="rounded-full hover:bg-slate-300 cursor-pointer" />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </div>
    </section>
  );
};

const Adduser = ({ questionnaire, onDone }) => {
  const [values, setValues] = useState({});
  const [open, setOpen] = useState(false);
  const [tab, setTab] = useState("email");
  const [company, setCompany] = useState({});

  useEffect(() => {
    async function fetchData() {
      try {
        const { data } = await api.get(`/company/${questionnaire.company_id}`);
        setCompany(data);
      } catch (e) {
        console.log(e);
      }
    }
    fetchData();
  }, []);

  const { fund } = useStore();

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      values.fund_id = fund._id;
      values.fund_name = fund.name;
      values.questionnaire_name = questionnaire.name;
      values.questionnaire_id = questionnaire._id;
      values.role = "user";
      values.companies = [{ id: company._id, name: company.name }];
      const { data } = await api.post("/user/invite", values);

      const users = [...questionnaire.users, data._id];
      const { data: d } = await api.put(`/questionnaire/${questionnaire._id}`, { users });
      questionnaire.users = d.users;

      toast.success("user created successfully");
      setValues({});
      setOpen(false);
      onDone();
    } catch (e) {
      console.log(e);
      toast.error(e?.code || "ERROR");
    }
  };

  const toggleInvitation = async () => {
    const { data, ok } = await api.post(`/questionnaire/toggle_invitation_link/${questionnaire._id}`);
    if (!ok) return toast.error("Error");
    // setQuestionnaire(data);
  };

  function renderContent() {
    if (tab === "email") {
      return (
        <form onSubmit={handleSubmit}>
          <div className="space-y-4">
            <fieldset className="space-y-2">
              <Label>Name</Label>
              <Input placeholder="Jhon dee" required value={values.name} onChange={(e) => setValues({ ...values, name: e.target.value })} />
            </fieldset>
            <fieldset className="space-y-2">
              <Label>Email</Label>
              <Input placeholder="jhon@doe.com" required value={values.email} onChange={(e) => setValues({ ...values, email: e.target.value })} />
            </fieldset>
          </div>
          <DialogFooter className="mt-3">
            <Button type="submit" className="bg-accent hover:bg-[#3760fa]">
              Send invite
            </Button>
          </DialogFooter>
        </form>
      );
    }
    if (tab === "link") {
      const link = `${window.location.host}/auth/signup_user?questionnaire_token=${questionnaire.invitation_token}`;
      return (
        // <div className="space-y-4">
        //   <fieldset className="space-y-2">
        //     <Label>Invitation link</Label>
        //     <Input disabled value={link} />
        //   </fieldset>
        //   <DialogFooter className="mt-3">
        //     <Button
        //       onClick={() => {
        //         navigator.clipboard.writeText(link);
        //         toast.success("Link copied to clipboard");
        //       }}
        //       className="bg-accent hover:bg-[#3760fa]"
        //     >
        //       Copy link
        //     </Button>
        //   </DialogFooter>
        // </div>
        <div className="space-y-4">
          {questionnaire.invitation_token ? (
            <div>
              <textarea className="bg-gray-100 border border-gray-300 rounded p-2 w-full mb-3" readOnly value={link} />
              <div className="flex gap-10">
                {/* <button
                  onClick={() => {
                    navigator.clipboard.writeText(link);
                    toast.success("Link copied to clipboard");
                  }}
                  className="bg-accent hover:bg-[#3760fa]"
                >
                  Copy link
                </button> */}
                <Button
                  onClick={() => {
                    navigator.clipboard.writeText(link);
                    toast.success("Link copied to clipboard");
                  }}
                  className="bg-accent hover:bg-[#3760fa]"
                >
                  Copy link
                </Button>
                {/* <button onClick={toggleInvitation} className="bg-red-500 py-3 px-6 mt-4 text-sm rounded-md text-white">
                  Delete link
                </button> */}
                <Button onClick={toggleInvitation} className="bg-red-500">
                  Delete link
                </Button>
              </div>
            </div>
          ) : (
            <Button onClick={toggleInvitation} className="bg-accent hover:bg-[#3760fa]">
              Generate link
            </Button>
          )}
        </div>
      );
    }
  }

  return (
    <Dialog open={open} onOpenChange={() => setOpen(!open)}>
      <DialogTrigger asChild>
        <Button variant="blue">Invite</Button>
      </DialogTrigger>
      <DialogContent className="max-w-lg">
        <DialogHeader>
          <DialogTitle className="mb-5">Invite participant</DialogTitle>
        </DialogHeader>
        <ul className="flex items-center gap-x-5 border-b border-gray-200">
          <Tab text="Invite with email" onClick={() => setTab("email")} selected={tab === "email"} />
          {/* <Tab text="Invite with link" onClick={() => setTab("link")} selected={tab === "link"} /> */}
        </ul>

        {renderContent()}
      </DialogContent>
    </Dialog>
  );
};

const Tab = ({ text, selected, onClick }) => {
  return (
    <li>
      <div
        onClick={onClick}
        className={`cursor-pointer text-sm font-medium pb-3 border-b-2 transition-colors hover:border-purple-2 hover:text-purple-2 capitalize ${
          selected ? "border-purple-2 text-purple-2" : "border-transparent text-muted-foreground"
        }`}
      >
        {text}
      </div>
    </li>
  );
};
