import { useEffect, useState } from "react";
import { Loader2 } from "lucide-react";
import { Chart as ChartJS, ArcElement, Tooltip, Legend, CategoryScale, LinearScale, BarElement, Title } from "chart.js";
import ChartDataLabels from "chartjs-plugin-datalabels";
import html2canvas from "html2canvas";

import { Button } from "@/components/ui/button";
import Loader from "@/components/loader";
import API from "@/services/api";
import Dimension from "./Dimension";
import GeneralInformation from "./GeneralInformation";

ChartJS.register(ArcElement, Tooltip, Legend, CategoryScale, LinearScale, BarElement, Title, ChartDataLabels);

export default ({ match }) => {
  const [questionnaire, setQuestionnaire] = useState();
  const [indicators, setIndicators] = useState();
  const [company, setCompany] = useState();
  const [loading, setLoading] = useState(false);
  const [indicatorDimensions, setIndicatorDimensions] = useState([]);

  useEffect(() => {
    fetch();
  }, [match.params.id]);

  async function fetch() {
    const res1 = await API.get(`/questionnaire/${match.params.id}`);
    const res2 = await API.post(`/campaign_indicator/search`, { questionnaire_id: match.params.id });
    const res3 = await API.get(`/company/${res1.data.company_id}`);
    setQuestionnaire(res1.data);
    setIndicators(res2.data);
    setCompany(res3.data);
    const indicatorDimensions = Array.from(new Set(res2.data.map((indicator) => indicator.dimension)));
    const filteredDimensions = indicatorDimensions.filter((dimension) => dimension !== "General information");
    setIndicatorDimensions(filteredDimensions);
  }

  async function handleDownload() {
    setLoading(true);
    try {
      try {
        const response = await API.pdf(`/questionnaire/${match.params.id}/pdf`);
        const blob = new Blob([response], { type: "application/pdf" });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        a.download = "Matter.pdf"; // temporary
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
      } catch (error) {
        console.log(error);
      }
    } catch (e) {
      console.error(e);
    }
    setLoading(false);
  }

  if (!questionnaire || !indicators || !company) return <Loader />;

  return (
    <div className="container">
      <div className="mb-10 flex md:flex-row flex-col items-center justify-between gap-y-5">
        <div className="flex items-center gap-x-2.5 w-full justify-end">
          <Button className="bg-purple-3 text-purple-1 hover:bg-purple-1 hover:text-white" onClick={handleDownload} disabled={loading}>
            Download as PDF <Loader2 className={`inline-block transition-all ${loading ? "animate-spin w-4 ml-2" : "w-0"}`} size={16} />
          </Button>
        </div>
      </div>
      <GeneralInformation company={company} indicators={indicators} />
      {indicatorDimensions.map((dimension, index) => (
        <Dimension key={index} company={company} dimension={dimension} indicators={indicators} />
      ))}
    </div>
  );
};
