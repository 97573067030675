import { useEffect, useState } from "react";
import { AiOutlineCheck } from "react-icons/ai";
import { Search, Plus } from "lucide-react";
import toast from "react-hot-toast";

import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from "@/components/ui/table";
import View from "./view";

import Loader from "@/components/loader";
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";

import SelectDimension from "./selectDimension";

import api from "@/services/api";
import { dimensions, question_types } from "@/constants";

export const Template = ({ dataset, filters, setFilters }) => {
  const [indicatorSelected, setIndicatorSelected] = useState();
  const [selected, setSelected] = useState([]);
  const [selectAll, setSelectAll] = useState(false);
  const [loading, setLoading] = useState(false);
  const [indicators, setIndicators] = useState();

  useEffect(() => {
    (async () => {
      filters.dataset_id = dataset.master_dataset_id;
      const { data } = await api.post("/indicator/search", filters);
      setIndicators(data);
    })();
  }, [filters, dataset]);

  const onSelecteAll = () => {
    setSelectAll((state) => !state);
    if (!selectAll) {
      const newArr = [...selected];
      for (let i = 0; i < indicators.length; i++) {
        if (!newArr.find((e) => e._id === indicators[i]._id)) {
          newArr.push(indicators[i]);
        }
      }
      setSelected(newArr);
    } else {
      setSelected([]);
    }
  };

  function onSelect(v) {
    if (selectAll) setSelectAll(false);
    let arr = [...selected];
    if (arr.find((item) => item._id === v._id)) arr = arr.filter((item) => item._id !== v._id);
    else arr = [...selected, v];
    setSelected(arr);
  }

  const handleAddMultiple = async () => {
    setLoading(true);
    for (let i = 0; i < selected.length; i++) {
      try {
        const indicator = selected[i];
        await addIndicator(indicator);
      } catch (e) {
        console.log(e);
      }
    }
    toast.success("Done!");
    setSelected([]);
  };

  const addIndicator = async (obj) => {
    obj.dataset_id = dataset._id;
    obj.dataset_name = dataset.name;
    delete obj._id;
    delete obj.__v;
    delete obj.created_at;

    const { data } = await api.post(`/indicator`, obj);
  };

  return (
    <section>
      <div className="grid grid-cols-4 gap-4 items-center py-5">
        <div className="flex items-center justify-end gap-x-2.5">
          <div className="w-full relative">
            <Input className="relative pl-10" placeholder="Type here..." value={filters.search} onChange={(e) => setFilters({ ...filters, search: e.target.value })} />
            <Search className="absolute top-1/2 -translate-y-1/2 left-2 text-muted-foreground" />
          </div>
        </div>
        <SelectDimension filters={filters} type="dimension" value={filters.dimension} onChange={(dimension) => setFilters({ ...filters, dimension })} />
        <SelectDimension filters={filters} type="sub_dimension" value={filters.sub_dimension} onChange={(sub_dimension) => setFilters({ ...filters, sub_dimension })} />
        {Object.keys(filters).length > 1 && (
          <Button className="underline mr-auto" variant="link" onClick={() => setFilters({ dataset_id: dataset._id })}>
            Reset filters
          </Button>
        )}
        {selected.length > 0 && (
          <Button className="ml-auto" disabled={loading} variant="blue" onClick={handleAddMultiple}>
            Add to questionnaire
          </Button>
        )}
      </div>

      <View value={indicatorSelected} readOnly onChange={() => setIndicatorSelected(null)} />
      <div className="border rounded-xl overflow-hidden">
        {!indicators ? (
          <Loader />
        ) : (
          <Table>
            <TableHeader className="bg-[#EFF2FE]">
              <TableRow>
                <TableHead className="cursor-pointer" onClick={onSelecteAll}>
                  <label className={`cursor-pointer w-5 h-5 flex items-center justify-center rounded border border-gray-300 ${selectAll && "bg-primary"}`}>
                    <AiOutlineCheck className="text-white text-xs" />
                  </label>
                </TableHead>
                <TableHead className="py-3.5 pl-4 pr-3 text-left font-semibold text-black">ID</TableHead>
                <TableHead className="py-3.5 pl-4 pr-3 text-left font-semibold text-black">Dimension</TableHead>
                <TableHead className="py-3.5 pl-4 pr-3 text-left font-semibold text-black">Sub Dimension</TableHead>
                <TableHead className="py-3.5 pl-4 pr-3 text-left font-semibold text-black">Indicator</TableHead>
                <TableHead className="py-3.5 pl-4 pr-3 text-left font-semibold text-black">Question</TableHead>
                <TableHead className="py-3.5 pl-4 pr-3 text-left font-semibold text-black">Type</TableHead>
              </TableRow>
            </TableHeader>
            <TableBody className="bg-white">
              {indicators.map((indicator) => {
                const checked = selected.some((s) => s._id === indicator._id);
                return (
                  <TableRow
                    key={indicator._id}
                    onClick={() => setIndicatorSelected(indicator)}
                    className="odd:bg-[#FCFCFC] text-sm text-black divide-x divide-gray-200 hover:bg-black-10 cursor-pointer"
                  >
                    <TableCell
                      className="cursor-pointer p-3"
                      onClick={(e) => {
                        e.stopPropagation();
                        onSelect(indicator);
                      }}
                    >
                      <label className={`cursor-pointer w-5 h-5 flex items-center justify-center rounded border border-gray-300 ${checked && "bg-primary"}`}>
                        <AiOutlineCheck className="text-white text-xs" />
                      </label>
                    </TableCell>
                    <TableCell className="whitespace-nowrap p-3">{indicator.key}</TableCell>
                    <TableCell className="p-3">
                      <p
                        className={`text-center px-1 py-2 rounded text-white font-medium ${dimensions.find((d) => d.name === indicator.dimension)?.color || "bg-white text-black"}`}
                      >
                        {indicator.dimension}
                      </p>
                    </TableCell>
                    <TableCell className="p-3">{indicator.sub_dimension}</TableCell>
                    <TableCell className="p-3">{indicator.indicator?.en}</TableCell>
                    <TableCell className="p-3">{indicator?.question?.en || ""}</TableCell>
                    <TableCell className="p-3">
                      <p
                        className={`w-max text-center px-4 py-2 rounded text-app-gray font-semibold ${
                          question_types.find((q_type) => q_type.name === indicator.question_type)?.color || "bg-white text-black"
                        }`}
                      >
                        {indicator.question_type}
                      </p>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        )}
      </div>
    </section>
  );
};
