import { Route, Switch } from 'react-router-dom'

import Edit from './edit'
import Detail from './detail'
import Result from './result'

export const Questionnaire = () => {
  return (
    <div className='my-4'>
      <Switch>
        <Route path='/questionnaire/result/:id' component={Result} />
        <Route path='/questionnaire/edit/:id' component={Edit} />
        <Route path='/questionnaire/:id' component={Detail} />
      </Switch>
    </div>
  )
}
