import { Pie } from "react-chartjs-2";

export default ({ data }) => {
  return (
    <>
      <div>
        <p className="font-bold w-full max-w-xs  font-montserrat">{data.indicator_final_report?.en}</p>
        <p className="text-gray-400 italic">{data.indicator_final_report_description?.en}</p>
      </div>
      {!data.value || data.value === "N/A" ? (
        <span className="font-normal font-montserrat">{"N/A"}</span>
      ) : data.display_type === "number" ? (
        <span className="font-normal font-montserrat">{`${data.value}%`}</span>
      ) : data.display_type === "pie" ? (
        <Pie
          data={{
            labels: [data.indicator_final_report.en],
            datasets: [
              {
                data: [data.value, 100 - data.value],
                backgroundColor: ["#F2F2F2", "#E5E5E5"],
                hoverBackgroundColor: ["#F2F2F2", "#E5E5E5"],
              },
            ],
          }}
          options={{
            responsive: true,
            maintainAspectRatio: false,
            plugins: { legend: { display: true, labels: { boxHeight: 20, font: { size: 14 } } } },
          }}
        />
      ) : (
        <span className="font-normal font-montserrat">{`${data.value}%`}</span>
      )}
    </>
  );
};
