import { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { Inbox } from "lucide-react";
import { Button } from "@/components/ui/button";
import Loader from "@/components/loader";
import api from "@/services/api";
import useStore from "@/store";
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from "@/components/ui/table";

export default () => {
  const [questionnaires, setQuestionnaires] = useState();
  const { fund } = useStore();

  useEffect(() => {
    fetch();
  }, [fund]);

  async function fetch() {
    if (!fund) return;
    const { data } = await api.post("/questionnaire/search", { fund_id: fund?._id });
    setQuestionnaires(data);
  }

  const exportToCSV = async (questionnaire) => {
    const { data: campaignIndicators } = await api.post("/campaign_indicator/search", { questionnaire_id: questionnaire._id });

    const csvRows = [];
    const questionHeaders = ["Question ID", "Dimension", "Sub Dimension", "Question Text", "Answers", "Comments"];

    csvRows.push(questionHeaders.map((header) => `"${header}"`).join(";"));

    for (let i = 0; i < campaignIndicators.length; i++) {
      const indicator = campaignIndicators[i];
      const comments = indicator.comments.map((comment) => (comment.text || comment.file_name || "N/A").replace(/"/g, '""').replace(/[\r\n]+/g, " ")).join(" ; ");
      const questionValues = [
        indicator.key,
        indicator.dimension || "N/A",
        indicator.sub_dimension || "N/A",
        indicator.question.en.replace(/[\r\n]+/g, " "),
        indicator.value,
        `"${comments}"`,
      ];
      csvRows.push(questionValues.join(";"));
    }

    const csvContent = "data:text/csv;charset=utf-8," + encodeURIComponent(csvRows.join("\n"));

    const link = document.createElement("a");
    link.href = csvContent;
    link.download = `${questionnaire.name}.csv`;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const multiExportToCSV = async () => {
    if (!questionnaires || questionnaires.length === 0) {
      return;
    }

    for (const questionnaire of questionnaires) {
      await exportToCSV(questionnaire);
    }
  };

  function renderContent() {
    if (!questionnaires) return <Loader />;

    if (!questionnaires.length) {
      return (
        <p className="flex items-center gap-x-2 text-sm bg-table-header rounded-lg h-20 p-5 justify-center">
          <Inbox size={24} />
          There are no reports.
        </p>
      );
    }

    return (
      <>
        <div className="flex justify-end w-full mb-5">
          <Button onClick={multiExportToCSV} className="bg-accent hover:bg-[#3760fa] p-2">
            Export All CSV
          </Button>{" "}
        </div>
        <div className="border rounded-xl overflow-hidden">
          <Table>
            <TableHeader>
              <TableRow>
                <TableHead>Name</TableHead>
                <TableHead>Dates</TableHead>
                <TableHead>Action</TableHead>
              </TableRow>
            </TableHeader>
            <TableBody>
              {questionnaires.map((campaign) => {
                return <Row key={campaign._id} campaign={campaign} />;
              })}
            </TableBody>
          </Table>
        </div>
      </>
    );
  }

  return (
    <section className="container">
      <div className="flex flex-col md:flex-row gap-5 items-center justify-between mb-10">
        <h1 className="text-4xl font-bold shrink-0">reports</h1>
      </div>
      <div className="">{renderContent()}</div>
    </section>
  );
};

const Row = ({ campaign }) => {
  const history = useHistory();

  return (
    // <TableRow key={campaign._id} onClick={() => history.push(`/campaign/${campaign._id}`)} className="cursor-pointer">
    <TableRow key={campaign._id} className="cursor-pointer">
      <TableCell>{campaign.name}</TableCell>
      <TableCell>
        <div className="flex items-center gap-x-2">
          <p>{new Date(campaign.start_at).toLocaleDateString([], { year: "numeric", month: "long", day: "numeric" })}</p>
          {/* <ProgressBar
            percentage={Math.floor(((Date.now() - new Date(campaign.start_at)) / (new Date(campaign.end_at) - new Date(campaign.start_at))) * 100)}
            color={"bg-secondary"}
          /> */}
          <p>{new Date(campaign.end_at).toLocaleDateString([], { year: "numeric", month: "long", day: "numeric" })}</p>
        </div>
      </TableCell>
      <TableCell>
        <Button
          variant="link"
          onClick={() => {
            history.push(`/questionnaire/result/${campaign._id}`);
          }}
          className="text-blue-600 underline"
        >
          View
        </Button>
      </TableCell>
    </TableRow>
  );
};
