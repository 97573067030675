import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import toast from "react-hot-toast";
import { Dialog, DialogContent, DialogFooter, DialogHeader, DialogTitle, DialogTrigger } from "@/components/ui/dialog";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "@/components/ui/select";
import { Label } from "@/components/ui/label";
import { Input } from "@/components/ui/input";
import { Button } from "@/components/ui/button";

import api from "@/services/api";
import useStore from "@/store";

export const CreateQuestionnaire = ({ company, campaign = {} }) => {
  const [values, setValues] = useState({});
  const [loading, setLoading] = useState(false);
  const [type, setType] = useState("custom");
  const [created, setCreated] = useState();
  const [count, setCount] = useState(0);

  const { fund } = useStore();
  const { user } = useStore();

  const history = useHistory();

  useEffect(() => {
    setValues({ name: `${campaign.name}` });
  }, []);

  const handleError = (error) => {
    setLoading(false);
    toast.error(error);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      setLoading(true);
      if (!fund._id) return handleError("Please select a fund");
      if (!company.nace_codes || !(company.nace_codes.length > 0)) return handleError("Please select minimum one NACE code for your company");
      values.fund_id = fund._id;
      values.fund_name = fund.name;
      values.campaign_id = campaign._id;
      values.campaign_name = campaign.name;
      values.company_id = company._id;
      values.company_name = company.name;
      values.logo = company.logo;
      values.dataset_id = values.dataset_id;
      values.dataset_name = values.dataset_name;
      values.start_at = campaign.start_at;
      values.end_at = campaign.end_at;
      const { data, count } = await api.post("/questionnaire", values);
      toast.success("questionnaire created successfully");
      setCreated(data);
      setCount(count);
    } catch (e) {
      console.log(e);
    }
    setLoading(false);
  };

  if (user.role === "user") return null;

  function renderContent() {
    if (created)
      return (
        <DialogContent className="max-w-2xl">
          <DialogHeader>
            <DialogTitle className="mb-5 font-bold">{created?.total_indicators || 0} indicators have been created</DialogTitle>
          </DialogHeader>
          <div className="space-y-4">
            {count !== 0 && (
              <div className="my-1 py-2 px-4 bg-blue-100 text-sm font-light rounded-lg">
                Good news! Matteriality was able to automatically fill {count || 0}/{created?.total_indicators || 0} indicators thanks to data available on your company and fund.
              </div>
            )}
            <div className="my-1 text-sm font-normal">Send your questionnaire to some users to fill it at 100%!</div>
            <DialogFooter className="mt-3">
              <Button onClick={() => history.push(`/questionnaire/${created._id}`)} className="bg-accent hover:bg-[#3760fa]">
                Continue
              </Button>
            </DialogFooter>
          </div>
        </DialogContent>
      );

    return (
      <DialogContent className="max-w-2xl">
        <DialogHeader>
          <DialogTitle className="mb-5 font-bold">Create new questionnaire</DialogTitle>
        </DialogHeader>
        <form onSubmit={handleSubmit} className="space-y-4">
          <fieldset className="space-y-2">
            <Label>Questionnaire name</Label>
            <Input placeholder="Name" required value={values.name} onChange={(e) => setValues({ ...values, name: e.target.value })} />
          </fieldset>
          {/* <label className="flex items-center space-x-2 py-1 cursor-pointer font-medium">
            <input type="radio" disabled className="h-5 w-5" checked={type === "auto"} name="type" value="auto" onChange={(e) => setType(e.target.value)} />
            <span className={`text-xs ${type === "auto" ? "text-blue-700" : ""}`}>Let Matteriality automatically pick the right indicators for my company</span>
          </label> */}
          {/* {type === "auto" && (
            <div className="my-1 py-2 px-4 bg-blue-100 text-sm font-light rounded-lg">
              Matteriality will use the NACE code and other info from your company and fund to pick the relevant indicators.
            </div>
          )} */}
          {/* <label className="flex items-center space-x-2 py-1 cursor-pointer font-medium">
            <input type="radio" className="h-5 w-5" checked={type === "custom"} name="type" value="custom" onChange={(e) => setType(e.target.value)} />
            <span className={`text-xs ${type === "custom" ? "text-blue-700" : ""}`}>Pick my own indicators and choose a custom dataset</span>
          </label> */}
          {/* {type === "custom" && ( */}
          <fieldset className="space-y-2">
            <Label>Dataset</Label>
            <DatasetSelect value={values.dataset_id} onChange={(e) => setValues({ ...values, ...e })} />
          </fieldset>
          {/* )} */}
          <DialogFooter className="mt-3">
            <Button disabled={loading || !values.dataset_id || !values.name} type="submit" className="bg-accent hover:bg-[#3760fa]">
              {loading ? "Loading..." : "Create"}
            </Button>
          </DialogFooter>
        </form>
      </DialogContent>
    );
  }

  return (
    <Dialog>
      <DialogTrigger asChild className="text-center w-full">
        <Button variant="link" className="text-blue-600 underline">
          Create questionnaire
        </Button>
      </DialogTrigger>
      {renderContent()}
    </Dialog>
  );
};

const DatasetSelect = ({ value, onChange }) => {
  const [data, setData] = useState([]);

  const { fund } = useStore();

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    const datasetsByFundId = await api.post("/dataset/search", { fund_id: fund._id });
    // const datasetsByType = await api.post("/dataset/search", { type: "master" });
    if (!datasetsByFundId.ok) return toast.error("Something went wrong");
    // const combinedDatasets = [...datasetsByFundId.data, ...datasetsByType.data];
    setData(datasetsByFundId.data);
  };

  return (
    <Select
      defaultValue={value}
      onValueChange={(id) => {
        const item = data.find((d) => id === d._id);
        onChange({ dataset_id: item._id, dataset_name: item.name });
      }}
    >
      <SelectTrigger className="text-left">
        <SelectValue placeholder="Select dataset" />
      </SelectTrigger>
      <SelectContent>
        {data.map((item) => (
          <SelectItem value={item._id} key={item._id}>
            {item.name}
          </SelectItem>
        ))}
      </SelectContent>
    </Select>
  );
};
