import { useEffect, useState, Fragment } from "react";
import { Link, useHistory } from "react-router-dom";
import toast from "react-hot-toast";
import { Inbox, ChevronsUpDown, Check, Settings } from "lucide-react";
import { Listbox, Transition } from "@headlessui/react";

import { Dialog, DialogContent, DialogFooter, DialogHeader, DialogTitle, DialogTrigger } from "@/components/ui/dialog";
import { Button } from "@/components/ui/button";
import Loader from "@/components/loader";
import ProgressBar from "@/components/Progressbar";
import { Input } from "@/components/ui/input";

import { CreateQuestionnaire } from "./createQuestionnaire";

import { formatDateToYYYYMMDD } from "@/utils";

import api from "@/services/api";
import useStore from "@/store";

export function Home() {
  const [companies, setCompanies] = useState();
  const [selectedCampaign, setSelectedCampaign] = useState();

  const { fund, user } = useStore();

  console.log("selectedCampaign", selectedCampaign);

  useEffect(() => {
    const fetchData = async () => {
      console.log("fetch", fund);
      if (!fund) return;
      const { data } = await api.post("/company/search", { fund_id: fund?._id });
      console.log("data", data);
      setCompanies(data);
    };
    fetchData();
  }, [fund]);

  console.log("companies", companies);

  return (
    <section className="container my-4">
      <div className="flex flex-col md:flex-row gap-5 items-center justify-between mb-10">
        <h1 className="text-4xl font-bold shrink-0">Companies</h1>
        <div className="flex justify-center items-center">
          <CampaignSelect selectedCampaign={selectedCampaign} setSelectedCampaign={setSelectedCampaign} />
        </div>
      </div>
      <div className="">
        {!companies ? (
          <Loader />
        ) : (
          <div className="grid md:grid-cols-3 md:gap-4 gap-2 items-stretch">
            {user.role === "partner" && (
              <div className="flex justify-center items-center border border-border rounded-lg min-h-[200px]">
                <AddCompany campaign={selectedCampaign} />
              </div>
            )}

            {!companies.length && (
              <p className="flex items-center col-span-2 gap-x-2 text-sm bg-table-header rounded-lg h-full p-5 justify-center">
                <Inbox size={24} />
                There are no assigned companies.
              </p>
            )}

            {companies.map((data) => (
              <Company key={data._id} data={data} campaign={selectedCampaign} />
            ))}
          </div>
        )}
      </div>
    </section>
  );
}

const Company = ({ data, campaign }) => {
  const [questionnaire, setQuestionnaire] = useState();
  const { user } = useStore();

  useEffect(() => {
    (async () => {
      const { data: d } = await api.post(`/questionnaire/search`, { company_id: data._id, campaign_id: campaign?._id });
      setQuestionnaire(d[0] || {});
    })();
  }, [campaign]);

  function renderContent() {
    if (!questionnaire) return <Loader size="small" />;

    if (!Object.keys(questionnaire).length) {
      return (
        <div className="space-y-3">
          <p className="text-center text-sm">No questionnaire for this campaign</p>
          <ProgressBar percentage={0} color={"bg-accent"} />
          <CreateQuestionnaire company={data} campaign={campaign} />
        </div>
      );
    }

    return (
      <div className="space-y-3 text-center">
        <p>
          {questionnaire.current_index} / {questionnaire.total_indicators} indicators
        </p>
        <ProgressBar percentage={(questionnaire.current_index / questionnaire.total_indicators) * 100} color={"bg-accent"} />
        <p className="text-left text-sm my-4 font-medium">{questionnaire.name}</p>
        <Link to={`/questionnaire/${questionnaire._id}`} className="text-accent underline text-sm inline-block">
          Manage questionnaire
        </Link>
      </div>
    );
  }

  return (
    <div className="h-full">
      <div className="border-gray-200 bg-muted-foreground px-4 py-5 sm:px-6 rounded-t-lg">
        <div className="flex flex-wrap items-center justify-between sm:flex-nowrap">
          <div className="text-base font-semibold leading-6 text-white">{data.name}</div>
          <div className="flex-shrink-0 text-white">
            {user.role === "partner" && (
              <Link to={`/company/${data._id}`}>
                <p className="underline">Company details</p>
              </Link>
            )}
          </div>
        </div>
      </div>
      <div className="p-6 border rounded-b-lg">{renderContent()}</div>
    </div>
  );
};

const AddCompany = ({ campaign }) => {
  const [values, setValues] = useState({ name: "" });
  const [loading, setLoading] = useState(false);

  const { fund } = useStore();
  const { user } = useStore();

  const history = useHistory();

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      setLoading(true);
      if (!fund._id) return toast.error("Please select a fund");
      if (!campaign) return toast.error("Please select a campaign");
      values.fund_id = fund._id;
      values.fund_name = fund.name;
      const { data } = await api.post("/company", values);
      toast.success("company created successfully");
      history.push(`/company/${data._id}`);
    } catch (e) {
      console.log(e);
    }
    setLoading(false);
  };

  if (user.role === "user") return null;

  return (
    <Dialog>
      <DialogTrigger asChild>
        <Button variant="blue">Create company</Button>
      </DialogTrigger>
      <DialogContent className="w-3/4">
        <DialogHeader>
          <DialogTitle className="mb-5">Create new company</DialogTitle>
        </DialogHeader>
        <form onSubmit={handleSubmit} className="space-y-4">
          <div className="flex flex-col w-full gap-y-2">
            <span className="flex gap-x-1">
              <label htmlFor="name">Company Name</label>
              <p className="text-red-500">*</p>
            </span>
            <Input placeholder="Company name" name="name" type="text" value={values.name} onChange={(e) => setValues({ ...values, name: e.target.value })} />
          </div>
          <DialogFooter className="mt-3">
            <Button disabled={loading} type="submit" className="bg-accent hover:bg-[#3760fa]">
              {loading ? "Loading..." : "Create a company"}
            </Button>
          </DialogFooter>
        </form>
      </DialogContent>
    </Dialog>
  );
};

function CampaignSelect({ selectedCampaign = {}, setSelectedCampaign }) {
  const [campaigns, setCampaigns] = useState([]);
  const { fund } = useStore();
  const { user } = useStore();

  function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
  }
  async function fetchData() {
    const { data, ok } = await api.post("/campaign/search", { fund_id: fund?._id });
    if (!ok) return;
    setCampaigns(data);
    setSelectedCampaign(data[0]);
  }

  useEffect(() => {
    fetchData();
  }, [fund]);

  return (
    <div className="flex items-center gap-x-2">
      {selectedCampaign.start_at && selectedCampaign.end_at && (
        <>
          <p>{formatDateToYYYYMMDD(selectedCampaign.start_at)}</p>
          <div className="w-80">
            <ProgressBar
              percentage={Math.floor(((Date.now() - new Date(selectedCampaign.start_at)) / (new Date(selectedCampaign.end_at) - new Date(selectedCampaign.start_at))) * 100)}
              color={"bg-secondary"}
            />
          </div>
          <p>{formatDateToYYYYMMDD(selectedCampaign.end_at)}</p>
        </>
      )}
      <Listbox value={selectedCampaign} onChange={setSelectedCampaign}>
        {({ open }) => (
          <>
            <div className="relative w-96">
              <Listbox.Button className="relative w-full cursor-default rounded-md bg-white py-1.5 pl-3 pr-10 text-left text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6">
                <span className="block truncate">{selectedCampaign.name || "No campaign selected"}</span>
                <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                  <ChevronsUpDown className="h-5 w-5 text-gray-400" aria-hidden="true" />
                </span>
              </Listbox.Button>

              <Transition show={open} as={Fragment} leave="transition ease-in duration-100" leaveFrom="opacity-100" leaveTo="opacity-0">
                <Listbox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                  {campaigns.map((campaign, campaignIdx) => (
                    <Listbox.Option
                      key={campaignIdx}
                      className={({ active }) => classNames(active ? "bg-indigo-600 text-white" : "text-gray-900", "relative cursor-default select-none py-2 pl-3 pr-9")}
                      value={campaign}
                    >
                      {({ selected, active }) => (
                        <>
                          <span className={classNames(selected ? "font-semibold" : "font-normal", "block truncate")}>{campaign.name}</span>

                          {selected ? (
                            <span className={classNames(active ? "text-white" : "text-indigo-600", "absolute inset-y-0 right-0 flex items-center pr-4")}>
                              <Check className="h-5 w-5" aria-hidden="true" />
                            </span>
                          ) : null}
                        </>
                      )}
                    </Listbox.Option>
                  ))}
                </Listbox.Options>
              </Transition>
            </div>
          </>
        )}
      </Listbox>
      {/* {user.role === "partner" && (
        <Link to={`/campaign/${selectedCampaign?._id || ""}`}>
          <Settings className="text-accent" />
        </Link>
      )} */}
    </div>
  );
}
